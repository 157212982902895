import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import ExtendedOverview from "app/components/ExtendedOverview";
import TopTrackComponent from "app/components/TopTrackComponent";

import { Downloads, Earned, Sold, VectorButton } from "app/assets/icons";
import PlayDownloadComponents from "app/components/ComponentsPlayDownload";
import {
  getAnalyticsTracks,
  getTotalSalesAnalyticsTracks,
} from "app/redux/actions/Analytics";
import {
  selectorsAnalyticsTotalSalesTracks,
  selectorsDownloadAnalytics,
  selectorsFavoritesAnalytics,
  selectorsPlaysAnalytics,
  selectorsSoldAnalytics,
} from "app/redux/selectors/analytics";
import { isLoadingSelector } from "app/redux/selectors/status";
import {
  AnalyticType,
  dateType,
  queryAnalyticsType,
  totalAnalyticsTrack,
} from "constants/analyticsType";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import { RootState } from "types";
import { v4 as uuidv4 } from "uuid";
import "./styles.scss";
import FavoritesAnalytics from "app/assets/icons/favoritesAnalytics";
import MapComponent from "../MapComponent";
import TrackItem from "../TrackItem";
import { TrackSoundKit } from "app/models";
import { planType } from "constants/planType";
import UpgradeYourPlan from "../NotFoundPage/upgradeYourPlan";
import { selectUserAdmin } from "app/redux/selectors/users";
function StatsComponents() {
  const { t } = useTranslation();
  const [dateTime, setDateTime] = useState(0);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUserAdmin);

  const checkPlanSubscription = useMemo(
    () => currentUser?.subscriptionType === planType.MAINSTREAM,
    [currentUser?.subscriptionType]
  );

  const playsAnalytics = useSelector(selectorsPlaysAnalytics);
  // const purchasedAnalytics = useSelector(selectorsPurchasedAnalytics);
  const soldAnalytics = useSelector(selectorsSoldAnalytics);
  const downloadAnalytics = useSelector(selectorsDownloadAnalytics);
  const favoritesAnalytics = useSelector(selectorsFavoritesAnalytics);
  const salesAnalytics = useSelector(selectorsAnalyticsTotalSalesTracks);
  const salesTotalAnalytics = useMemo(() => {
    return (salesAnalytics || []).map((item: TrackSoundKit) =>
      item?.profitExtended ? item?.profitExtended?.gross : 0
    );
  }, [salesAnalytics]);

  const totalPlayTrack = totalAnalyticsTrack(playsAnalytics.values);
  // const totalPurchasedTrack = totalAnalyticsTrack(purchasedAnalytics.values);
  const totalSoldTrack = totalAnalyticsTrack(soldAnalytics.values);
  const totalDownloadTrack = totalAnalyticsTrack(downloadAnalytics.values);
  const totalFavoritesTrack = totalAnalyticsTrack(favoritesAnalytics.values);
  const totalSalesTrack = totalAnalyticsTrack(salesTotalAnalytics);
  const [showExtended, setShowExtended] = useState(false);
  const [analyticsType, setAnalyticsType] = useState({
    icon: <VectorButton />,
    name: t("Plays"),
    type: "Plays",
    price: totalPlayTrack,
  });

  const dataList = useMemo(
    () => [
      {
        icon: <VectorButton />,
        name: t("Plays"),
        type: "Plays",
        price: totalPlayTrack,
      },
      {
        icon: <Downloads />,
        name: t("downloads"),
        type: "Downloads",
        price: totalDownloadTrack,
      },
      {
        icon: <Earned />,
        name: t("sales"),
        type: "Sales",
        price:
          totalSalesTrack === 0
            ? totalSalesTrack
            : totalSalesTrack.toLocaleString(),
      },
      {
        icon: <Sold />,
        name: t("itemSold"),
        type: "Sold",
        price: totalSoldTrack,
      },
      {
        icon: <FavoritesAnalytics />,
        name: t("favorites"),
        type: "Favorites",
        price: totalFavoritesTrack,
      },
    ],
    [
      totalPlayTrack,
      totalDownloadTrack,
      totalSalesTrack,
      totalSoldTrack,
      totalFavoritesTrack,
      t,
    ]
  );

  const listTabDate = useMemo(
    () => [
      { name: t("today"), id: 1 },
      { name: t("week"), id: 2 },
      { name: t("month"), id: 3 },
      { name: t("year"), id: 4 },
    ],
    [t]
  );

  const timeType = dateType(dateTime);

  useEffect(() => {
    async function fetchData() {
      dispatch(
        getTotalSalesAnalyticsTracks({
          timeType,
          analyticType: AnalyticType.SOLD,
        })
      );
      await dispatch(
        getAnalyticsTracks({
          timeType,
          analyticType: AnalyticType.PLAYED,
        })
      );

      //await dispatch(
      //   getAnalyticsTracks({
      //     timeType,
      //     analyticType: AnalyticType.PURCHASED,
      //   })
      // );

      await dispatch(
        getAnalyticsTracks({
          timeType,
          analyticType: AnalyticType.SOLD,
        })
      );

      await dispatch(
        getAnalyticsTracks({
          timeType,
          analyticType: AnalyticType.DOWNLOADED,
        })
      );

      await dispatch(
        getAnalyticsTracks({
          timeType,
          analyticType: AnalyticType.FAVORITED,
        })
      );
    }
    fetchData();
  }, [dispatch, timeType]);

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([getAnalyticsTracks.typePrefix], state)
  );

  const totalAnalytics = useCallback(() => {
    const total = {
      [queryAnalyticsType.Plays]: totalPlayTrack,
      // [queryAnalyticsType.Purchased]: totalPurchasedTrack,
      [queryAnalyticsType.Sold]: totalSoldTrack,
      [queryAnalyticsType.Downloads]: totalDownloadTrack,
      [queryAnalyticsType.Favorites]: totalFavoritesTrack,
      [queryAnalyticsType.Sales]: `$${totalSalesTrack.toLocaleString()}`,
    };

    return total[analyticsType.type];
  }, [
    analyticsType.type,
    totalDownloadTrack,
    totalFavoritesTrack,
    totalPlayTrack,
    totalSalesTrack,
    totalSoldTrack,
  ]);

  return (
    <>
      <Flex
        mt="35px"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
      >
        <Flex w="100%">
          <Flex position="absolute">
            <Text fontSize="32px" mr="15px">
              {t("dataAnalytics")}
            </Text>
          </Flex>
          <Tabs
            index={dateTime}
            onChange={(index) => setDateTime(index)}
            variant="unstyled"
            align="end"
            w="100%"
            pt={{ base: "50px", md: "0px" }}
          >
            <TabList
              color="#49494D"
              justifyContent={{ base: "space-around", md: "end" }}
            >
              {listTabDate.map((item) => (
                <Tab
                  _selected={{
                    color: "#FFFFFF",
                  }}
                  _focus={{ boxShadow: "none" }}
                  _hover={{
                    border: "1px solid #38383D",
                    background: "#222227 !important",
                    borderRadius: "6px",
                  }}
                  borderColor="#18181c"
                  borderWidth="1px"
                  key={item.id}
                >
                  {item.name}
                </Tab>
              ))}
            </TabList>
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50vh"
              >
                <FadeLoader color={"#d4f70e"} loading={isLoading} />
              </Box>
            ) : (
              <TabPanels align="start" overflow="scroll">
                {Array.from({ length: 4 }).map(() => (
                  <TabPanel key={uuidv4()} p={{ base: "0px", md: "16px 0px" }}>
                    <PlayDownloadComponents
                      setAnalyticsType={setAnalyticsType}
                      data={dataList}
                      selectAnalyticsType={analyticsType}
                    />
                  </TabPanel>
                ))}
              </TabPanels>
            )}
          </Tabs>
        </Flex>
      </Flex>

      {!isLoading && (
        <>
          <Box
            mt={{ base: "30px", md: "10px" }}
            p="20px 20px"
            background="#1C1C20"
          >
            <ExtendedOverview
              analyticsType={analyticsType}
              totalAnalytics={totalAnalytics()}
              timeDay={timeType}
            />
          </Box>
          <Button
            background="rgba(255, 255, 255, 0.04)"
            _hover={{ background: "rgba(255, 255, 255, 0.04)" }}
            mt="35px"
            onClick={() => setShowExtended((pre) => !pre)}
          >
            {showExtended
              ? t("hideExtendedOverview")
              : t("showExtendedOverview")}
          </Button>
          {showExtended &&
            (!checkPlanSubscription ? (
              <UpgradeYourPlan />
            ) : (
              <TrackItem analyticsType={analyticsType} timeDay={timeType} />
            ))}
        </>
      )}
      <TopTrackComponent />

      <MapComponent timeDay={timeType} analyticsType={analyticsType} />
    </>
  );
}

export default React.memo(StatsComponents);
