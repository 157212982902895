import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import FilterTrendingBeats from "./filterTrendingBeats";
import algoliasearch from "algoliasearch/lite";
import { Configure, InstantSearch } from "react-instantsearch";
import "./styles.scss";
import TrackExploreHome from "./trackExploreHome";
import SkeletonFilterSearch from "../SearchFilterPage/skeltonFilterSearch";
import ModalShowFreeDownload from "app/components/ModalShowFreeDownload";
import useTrackSelect from "hooks/track/useTrackSelect";
import IconFilterSearch from "app/assets/icons/IconFilterSearch";
import { useTranslation } from "react-i18next";
import ModalFilterTrendingBeats from "./modalFilterTrendingBeats";
import CurrentRefinements from "../SearchFilterPage/CurrentRefinements";

interface ExploreHomeProps {
  isLoading: boolean;
}

function ExploreHome({ isLoading }: ExploreHomeProps) {
  const {
    showModalFreeDownload,
    setShowModalFreeDownload,
    handleFreeDownload,
    linkTitleDownload,
  } = useTrackSelect();
  const { t } = useTranslation();
  const [showModalFilter, setShowModalFilter] = useState(false);

  const searchClient = useMemo(() => {
    return algoliasearch(
      process.env.REACT_APP_APPLICATION_ID_ALGOLIA as string,
      process.env.REACT_APP_API_KEY_ALGOLIA as string
    );
  }, []);

  // const CustomToggleRefinement = (props) => {
  //   const { value, refine } = useToggleRefinement({
  //     attribute: props.searchFilter,
  //   });

  //   return (
  //     <>
  //       <Box
  //         background="#24252A"
  //         padding="10px"
  //         borderRadius="8px"
  //         onClick={() => {
  //           refine({
  //             isRefined: value.isRefined,
  //           });
  //         }}
  //       >
  //         <Box position="relative">
  //           <Image
  //             borderRadius="6px"
  //             src={props.image}
  //             opacity={value.isRefined ? "0.9" : "0.3"}
  //             border={value.isRefined ? "1px solid #d4f70e" : ""}
  //             boxShadow={value.isRefined ? "0px 5px 12px -5px #d4f70e" : ""}
  //             w="148px"
  //             height="148px"
  //           />
  //           <Box position="absolute" top="40%" right="40%">
  //             {props.icon}
  //           </Box>
  //         </Box>

  //         <Text
  //           m="15px 0px 12px 0px"
  //           fontSize="18px"
  //           lineHeight="21px"
  //           fontWeight="500"
  //           textAlign="center"
  //           fontFamily="Cera Pro Medium"
  //         >
  //           {props.name}
  //         </Text>
  //       </Box>
  //     </>
  //   );
  // };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.REACT_APP_KEY_TRACKS_SEARCH as string}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure hitsPerPage={15} />
      <Box>
        {/* <Flex justifyContent="space-between" mt="50px" gridGap="30px" mb="50px">
          {dataSearch.map((item) => (
            <CustomToggleRefinement key={item.name} {...item} />
          ))}
        </Flex> */}
        <Flex
          gridGap={{ base: "20px", xl: "30px" }}
          display={{ base: "block", md: "flex" }}
        >
          <Box
            w={{ base: "100%", md: "35%", xl: "22%" }}
            mt={{ base: "0px", md: "376px", xl: "287px" }}
            display={{ base: "none", md: "block" }}
          >
            {isLoading ? (
              <Box mt="20px">
                <SkeletonFilterSearch />
              </Box>
            ) : (
              <Box
                position="sticky"
                top="100px"
                overflow="scroll"
                height={{ base: "100%", md: "90vh" }}
              >
                <FilterTrendingBeats isTrackSearch={true} />
              </Box>
            )}
          </Box>
          <Flex
            alignItems="center"
            display={{ base: "flex", md: "none" }}
            backgroundColor="#18181c"
            position="sticky"
            top="94px"
            py="12px"
            zIndex={90}
          >
            <Flex onClick={() => setShowModalFilter(true)}>
              <IconFilterSearch />
              <Text ml="10px">{t("filter")}</Text>
            </Flex>
          </Flex>
          <Box display={{ base: "block", md: "none" }}>
            <CurrentRefinements />
          </Box>

          <Box
            w={{ base: "100%", md: "65%", xl: "78%" }}
            mt="20px"
            className="trending-tags"
            position="sticky"
            mb="50px"
          >
            <TrackExploreHome
              isLoading={isLoading}
              handleFreeDownload={handleFreeDownload}
            />
          </Box>
        </Flex>
      </Box>
      {showModalFreeDownload && (
        <ModalShowFreeDownload
          showModal={showModalFreeDownload}
          setShowModal={setShowModalFreeDownload}
          linkTitleFreeDownload={linkTitleDownload}
        />
      )}
      <Box display={showModalFilter ? "block" : "none"}>
        <ModalFilterTrendingBeats
          setShowModal={setShowModalFilter}
          isTrackSearch={true}
        />
      </Box>
    </InstantSearch>
  );
}

export default React.memo(ExploreHome);
