import {
  Box,
  Flex,
  Image,
  Spinner,
  Text,
  UnorderedList,
  ListItem,
  SimpleGrid,
} from "@chakra-ui/react";
import FreeDownload from "app/assets/icons/freeDownload";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import PlayButtonGroup from "app/components/FooterPlayer/PlayButton";
import {
  TrackSoundKit,
  HandleFreeDownloadModel,
  ShortLinkModel,
} from "app/models";
import * as playlistActions from "app/redux/actions/playlist";
import { addToCart, removeFromCart } from "app/redux/actions/cart";
import {
  checkIsPlayPlaylists,
  checkPlaylistIdPlaying,
} from "app/redux/actions/playlist";
import { checkFollowUser } from "app/redux/actions/tracks";
import { isInCartSelector } from "app/redux/selectors/cart";
import { isLoadingSelector } from "app/redux/selectors/status";
import { selectLoggedIn, selectUserAdmin } from "app/redux/selectors/users";
import { PROFILE_TYPE } from "constants/contractType";
import { TRACK_TYPE } from "constants/trackTypes";
import { usePlayers } from "hooks/player/usePlayers";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { RootState } from "types";
import {
  checkFreeDownload,
  checkTypeDownload,
  isNotForSaleTrackSoundPacks,
} from "utils/convertTracks";
import { getPriceTrack } from "utils/getPrice";
import CartIcon from "../CartIcon";
import ToastPlanSubscription from "../toast/toastplan";
import "./styles.scss";
import PopoverMenuProfile from "./popoverMenuProfile";
import Cart from "app/assets/icons/cart";
import ModalPopoverProfile from "./modalPopoverProfile";
import { LOOP_TYPE } from "constants/audioOptions";
import { BsThreeDotsVertical } from "react-icons/bs";

interface MusicCardProps {
  isProfile?: boolean;
  item: TrackSoundKit;
  index?: number;
  onOpenOptionsModal?: (track: TrackSoundKit, isLyric?: boolean) => void;
  isSK?: boolean;
  isRecentAction?: boolean;
  isDraft?: boolean;
  handleShowModalAddTrack?: (id: string) => void;
  isPlaylists?: boolean;
  playlistId?: string;
  handleFreeDownload?: ({
    url,
    title,
    _id,
    isTrack,
  }: HandleFreeDownloadModel) => void;
  onOpenModalShareLink?: (track: TrackSoundKit) => void;
  setShortLinkUrl?: (value: ShortLinkModel) => void;
  setLoadingUrl?: (value: boolean) => void;
  listPlaylistTracksSoundPacks?: TrackSoundKit[];
  handleShowModalFreeDownloadMailchimp: (track: TrackSoundKit) => void;
}

function MusicCard({
  item: track,
  isProfile = false,
  index,
  onOpenOptionsModal = () => {},
  isSK = false,
  isRecentAction = false,
  isDraft = false,
  handleShowModalAddTrack,
  isPlaylists = false,
  playlistId,
  handleFreeDownload,
  onOpenModalShareLink = () => {},
  setShortLinkUrl,
  setLoadingUrl,
  listPlaylistTracksSoundPacks,
  handleShowModalFreeDownloadMailchimp,
}: MusicCardProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModalProfile, setShowModalProfile] = useState(false);
  const {
    playingTrackId,
    isPlaying,
    handlePlayOrPause,
    handleTogglePlayPause,
    isLoading,
    isPlayAllPlaylist,
    handlePlayPlaylists,
    playlistsAlgolia,
  } = usePlayers();

  const currentUser = useSelector(selectUserAdmin);
  const { path } = useRouteMatch();
  const loggedIn = useSelector(selectLoggedIn);

  const checkFreeBeats = checkFreeDownload(track);
  const isLoadingCheckFreeDownload = useSelector((state: RootState) =>
    isLoadingSelector([checkFollowUser.typePrefix], state)
  );
  const [selectedTrackId, setSelectedTrackId] = useState("");

  const onClickToTrack = useCallback(() => {
    if (!track?.mergeAudio && !track.audioFileUrl) {
      return;
    }
    if (!isPlayAllPlaylist && !isPlaylists) {
      handlePlayPlaylists([]);
    }

    if (playlistId) {
      dispatch(checkIsPlayPlaylists(false));
      dispatch(checkPlaylistIdPlaying(playlistId));
    } else {
      dispatch(checkIsPlayPlaylists(true));
      dispatch(checkPlaylistIdPlaying(""));
    }
    if (playingTrackId !== track?._id) {
      handlePlayOrPause(track);
      if ((listPlaylistTracksSoundPacks || [])?.length > 0) {
        handlePlayPlaylists(listPlaylistTracksSoundPacks);
        dispatch(playlistActions.setLoopAudioType(LOOP_TYPE.LOOP));
      } else if (playlistsAlgolia?.length > 0) {
        handlePlayPlaylists(playlistsAlgolia);
        dispatch(playlistActions.setLoopAudioType(LOOP_TYPE.LOOP));
      } else {
        handlePlayPlaylists([]);
        dispatch(playlistActions.setLoopAudioType(LOOP_TYPE.NO_LOOP));
      }
    } else {
      handleTogglePlayPause();
    }
  }, [
    dispatch,
    handlePlayOrPause,
    handlePlayPlaylists,
    handleTogglePlayPause,
    isPlayAllPlaylist,
    isPlaylists,
    playlistId,
    playingTrackId,
    track,
    listPlaylistTracksSoundPacks,
    playlistsAlgolia,
  ]);

  const handlePlayTrackSoundkit = useCallback(() => {
    !isDraft && onClickToTrack();
  }, [isDraft, onClickToTrack]);

  const isDisabled = isNotForSaleTrackSoundPacks(track);

  const isInCart = useSelector(isInCartSelector(track));

  const handleRemoveToCartButton = useCallback(() => {
    dispatch(removeFromCart([track?._id]));
  }, [dispatch, track?._id]);

  const handleAddToCart = useCallback(
    (e) => {
      if (isDisabled) {
        return;
      }
      if (isInCart) {
        handleRemoveToCartButton();
      } else if (isSK) {
        dispatch(addToCart(track));
      } else {
        onOpenOptionsModal(track);
        e.preventDefault();
      }
    },
    [
      isDisabled,
      isInCart,
      handleRemoveToCartButton,
      onOpenOptionsModal,
      track,
      dispatch,
      isSK,
    ]
  );

  const isExclusive = useMemo(
    () => (track?.licenses || []).every((license) => license.exclusive),
    [track]
  );

  const isMakeOffersOnly = useMemo(
    () => (track?.licenses || []).every((license) => license?.makeOffersOnly),
    [track?.licenses]
  );

  const handleTrackDetail = useCallback(
    (e) => {
      e.stopPropagation();
      if (isSK) {
        history.push(`/soundkit/${track?.slug}`);
        return;
      }
      history.push(`/track/${track?.slug}`);
    },
    [history, isSK, track?.slug]
  );

  const handleSearchTags = useCallback(
    (tag) => {
      history.push(`/search?type=tags&q=${tag}`);
    },
    [history]
  );

  const redirectProfile = useCallback(
    (e, name) => {
      const checkParams = path.split("/")[1];
      e.stopPropagation();
      if (checkParams === "user") {
        history.push(`${name}?profileType=${PROFILE_TYPE.TRACKS}`);
        return;
      }
      history.push(`/user/${name}?profileType=${PROFILE_TYPE.TRACKS}`);
    },
    [history, path]
  );

  const handleDownloadTrack = useCallback(async () => {
    if (track?.requireEmailCapture) {
      handleShowModalFreeDownloadMailchimp(track);
      return;
    }
    setSelectedTrackId(track?._id);
    const isFollowing = await dispatch(
      checkFollowUser(track?.createdBy?._id || "")
    );
    setSelectedTrackId("");
    if (isFollowing?.payload || currentUser?._id === track?.createdBy?._id) {
      handleFreeDownload({
        url: checkTypeDownload(track)
          ? (track?.mergeAudio as string)
          : (track?.untaggedFileUrl as string),
        title: track?.title || "",
        _id: track?._id || "",
        isTrack: true,
      });
    } else {
      ToastPlanSubscription({
        title: t("followForAFreeDownload"),
        description: t("desFollowGetDownload"),
      });
    }
  }, [
    currentUser?._id,
    dispatch,
    handleFreeDownload,
    t,
    track,
    handleShowModalFreeDownloadMailchimp,
  ]);

  const handleDownloadSoundpack = useCallback(async () => {
    if (track?.requireEmailCapture) {
      handleShowModalFreeDownloadMailchimp(track);
      return;
    }
    setSelectedTrackId(track?._id);
    const isFollowing = await dispatch(
      checkFollowUser(track?.createdBy?._id || "")
    );
    setSelectedTrackId("");
    if (isFollowing?.payload || currentUser?._id === track?.createdBy?._id) {
      handleFreeDownload({
        url: track?.downloadFilesUrl || "",
        title: track?.title || "",
        _id: track?._id || "",
        isTrack: false,
      });
    } else {
      ToastPlanSubscription({
        title: t("followForAFreeDownload"),
        description: t("desFollowGetDownload"),
      });
    }
  }, [
    currentUser?._id,
    dispatch,
    handleFreeDownload,
    handleShowModalFreeDownloadMailchimp,
    t,
    track,
  ]);

  return (
    <Box
      className="components-mytrack-item"
      role="group"
      cursor="pointer"
      display="flex"
      alignItems="center"
    >
      <Box
        pr={{ xl: "10px" }}
        w="100%"
        overflow="auto"
        _groupHover={{
          background: { base: "none", md: "rgba(36, 37, 42, 0.8)" },
          borderRadius: "4px",
          padding: { base: "0px", md: "0px 10px 0px 0px" },
        }}
      >
        <Flex
          alignItems="center"
          w="100%"
          p={"12px 0px "}
          borderBottom={isProfile ? "none" : "1px solid rgba(36, 37, 42, 0.8)"}
          minWidth={{ md: "700px", lg: "760px", xl: "800px" }}
          ml={{
            md: isPlaylists ? "0px" : "-13%",
            lg: isPlaylists ? "-2px" : "-4%",
            xl: "0px",
          }}
        >
          <Flex
            w={"9%"}
            cursor="pointer"
            onClick={handlePlayTrackSoundkit}
            justifyContent="center"
            display={{ base: "none", md: "flex" }}
          >
            {!isRecentAction && (
              <Text
                textAlign="center"
                _groupHover={{
                  display: !isDraft && "none",
                }}
                display={playingTrackId === track?._id ? "none" : "flex"}
              >
                {index + 1}
              </Text>
            )}

            <Flex
              w={"45px"}
              h={"45px"}
              borderRadius="4px"
              alignItems="center"
              justifyContent="center"
              display={playingTrackId === track?._id ? "flex" : "none"}
              _groupHover={{
                display: !isDraft && "flex",
                alignItems: !isDraft && "center",
                justifyContent: !isDraft && "center",
              }}
            >
              <PlayButtonGroup
                size={35}
                isPlaying={playingTrackId === track?._id && isPlaying}
                isLoading={isLoading}
              />
            </Flex>
          </Flex>
          <Flex
            w={{ base: "70%", md: "50%" }}
            alignItems="center"
            onClick={handlePlayTrackSoundkit}
          >
            <Image
              w={{ base: "52px", md: "72px" }}
              h={{ base: "52px", md: "72px" }}
              src={track?.coverUrl}
              fallbackSrc={ImageDefault}
              borderRadius="4px"
              objectFit="cover"
              border={
                !track?.coverUrl
                  ? "1px solid rgba(255, 255, 255, 0.14)"
                  : "none"
              }
              mr={{ base: "0", md: "12px", xl: "20px" }}
            />
            <Box w={"100%"} overflow="hidden" ml={{ base: "10px", md: "0px" }}>
              {track?.title ? (
                <Flex
                  alignItems="center"
                  className="title-name"
                  _hover={{ textDecoration: "underline" }}
                  onClick={handleTrackDetail}
                >
                  <Text
                    className="fontSfProDisplayBold title-text"
                    fontSize={{ base: "16px", xl: "16px" }}
                    lineHeight="16px"
                    fontWeight="900"
                    color={"white"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="clip"
                    fontFamily="Cera Pro Bold"
                    w={{ base: "100%", md: "85%" }}
                  >
                    {track?.title?.slice(0, 55)}
                  </Text>
                </Flex>
              ) : (
                <Text
                  fontSize="12px"
                  lineHeight="14px"
                  fontWeight="500"
                  color={"#ED3636"}
                >
                  {t("missingTrackTitle")}
                </Text>
              )}
              <Flex
                mt="8px"
                w={{ base: "120px", md: "100%" }}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {TRACK_TYPE[track?.type] && (
                  <Box
                    display="flex"
                    alignItems="center"
                    height="20px"
                    borderRadius="4px"
                    padding="5px 10px"
                    mr="5px"
                    mb="5px"
                    backgroundColor="#2b2e1b"
                  >
                    <Text
                      fontSize={"12px"}
                      lineHeight="14px"
                      color="#d4f70e"
                      fontFamily="Cera Pro Regular"
                    >
                      {t(TRACK_TYPE[track?.type])}
                    </Text>
                  </Box>
                )}
                <Text
                  fontSize={"14px"}
                  color="#464649"
                  _hover={{
                    textDecor: "underline",
                    color: "white",
                  }}
                  cursor="pointer"
                  fontFamily="Cera Pro Regular"
                  onClick={(e) => redirectProfile(e, track?.createdBy?.name)}
                >
                  {`${t("by")} ${track?.createdBy?.name}`}
                </Text>
              </Flex>

              <Flex
                alignItems="center"
                overflow="scroll"
                display={{ base: "none", md: "flex" }}
              >
                {track.bpm && (
                  <Text fontSize="12px" mr="5px">
                    Bpm <span>{track?.bpm}</span>
                  </Text>
                )}

                <UnorderedList display="flex" gap="25px">
                  {track?.key && (
                    <ListItem fontSize="12px">KEY: {track?.key}</ListItem>
                  )}
                  {track?.mainGenre && (
                    <ListItem fontSize="12px">{track?.mainGenre}</ListItem>
                  )}
                  {track?.subGenre && (
                    <ListItem fontSize="12px">{track?.subGenre}</ListItem>
                  )}
                </UnorderedList>
              </Flex>
            </Box>
          </Flex>
          <Flex
            w={"23%"}
            alignItems="center"
            justifyContent="center"
            display={{ base: "none", md: "flex" }}
          >
            <SimpleGrid columns={2} spacing={1}>
              {track?.tags?.slice(0, 4)?.map((item) => (
                <Flex
                  background="#2E2E32"
                  border="1px solid #464649"
                  borderRadius="2px"
                  alignItems="center"
                  justifyContent="center"
                  overflow="hidden"
                  key={item}
                  w="85px"
                  h="20px"
                  _hover={{
                    background: "#454C22",
                    color: "#d4f70e",
                    border: "1px solid #d4f70e",
                  }}
                  onClick={() => handleSearchTags(item)}
                >
                  <Text
                    fontSize="12px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    w="90%"
                    textAlign="center"
                  >
                    {item}
                  </Text>
                </Flex>
              ))}
            </SimpleGrid>
          </Flex>

          <Flex
            justifyContent="flex-end"
            display={{ base: "flex", md: "none" }}
            w="25%"
          >
            {checkFreeBeats && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="38px"
                width="38px"
                borderRadius="50%"
                bg="#333625"
                border="1px solid #62701B"
                mr="5px"
                onClick={
                  !loggedIn
                    ? () => null
                    : isSK
                    ? handleDownloadSoundpack
                    : handleDownloadTrack
                }
              >
                <FreeDownload />
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="38px"
              width="38px"
              borderRadius="50%"
              bg={isInCart ? "#D4F70E" : "#24252A"}
              border="1px solid #505155"
              onClick={handleAddToCart}
            >
              <Cart fill={isInCart ? "#24252A" : "#D4F70E"} />
            </Box>
          </Flex>

          <Box
            w={{ base: "25%", md: "12%" }}
            display={{ base: "none", md: "flex" }}
            justifyContent="flex-end"
            flexDirection="column"
            alignItems="end"
          >
            <CartIcon
              isDisabled={isDisabled}
              onClick={handleAddToCart}
              price={getPriceTrack(track)}
              active={isInCart}
              isOffer={
                !track.licenses ? false : isExclusive && isMakeOffersOnly
              }
            />

            {checkFreeBeats && (
              <Flex
                mt="5px"
                background="#3D4419"
                color="#D4F70E"
                padding={{
                  base: "4px 8px",
                  md: "6px 10px",
                  xl: "9px 11px",
                }}
                borderRadius="34px"
                cursor="pointer"
                onClick={
                  !loggedIn
                    ? () => null
                    : isSK
                    ? handleDownloadSoundpack
                    : handleDownloadTrack
                }
              >
                {isLoadingCheckFreeDownload &&
                selectedTrackId === track?._id ? (
                  <Spinner
                    w={{ base: "12px", lg: "16px" }}
                    h={{ base: "12px", lg: "16px" }}
                  />
                ) : (
                  <>
                    <Text
                      fontSize={{ base: "10px", xl: "12px" }}
                      lineHeight="14px"
                      fontWeight="400"
                      mr="10px"
                      fontFamily="Cera Pro Regular"
                    >
                      {t("FREE")}
                    </Text>
                    <FreeDownload />
                  </>
                )}
              </Flex>
            )}
          </Box>
          <Flex
            padding={{ base: "none", md: "5px 2px" }}
            cursor="pointer"
            _hover={{
              background: { base: "none", md: "#24252A" },
              padding: { base: "0px", md: "5px 2px" },
              border: { base: "none", md: "1px solid #38383D" },
              borderRadius: "8px",
            }}
            w="5%"
            display={{ base: "flex", md: "none" }}
            justifyContent="flex-end"
            onClick={() => setShowModalProfile(true)}
          >
            <BsThreeDotsVertical />
          </Flex>
          {isProfile && (
            <Flex
              display={{ base: "none", md: "flex" }}
              w={{ base: "5%", md: "6%" }}
              justifyContent="end"
            >
              <PopoverMenuProfile
                track={track}
                isSK={isSK}
                isRecentAction={isRecentAction}
                onOpenOptionsModal={onOpenOptionsModal}
                onOpenModalShareLink={onOpenModalShareLink}
                setLoadingUrl={setLoadingUrl}
                setShortLinkUrl={setShortLinkUrl}
                handleShowModalAddTrack={handleShowModalAddTrack}
              />
            </Flex>
          )}
        </Flex>
      </Box>
      {showModalProfile && (
        <ModalPopoverProfile
          showModal={showModalProfile}
          setShowModal={setShowModalProfile}
          track={track}
          isSK={isSK}
          isRecentAction={isRecentAction}
          onOpenOptionsModal={onOpenOptionsModal}
          onOpenModalShareLink={onOpenModalShareLink}
          setLoadingUrl={setLoadingUrl}
          setShortLinkUrl={setShortLinkUrl}
          handleShowModalAddTrack={handleShowModalAddTrack}
        />
      )}
    </Box>
  );
}

export default React.memo(MusicCard);
