import { createReducer, current } from "@reduxjs/toolkit";
import { TrackSoundKit, Playlists } from "app/models";

import {
  removeAudioList,
  setAudioList,
  selectAudio,
  toggleMuted,
  setLoopAudioType,
  toggleShuffleAudio,
  setVolume,
  getPlaylists,
  createPlaylist,
  getIndividualPlaylist,
  prepareEditPlaylist,
  editPlaylist,
  deletePlaylists,
  addTrackToPlaylist,
  removeTrackToPlaylist,
  fetchRepostPlaylists,
  likePlaylistIndividual,
  unlikePlaylistIndividual,
  repostPlaylistIndividual,
  fetchFavoritesPlaylists,
  followUnfollowPlaylist,
  getPlaylistsFollowing,
  playPlaylists,
  checkIsPlayPlaylists,
  checkPlaylistIdPlaying,
  getPlaylistsInProfile,
  likePlaylistsError,
  unLikePlaylistsError,
  errorLikePlaylistDetail,
  errorUnLikePlaylistDetail,
  listPlaylistsTrackSoundkitAlgolia,
} from "app/redux/actions/playlist";
import { LOOP_TYPE } from "constants/audioOptions";
import unionBy from "lodash/unionBy";
import { PlaylistType } from "constants/playlistType";
import {
  likePlaylistInRecentActionsHomepage,
  unlikePlaylistInRecentActionsHomepage,
  repostPlaylistInRecentActionsHomepage,
} from "../actions/home";

export interface InitPlaylistState {
  audioList: Array<TrackSoundKit>;
  isMuted: boolean;
  volume: number;
  isShuffle: boolean;
  loopType: string;
  selectedPlaylist: {
    name: string;
    tags: string[];
    description: string | undefined;
    visibility: string;
    coverUrl: string | undefined;
    _id?: string;
  };
  playlists: Playlists[];
  playlistIndividual: Playlists;
  favoritesPlaylists: Playlists[];
  repostPlaylists: Playlists[];
  playlistsFollowing: Playlists[];
  isPlayAllPlaylist: boolean;
  playlistIdPlaying: string;
  playlistsInProfile: Playlists[];
  playlistsAlgolia: Array<TrackSoundKit>;
}

const initialState: InitPlaylistState = {
  audioList: [],
  isMuted: false,
  volume: 0.5,
  isShuffle: false,
  loopType: LOOP_TYPE.NO_LOOP,
  selectedPlaylist: {
    name: "",
    tags: [],
    description: undefined,
    visibility: PlaylistType.PUBLIC,
    coverUrl: undefined,
    _id: "",
  },
  playlists: [],
  playlistIndividual: {},
  favoritesPlaylists: [],
  repostPlaylists: [],
  playlistsFollowing: [],
  isPlayAllPlaylist: false,
  playlistIdPlaying: "",
  playlistsInProfile: [],
  playlistsAlgolia: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setAudioList, (state, action) => {
      return {
        ...state,
        audioList: action.payload,
      };
    })
    .addCase(removeAudioList, (state) => {
      return {
        ...state,
        audioList: [],
        isAudioPlay: false,
      };
    })
    .addCase(selectAudio, (state, action) => {
      const { audioList } = state;
      const newAudio = action.payload;
      const isExistInPlaylist = audioList.find(
        (audio) => audio?._id === newAudio?._id
      );

      const newAudioList = isExistInPlaylist
        ? audioList.filter((audio) => audio?._id !== newAudio?._id)
        : unionBy(state.audioList, [action.payload], "_id");
      return {
        ...state,
        audioList: newAudioList,
      };
    })
    .addCase(toggleMuted, (state) => {
      return {
        ...state,
        isMuted: !state.isMuted,
      };
    })
    .addCase(setVolume, (state, action) => {
      return {
        ...state,
        volume: action.payload,
      };
    })
    .addCase(toggleShuffleAudio, (state) => {
      return {
        ...state,
        isShuffle: !state.isShuffle,
      };
    })
    .addCase(setLoopAudioType, (state, action) => {
      return {
        ...state,
        loopType: action.payload,
      };
    })
    .addCase(createPlaylist.fulfilled, (state, action) => {
      return {
        ...state,
        playlists: [...state.playlists, action.payload],
      };
    })
    .addCase(addTrackToPlaylist.fulfilled, (state, action) => {
      const currentPlaylists = current(state).playlists;
      const updatePlaylist = currentPlaylists.map((item) => {
        if (item._id === action.payload.playlistId) {
          return {
            ...item,
            totalTracks: Number(item?.totalTracks) + 1,
            trackIds: [...item?.trackIds, action.payload.trackId],
          };
        }
        return {
          ...item,
        };
      });

      return {
        ...state,
        playlists: updatePlaylist,
      };
    })
    .addCase(removeTrackToPlaylist.fulfilled, (state, action) => {
      const currentPlaylists = current(state).playlists;
      const currentPlaylistIndividual = current(state).playlistIndividual;

      const updatePlaylist = currentPlaylists.map((item) => {
        if (item._id === action.payload.playlistId) {
          return {
            ...item,
            totalTracks: Number(item?.totalTracks) - 1,
            trackIds: item?.trackIds?.filter(
              (item) => item !== action.payload.trackId
            ),
          };
        }
        return {
          ...item,
        };
      });
      let updateTrackInPlaylist = [];
      if (currentPlaylistIndividual?._id === action.payload.playlistId) {
        updateTrackInPlaylist = (
          currentPlaylistIndividual?.tracks || []
        ).filter((item) => item._id !== action.payload.trackId);
      } else {
        updateTrackInPlaylist = currentPlaylistIndividual?.tracks || [];
      }

      return {
        ...state,
        playlists: updatePlaylist,
        playlistIndividual: {
          ...state.playlistIndividual,
          tracks: updateTrackInPlaylist,
        },
      };
    })
    .addCase(editPlaylist.fulfilled, (state, action) => {
      const currentPlaylist = current(state.playlists);
      const updatePlaylists = currentPlaylist.map((item) => {
        if (item._id === action.payload?._id) {
          return action.payload;
        } else {
          return item;
        }
      });

      return {
        ...state,
        playlists: updatePlaylists,
        playlistIndividual: {
          ...state.playlistIndividual,
          name: action.payload?.name,
          description: action.payload?.description,
          tags: action.payload?.tags,
          coverUrl: action.payload?.coverUrl,
          visibility: action.payload?.visibility,
        },
      };
    })
    .addCase(getPlaylists.fulfilled, (state, action) => {
      return {
        ...state,
        playlists: action.payload,
      };
    })
    .addCase(getIndividualPlaylist.fulfilled, (state, action) => {
      return {
        ...state,
        playlistIndividual: action.payload,
      };
    })
    .addCase(prepareEditPlaylist, (state, action) => {
      return {
        ...state,
        selectedPlaylist: {
          coverUrl: action.payload?.coverUrl,
          name: action.payload?.name,
          tags: action.payload?.tags,
          description: action.payload?.description,
          visibility: action.payload?.visibility,
          _id: action.payload?._id,
        },
      };
    })
    .addCase(deletePlaylists.fulfilled, (state, action) => {
      const currentPlaylists = current(state).playlists;
      const filterPlaylists = (currentPlaylists || []).filter(
        (item) => !action.payload.includes(item?._id)
      );
      return {
        ...state,
        playlists: filterPlaylists,
      };
    })
    .addCase(fetchFavoritesPlaylists.fulfilled, (state, action) => {
      return {
        ...state,
        favoritesPlaylists: action.payload,
      };
    })
    .addCase(fetchRepostPlaylists.fulfilled, (state, action) => {
      return {
        ...state,
        repostPlaylists: action.payload,
      };
    })
    .addCase(likePlaylistInRecentActionsHomepage.fulfilled, (state, action) => {
      const body = { _id: action.payload.playlistId };
      return {
        ...state,
        favoritesPlaylists: [...state.favoritesPlaylists, body],
      };
    })
    .addCase(
      unlikePlaylistInRecentActionsHomepage.fulfilled,
      (state, action) => {
        const favorites = current(state.favoritesPlaylists);
        const unlikePlaylist = favorites.filter(
          (item) => item._id !== action.payload.playlistId
        );
        return {
          ...state,
          favoritesPlaylists: unlikePlaylist,
        };
      }
    )
    .addCase(
      repostPlaylistInRecentActionsHomepage.fulfilled,
      (state, action) => {
        const body = { _id: action.payload.playlistId };
        return {
          ...state,
          repostPlaylists: [...state.repostPlaylists, body],
        };
      }
    )
    .addCase(likePlaylistIndividual.fulfilled, (state, action) => {
      const body = { _id: action.payload.playlistId };
      return {
        ...state,
        favoritesPlaylists: [...state.favoritesPlaylists, body],
        playlistIndividual: {
          ...state.playlistIndividual,
          totalFavs: Number(state?.playlistIndividual?.totalFavs) + 1,
        },
      };
    })
    .addCase(errorLikePlaylistDetail, (state, action) => {
      return {
        ...state,
        playlistIndividual: {
          ...state.playlistIndividual,
          totalFavs: Number(state?.playlistIndividual?.totalFavs) - 1,
        },
      };
    })
    .addCase(errorUnLikePlaylistDetail, (state, action) => {
      return {
        ...state,
        playlistIndividual: {
          ...state.playlistIndividual,
          totalFavs: Number(state?.playlistIndividual?.totalFavs) + 1,
        },
      };
    })
    .addCase(unlikePlaylistIndividual.fulfilled, (state, action) => {
      const favoritePlaylist = current(state.favoritesPlaylists);
      const unlikePlaylist = favoritePlaylist.filter(
        (item) => item._id !== action.payload.playlistId
      );
      return {
        ...state,
        favoritesPlaylists: unlikePlaylist,
        playlistIndividual: {
          ...state.playlistIndividual,
          totalFavs: Number(state?.playlistIndividual?.totalFavs) - 1,
        },
      };
    })
    .addCase(repostPlaylistIndividual.fulfilled, (state, action) => {
      const body = { _id: action.payload.playlistId };
      return {
        ...state,
        repostPlaylists: [...state.repostPlaylists, body],
        playlistIndividual: {
          ...state.playlistIndividual,
          totalRepost: Number(state?.playlistIndividual?.totalRepost) + 1,
        },
      };
    })
    .addCase(followUnfollowPlaylist.fulfilled, (state, action) => {
      let followers = [];
      const currentPlaylistIndividual = current(state.playlistIndividual);
      const isFollow = currentPlaylistIndividual?.followers?.includes(
        action.payload
      );
      if (isFollow) {
        followers =
          currentPlaylistIndividual?.followers?.filter(
            (item) => item !== action?.payload
          ) || [];
      } else {
        followers = [...currentPlaylistIndividual?.followers, action?.payload];
      }
      return {
        ...state,
        playlistIndividual: {
          ...state.playlistIndividual,
          followers: followers,
        },
      };
    })
    .addCase(getPlaylistsFollowing.fulfilled, (state, action) => {
      return {
        ...state,
        playlistsFollowing: action.payload,
      };
    })
    .addCase(playPlaylists, (state, action) => {
      return {
        ...state,
        audioList: action.payload,
      };
    })
    .addCase(checkIsPlayPlaylists, (state, action) => {
      return {
        ...state,
        isPlayAllPlaylist: action.payload,
      };
    })
    .addCase(checkPlaylistIdPlaying, (state, action) => {
      return {
        ...state,
        playlistIdPlaying: action.payload,
      };
    })
    .addCase(getPlaylistsInProfile.fulfilled, (state, action) => {
      return {
        ...state,
        playlistsInProfile: action.payload,
      };
    })
    .addCase(likePlaylistsError, (state, action) => {
      const removePlaylists = state.favoritesPlaylists.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        favoritesPlaylists: removePlaylists,
      };
    })
    .addCase(unLikePlaylistsError, (state, action) => {
      return {
        ...state,
        favoritesPlaylists: [
          ...state.favoritesPlaylists,
          { _id: action.payload },
        ],
      };
    })

    .addCase(listPlaylistsTrackSoundkitAlgolia, (state, action) => {
      return {
        ...state,
        playlistsAlgolia: action.payload,
      };
    })
);
