import { Box, Flex, Grid, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import SelectInput from "app/components/AddTrackComponents/SelectInput";
import TextInput from "app/components/AddTrackComponents/TextInput";
import { soundKitTypes } from "constants/soundKitTypes";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FilesToDeliver from "./FilesToDeliver";
import "./styles.scss";
import { URLTRACKSOUNDKIT } from "constants/index";
import { slugTrackSoundkit } from "utils/convertTracks";
import { CHARACTER_TITLE } from "constants/statusType";
import CharaterLimitError from "../AddTrackComponents/charaterLimitError";

export default function GeneralInfo() {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const soundkitTitle = watch("title");

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      document.getElementById(firstError)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [errors]);

  return (
    <Box position="relative">
      <Text fontSize="20px" lineHeight="24px" mt="30px" fontWeight="600">
        {t("generalInfo")}
      </Text>
      <Grid
        templateColumns="repeat(6, 1fr)"
        gap={4}
        mt="30px"
        display={{ base: "block", lg: "grid" }}
      >
        <GridItem
          colSpan={4}
          w={{ base: "100%", lg: "96%" }}
          mb={{ base: "15px", lg: "0px" }}
        >
          <TextInput
            type="text"
            name="title"
            label={t("soundKitTitle")}
            placeholder={t("soundKitName")}
            errors={errors}
            control={control}
            maxLength={CHARACTER_TITLE.MAXIMUM_CHARACTERS}
          />
          <CharaterLimitError title={soundkitTitle} />

          <SimpleGrid columns={2} spacing="10px" w="100%" mt="15px">
            <Box>
              <TextInput
                placeholder={"$0"}
                name="basicPrice"
                label={t("price")}
                type={"group"}
                errors={errors}
                control={control}
              />
            </Box>
            <Box>
              <SelectInput
                id="type"
                name={"type"}
                placeholder={t("SelectSoundKitType")}
                label={t("soundKitType")}
                data={soundKitTypes}
                error={errors?.type?.message}
                control={control}
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid columns={1} spacing="10px" w="100%" mt="25px">
            <TextInput
              name="releaseDate"
              label={t("releaseDate")}
              type={"datetime-local"}
              errors={errors}
              control={control}
            />
          </SimpleGrid>
          <Flex
            mt="20px"
            background="#25252A"
            padding="15px"
            borderRadius="6px"
          >
            <Text
              color="#535353"
              fontSize={{ base: "14px", md: "16px" }}
              lineHeight="18px"
              mr="5px"
            >
              URL:
            </Text>
            <Text
              noOfLines={2}
              fontSize={{ base: "14px", md: "16px" }}
              lineHeight="18px"
            >
              {`${URLTRACKSOUNDKIT.soundkit}${slugTrackSoundkit(
                soundkitTitle
              )}`}
            </Text>
          </Flex>
        </GridItem>
        <FilesToDeliver isSoundkit />
      </Grid>
    </Box>
  );
}
