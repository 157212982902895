import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

import { useLocation } from "react-router-dom";
import { db } from "../../config/firebase";
import { useSelector } from "react-redux";
import { selectUserAdmin } from "app/redux/selectors/users";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import IconSendMessages from "app/assets/icons/IconsendMessages";
import "./styles.scss";
import queryString from "query-string";
import NotificationController from "app/apis/notifications";
import { ConversationInfo } from "app/models";
import { planType } from "constants/planType";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { useTranslation } from "react-i18next";
import { useConversation } from "../../hooks/useConversations";

interface InputSectionProps {
  disabled: boolean;
  conversation: ConversationInfo;
}

const InputSection = ({ disabled, conversation }: InputSectionProps) => {
  const currentUser = useSelector(selectUserAdmin);
  const filtered = (conversation?.users || [])?.find(
    (user) => user !== currentUser?._id
  );

  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const { getAllMsgCurrentUser, totalMsg } = useConversation();

  const location = useLocation();
  const tabMessages = queryString.parse(location.search);
  const conversationId = tabMessages.room;

  const checkPlanSubscription = useMemo(
    () => currentUser?.subscriptionType === planType.MAINSTREAM,
    [currentUser?.subscriptionType]
  );

  const textInputRef = useRef<HTMLInputElement>(null);

  const updateTimestamp = useCallback(() => {
    updateDoc(doc(db, "conversations", conversationId as string), {
      updatedAt: serverTimestamp(),
    });
  }, [conversationId]);

  useEffect(() => {
    const handler = () => {
      textInputRef.current?.focus();
    };
    window.addEventListener("focus", handler);
    return () => window.removeEventListener("focus", handler);
  }, []);

  useEffect(() => {
    textInputRef.current?.focus();
  }, [conversationId]);

  useEffect(() => {
    getAllMsgCurrentUser();
  }, [getAllMsgCurrentUser]);

  const submitMsg = useCallback(() => {
    if (!inputValue.trim()) return;
    setInputValue("");
    let replacedInputValue = ` ${inputValue} `;
    addDoc(
      collection(db, "conversations", conversationId as string, "messages"),
      {
        sender: currentUser?._id,
        content: replacedInputValue.trim(),
        type: "text",
        createdAt: serverTimestamp(),
        // replyTo: replyInfo?.id || null,
      }
    );

    NotificationController.createNotificationFromMessage({
      description: "has sent you a message",
      type: "NEW_MESSAGE",
      userId: filtered,
      conversationId,
      createdBy: currentUser?._id,
    });
    updateTimestamp();
  }, [conversationId, currentUser?._id, filtered, inputValue, updateTimestamp]);

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    getAllMsgCurrentUser();
    if (
      (!checkPlanSubscription && totalMsg.length < 5) ||
      checkPlanSubscription
    ) {
      submitMsg();
    } else {
      ToastPlanSubscription({
        title: t("messagesLimitReached"),
        description: !checkPlanSubscription
          ? t("descriptionFiveMessagesLimitReached")
          : t("descriptionTwentyMessagesLimitReached"),
      });
    }
  };

  return (
    <>
      <Box mt="15px" className="input-messages" p={{ base: "16px", md: "0px" }}>
        <form onSubmit={handleFormSubmit}>
          <Box>
            <InputGroup size="lg">
              <Input
                maxLength={1000}
                disabled={disabled}
                ref={textInputRef}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                type="text"
                fontSize={{ base: "14px", md: "16px" }}
                placeholder="Write a message ..."
                _placeholder={{ color: "rgba(255,255,255,0.3)" }}
                _focus={{ border: "none" }}
              />
              <InputRightElement className="icon-send-mess">
                <Button
                  type="submit"
                  background="#25252a"
                  _hover={{ background: "none" }}
                  _focus={{ boxShadow: "none" }}
                  _active={{ background: "none" }}
                >
                  <IconSendMessages />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default InputSection;
