import {
  Box,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Negotiations } from "app/models";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FadeLoader } from "react-spinners";
import NegotiationItemContentModal from "./ContentModalNegotiation/negotiationItemContent";
import NegotiationOffer from "./ContentModalNegotiation/negotiationOffer";
import SubmitNegotiationOffer from "./ContentModalNegotiation/submitNegotiationOffer";

export interface ShowContentProps {
  showContentNegotiationDeal: boolean;
  showNegotiationOffer: boolean;
  showSubmitNegotiation: boolean;
}

interface ModalNegotiationsProps {
  showModal: boolean;
  setShowModal: () => void;
  negotiationsItem: Negotiations;
  showContent: ShowContentProps;
  setShowContent: (data: ShowContentProps) => void;
  isLoading?: boolean;
}

export default function ModalNegotiations({
  showModal,
  setShowModal,
  negotiationsItem,
  showContent,
  setShowContent,
  isLoading,
}: ModalNegotiationsProps) {
  const { t } = useTranslation();

  const methods = useForm();

  return (
    <Modal isOpen={showModal} onClose={setShowModal} isCentered>
      <ModalOverlay />
      <ModalContent
        minW={{ base: "unset", md: "500px", xl: "700px" }}
        maxH={{ base: "650px", md: "850px" }}
        overflowY="scroll"
        mx={{ base: "10px", md: "0px" }}
        mt="0px"
      >
        <ModalHeader
          fontSize={{ base: "22px", md: "24px", xl: "28px" }}
          lineHeight="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          backdropFilter="blur(10px)"
          position="sticky"
          top="0px"
          px={{ base: "16px", md: "24px" }}
          zIndex={20}
        >
          {showContent?.showContentNegotiationDeal
            ? t("negotiationDeal")
            : t("negotiationOffer")}
          <CloseButton
            onClick={setShowModal}
            _focus={{
              borderWidth: "0px",
            }}
            top={{ base: "8px", md: "15px" }}
            fontSize={{ base: "20px", md: "22px", xl: "26px" }}
            borderColor="#38383D"
            _hover={{
              border: "1px solid #38383D !important",
              padding: "20px",
            }}
            padding={{ md: "20px" }}
            color="#d4f70e"
          />
        </ModalHeader>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <FadeLoader color={"#d4f70e"} loading={isLoading} />
          </Box>
        ) : (
          <ModalBody
            margin="auto"
            padding={{ base: "0px 16px 50px 16px", md: "0px 30px 50px 30px" }}
          >
            {showContent?.showContentNegotiationDeal && (
              <NegotiationItemContentModal
                negotiationsItem={negotiationsItem}
                setShowModal={setShowModal}
                setShowContent={setShowContent}
              />
            )}
            <FormProvider {...methods}>
              {showContent?.showNegotiationOffer && (
                <NegotiationOffer
                  setShowContent={setShowContent}
                  negotiationOffer={negotiationsItem}
                />
              )}
              {showContent?.showSubmitNegotiation && (
                <SubmitNegotiationOffer
                  setShowModal={setShowModal}
                  negotiationsItem={negotiationsItem}
                  setShowContent={setShowContent}
                />
              )}
            </FormProvider>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}
