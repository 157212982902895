import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { db } from "../../config/firebase";
import { useCollectionQuery } from "../../hooks/useCollectionQuery";
import AvatarUser from "app/assets/images/UserAvatar.png";
import TextInputController from "app/components/AddTrackComponents/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { selectUserAdmin } from "app/redux/selectors/users";
import { getConversation } from "app/redux/actions/conversations";
import { useHistory } from "react-router-dom";
import { useConversation } from "../../hooks/useConversations";
import { planType } from "constants/planType";
import ToastPlanSubscription from "app/components/toast/toastplan";
import "./styles.scss";

type Option = {
  label: string;
  value: string;
};
interface ModalCreateConversationProps {
  showModal: boolean;
  setShowModal: () => void;
}

export default function ModalCreateConversation({
  showModal,
  setShowModal,
}: ModalCreateConversationProps) {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUserAdmin);
  const dispatch = useDispatch();
  const history = useHistory();
  const { getAllMsgCurrentUser, totalMsg } = useConversation();
  const customFontSize =
    useBreakpointValue({ base: "14px", md: "16px" }) || "16px";

  const checkPlanSubscription = useMemo(
    () => currentUser?.subscriptionType === planType.MAINSTREAM,
    [currentUser?.subscriptionType]
  );

  useEffect(() => {
    getAllMsgCurrentUser();
  }, [getAllMsgCurrentUser, showModal]);

  const { data } = useCollectionQuery("all-users", collection(db, "users"));
  const dataUser = data?.docs
    .filter((item) => item.data().email !== currentUser.email)
    .map((item) => ({
      ...item.data(),
      id: item.id,
      name: item.data().name,
      email: item.data().email,
    }));

  const filters = dataUser?.map((item) => {
    return {
      ...item,
      label: item?.name,
      value: item.email,
    };
  });
  const filterOption = (option: Option, inputValue: string) => {
    return (
      option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
      option?.value?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  };

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const formatOptionLabel = ({ name, profileImage }: any) => (
    <Flex
      bg="#1F1F23"
      alignItems="center"
      borderRadius="4px"
      justifyContent="space-between"
      height="45px"
      padding="5px 15px"
      margin="0px"
    >
      <Text minW="200px" className="color" fontSize="14px" fontWeight="600">
        {name}
      </Text>
      {name && (
        <Image
          borderRadius="50%"
          h="34px"
          w="34px"
          src={profileImage}
          fallbackSrc={AvatarUser}
        />
      )}
    </Flex>
  );

  const valueUserConversation = watch("userConversation");

  const onSubmitForm = (values: any) => {
    const selectUserId = values?.userConversation?.id;
    const messages = values?.messages;
    if (
      (!checkPlanSubscription && totalMsg.length < 5) ||
      checkPlanSubscription
    ) {
      dispatch(getConversation(selectUserId))
        .then((conversationId) => {
          addDoc(
            collection(
              db,
              "conversations",
              conversationId.payload as string,
              "messages"
            ),
            {
              sender: currentUser?._id,
              content: messages,
              type: "text",
              createdAt: serverTimestamp(),
            }
          );

          const updateTimestamp = () => {
            updateDoc(
              doc(db, "conversations", conversationId?.payload as string),
              {
                updatedAt: serverTimestamp(),
              }
            );
          };
          updateTimestamp();
          history.push(`messages?tab=all&room=${conversationId?.payload}`);
          setShowModal();
          reset({
            userConversation: null,
            messages: "",
          });
        })
        .catch((err: any) => {
          console.log(err);
          setShowModal();
        });
    } else {
      ToastPlanSubscription({
        title: t("messagesLimitReached"),
        description: !checkPlanSubscription
          ? t("descriptionFiveMessagesLimitReached")
          : t("descriptionTwentyMessagesLimitReached"),
      });
    }
  };

  return (
    <Modal isOpen={showModal} onClose={setShowModal} isCentered>
      <ModalOverlay />
      <ModalContent
        w={{ base: "100%", md: "500px" }}
        minHeight="480px"
        overflow="scroll"
        mx={{ base: "10px", md: "0px" }}
      >
        <ModalHeader
          padding="14px 40px"
          fontSize={{ base: "24px", md: "28px", xl: "32px" }}
        >
          {t("startConversation")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={3}
          top={{ base: "16px", md: "20px" }}
          fontSize={{ base: "20px", md: "24px", xl: "26px" }}
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding={{ base: "12px", md: "18px" }}
          color="#d4f70e"
        />
        <ModalBody
          padding={{ base: "10px 16px", md: "10px 24px", xl: "10px 40px" }}
        >
          <form>
            <Text
              fontSize="16px"
              lineHeight="19px"
              fontWeight="400"
              color="#535353"
              mb="10px"
            >
              {t("user")}
            </Text>
            <Controller
              name={"userConversation"}
              render={({ field }) => (
                <Box>
                  <Select
                    className="select-user"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "white",
                        background: " #24252a",
                        fontSize: customFontSize,
                        borderColor: state.isFocused ? "#24252a" : "#24252a",
                      }),

                      input: (provided, state) => ({
                        ...provided,
                        color: "white", // change color of input when focused
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        background: "#24252a",
                        ":hover": {
                          backgroundColor: "#ddd",
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        background: "#24252a",
                      }),

                      indicatorSeparator: (defaultStyles) => ({
                        ...defaultStyles,
                        display: "none", // your changes to the arrow
                      }),
                    }}
                    options={filters}
                    isSearchable={true}
                    filterOption={filterOption}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    classNamePrefix="select"
                    placeholder={"Search by Username or Email"}
                    formatOptionLabel={formatOptionLabel}
                    {...field}
                  />
                </Box>
              )}
              control={control}
            />
            <Box mt="10px">
              <Text
                fontSize="16px"
                lineHeight="19px"
                fontWeight="400"
                color="#535353"
                m="12px 0px"
              >
                {t("message")}
              </Text>
              <TextInputController
                type="textarea"
                name="messages"
                placeholder={t("typeYourMessageHere")}
                errors={errors}
                control={control}
              />
            </Box>
            <Button
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              borderRadius="4px"
              w="40%"
              mt="30px"
              onClick={handleSubmit(onSubmitForm)}
              disabled={!!valueUserConversation ? false : true}
            >
              {t("send")}
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
