import { TrackSoundKit } from "app/models";
import * as playerActions from "app/redux/actions/player";
import * as playlistActions from "app/redux/actions/playlist";
import { selectAudio } from "app/redux/selectors/player";
import { selectorPlaylist } from "app/redux/selectors/playlist";
import { useCallback, useRef, useState, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import WaveSurfer from "wavesurfer.js";
import TrackService from "app/apis/tracks";
import { LOOP_TYPE } from "constants/audioOptions";
import { getRandomNoRepeats } from "utils/shuffleSong";
import { WareformContext } from "hooks/player/wareformContext";
import { AUDIO_SUPERPRODUCTOR } from "constants/index";
import { sleepDeplayTime } from "utils/formatAudioDuration";
import { selectLoggedIn } from "app/redux/selectors/users";
import {
  increasingSoundPackPlay,
  increasingTrackPlay,
} from "app/redux/actions/tracks";
import { getIpAddress } from "utils/getData";
import { checkTypeTrackOrSoundPack } from "utils/convertTracks";

export const usePlayers = () => {
  const dispatch = useDispatch();

  const wavesurfer = useContext<any>(WareformContext);

  const {
    playingTrack,
    isAudioPlay,
    playingTrackId,
    isPlaying,
    isLoading,
    showFooterPlayer,
  } = useSelector(selectAudio);
  const {
    audioList,
    isMuted,
    isShuffle,
    loopType,
    volume,
    isPlayAllPlaylist,
    playlistIdPlaying,
    playlistsAlgolia,
  } = useSelector(selectorPlaylist);
  const loggedIn = useSelector(selectLoggedIn);

  const [waveform, setWaveform] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenPlaylist, setIsOpenPlaylist] = useState<boolean>(false);
  const durationTimeRef = useRef<number>(0);
  const waveformRef = useRef<any>(null);
  const loopTypeRef = useRef<string>(loopType);
  const isShuffleRef = useRef<boolean>(isShuffle);

  const formWaveSurferOptions = useCallback((ref: any) => {
    return {
      container: "#waveform",
      preload: "auto",
    };
  }, []);

  const getChooseAudio = useMemo(() => {
    return getRandomNoRepeats<TrackSoundKit>(audioList);
  }, [audioList]);

  const onToggleShuffleAudio = useCallback(() => {
    isShuffleRef.current = !isShuffleRef;
    dispatch(playlistActions.toggleShuffleAudio());
  }, [dispatch]);

  const setPlayingTrack = useCallback(
    (audio) => {
      dispatch(playerActions.setPlayingTrack(audio));
    },
    [dispatch]
  );

  const setVolume = useCallback(
    (value) => {
      dispatch(playlistActions.setVolume(value));
    },
    [dispatch]
  );

  const handlePlayPlaylists = useCallback(
    (playlists) => {
      dispatch(playlistActions.playPlaylists(playlists));
    },
    [dispatch]
  );

  const removeAudioList = useCallback(() => {
    dispatch(playlistActions.removeAudioList());
  }, [dispatch]);

  const handleAddAudio = useCallback(
    (audio) => {
      dispatch(playlistActions.selectAudio(audio));
    },
    [dispatch]
  );

  const setPlayingTrackId = useCallback(
    (audio) => {
      dispatch(playerActions.setPlayingTrackId(audio?._id));
    },
    [dispatch]
  );

  const setIsAudioPlay = useCallback(
    (value: boolean) => {
      dispatch(playerActions.setIsAudioPlay(value));
    },
    [dispatch]
  );

  const hanldeShowImageBottom = useCallback(() => {
    dispatch(playerActions.setShowFooterPlayer(false));
  }, [dispatch]);

  const handleRemoveImageBottom = useCallback(() => {
    dispatch(playerActions.setShowFooterPlayer(true));
  }, [dispatch]);

  const handlePlayOrPause = useCallback(
    (track) => {
      setIsAudioPlay(true);
      setPlayingTrack({ ...track, totalPlay: Number(track?.totalPlay + 1) });
      setPlayingTrackId(track);
    },
    [setIsAudioPlay, setPlayingTrack, setPlayingTrackId]
  );
  const handleTogglePlayPause = useCallback(async () => {
    if (wavesurfer.current) {
      const isPlayingAudio = wavesurfer?.current?.isPlaying();
      dispatch(playerActions.setIsPlaying(!isPlayingAudio));
      if (isPlayingAudio) {
        if (volume === 0) {
          wavesurfer?.current?.pause();
        } else if (volume <= 0.3) {
          let curVol = 0.2;
          wavesurfer.current.setVolume(curVol - 0.1);
          await sleepDeplayTime(1);
          wavesurfer.current.setVolume(curVol - 0.2);
          await sleepDeplayTime(2);
          wavesurfer?.current?.pause();
        } else {
          let curVol = 0.4;
          wavesurfer.current.setVolume(curVol - 0.1);
          await sleepDeplayTime(1);
          wavesurfer.current.setVolume(curVol - 0.2);
          await sleepDeplayTime(1);
          wavesurfer.current.setVolume(curVol - 0.3);
          await sleepDeplayTime(1);
          wavesurfer?.current?.pause();
        }
      } else {
        wavesurfer.current.setVolume(0);
        await sleepDeplayTime(1);
        wavesurfer.current.setVolume(volume);
        wavesurfer?.current?.play();
      }
    }
  }, [dispatch, volume, wavesurfer]);

  const onVolumeChange = useCallback(
    (value) => {
      const currentVolumn = Number(value / 100);
      wavesurfer.current.setVolume(currentVolumn);
      setVolume(currentVolumn);
    },
    [setVolume, wavesurfer]
  );

  const setCurrentTimeValue = useCallback((value) => {
    const ele: any = document.getElementById("player-progress-audio");
    if (ele) {
      ele.value = value;
      ele.style.background =
        "linear-gradient(to right, #d4f70e 0%, #d4f70e " +
        (value - 0.15) +
        "%, #38383c " +
        (value - 0.15) +
        "%, #38383c 100%)";
    }
  }, []);

  const seekTo = useCallback(
    (value: number) => {
      wavesurfer.current.seekTo(value);
    },
    [wavesurfer]
  );

  const handleNextOrPrevious = useCallback(
    async (isNextPlay) => {
      const currentIndex = audioList.findLastIndex(
        (track) => track?._id === playingTrack?._id
      );
      if (isShuffleRef.current) {
        const audio = getChooseAudio();
        handlePlayOrPause(audio);
        return;
      }
      if (isNextPlay) {
        if (currentIndex === audioList.length - 1 && playingTrack?._id) {
          if (loopTypeRef.current === LOOP_TYPE.NO_LOOP) {
            const nextTrack = await TrackService.getNextTrack(
              playingTrack?._id
            );
            handlePlayOrPause(nextTrack);
          } else {
            handlePlayOrPause(audioList[0]);
          }
        } else {
          handlePlayOrPause(audioList[currentIndex + 1]);
        }
      } else {
        if (currentIndex === 0) {
          handlePlayOrPause(audioList[audioList.length - 1]);
        } else {
          handlePlayOrPause(audioList[currentIndex - 1]);
        }
      }
    },
    [audioList, handlePlayOrPause, getChooseAudio, playingTrack?._id]
  );

  const handleIncreasingTrackSoundPackPlay = useCallback(
    async (playingTrack) => {
      if (loggedIn) {
        const ipAddress = await getIpAddress();
        if (checkTypeTrackOrSoundPack(playingTrack)) {
          dispatch(
            increasingTrackPlay({
              id: playingTrack?._id as string,
              ipAddress: ipAddress,
            })
          );
        } else {
          dispatch(
            increasingSoundPackPlay({
              id: playingTrack?._id as string,
              ipAddress: ipAddress,
            })
          );
        }
      }
    },
    [dispatch, loggedIn]
  );

  const loadWaveform = useCallback(() => {
    if (!playingTrack?.mergeAudio && !playingTrack?.audioFileUrl) {
      return;
    }

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.load(
      playingTrack?.mergeAudio ||
        playingTrack?.audioFileUrl ||
        AUDIO_SUPERPRODUCTOR
    );
    wavesurfer.current.on("loading", () => {
      dispatch(playerActions.setIsLoadingPlay(true));
    });

    wavesurfer.current.on("ready", function () {
      if (wavesurfer.current) {
        dispatch(playerActions.setIsLoadingPlay(false));
        wavesurfer.current.playPause();
        durationTimeRef.current = wavesurfer?.current?.getDuration();
        dispatch(playerActions.setIsPlaying(true));
        const currentVolume = Math.min(Math.max(volume, 0), 1);
        wavesurfer.current.setVolume(currentVolume);
        let volumePlay = currentVolume;
        if (currentVolume !== 0) {
          const interval = setInterval(function () {
            volumePlay = Number(volumePlay) + Number(0.05);
            if (volumePlay >= currentVolume) {
              clearInterval(interval);
            }
            volumePlay = Math.min(Math.max(volumePlay, 0), 1);
            wavesurfer?.current?.setVolume(volumePlay || 0);
          }, 100);
        }
        handleIncreasingTrackSoundPackPlay(playingTrack);
      }
    });

    wavesurfer.current.on("audioprocess", () => {
      const totalTime = document.getElementById("totalTime");
      setCurrentTimeValue(
        (wavesurfer?.current?.getCurrentTime() /
          wavesurfer?.current?.getDuration()) *
          100
      );
      if (totalTime) {
        totalTime.innerHTML = wavesurfer?.current?.getDuration();
      }
    });

    wavesurfer.current.on("finish", () => {
      seekTo(0);
      setCurrentTimeValue(0);
      switch (loopTypeRef.current) {
        case LOOP_TYPE.NO_LOOP:
          wavesurfer.current.pause();
          dispatch(playerActions.setIsPlaying(false));
          break;
        case LOOP_TYPE.LOOP_ONE:
          wavesurfer.current.play();
          dispatch(playerActions.setIsPlaying(true));
          break;
        case LOOP_TYPE.LOOP:
          audioList?.length > 0
            ? handleNextOrPrevious(true)
            : wavesurfer.current.play();
          dispatch(playerActions.setIsPlaying(true));
          break;

        default:
          break;
      }
    });

    wavesurfer.current.on("error", () => {
      handleNextOrPrevious(true);
    });
  }, [
    audioList?.length,
    playingTrack,
    formWaveSurferOptions,
    wavesurfer,
    dispatch,
    setCurrentTimeValue,
    seekTo,
    handleNextOrPrevious,
    handleIncreasingTrackSoundPackPlay,
  ]);

  const handleRelead = useCallback(() => {
    seekTo(0);
    wavesurfer?.current?.play();
    dispatch(playerActions.setIsPlaying(true));
  }, [dispatch, seekTo, wavesurfer]);

  const onSetLoopAudioType = useCallback(() => {
    let selectType = LOOP_TYPE.NO_LOOP;
    switch (loopType) {
      case LOOP_TYPE.NO_LOOP:
        selectType = LOOP_TYPE.LOOP;
        break;
      case LOOP_TYPE.LOOP:
        selectType = LOOP_TYPE.LOOP_ONE;
        break;
      case LOOP_TYPE.LOOP_ONE:
        selectType = LOOP_TYPE.NO_LOOP;
        break;
      default:
        break;
    }
    loopTypeRef.current = selectType;
    dispatch(playlistActions.setLoopAudioType(selectType));
  }, [dispatch, loopType]);

  const handleChangeCurrentTime = useCallback(
    (e) => {
      wavesurfer.current.seekTo(e.target.value / 100);
      setCurrentTimeValue(
        (wavesurfer?.current?.getCurrentTime() /
          wavesurfer?.current?.getDuration()) *
          100
      );
    },
    [setCurrentTimeValue, wavesurfer]
  );

  return {
    isLoading,
    showFooterPlayer,
    isAudioPlay,
    playingTrack,
    waveform,
    durationTime: durationTimeRef.current,
    volume,
    waveformRef,
    wavesurfer,
    wavesurferRef: wavesurfer && wavesurfer.current ? wavesurfer.current : null,
    isPlaying,
    isOpen,
    playingTrackId,
    isOpenPlaylist,
    audioList,
    loopType,
    isShuffle,
    isMuted,
    handlePlayOrPause,
    handleRelead,
    setIsAudioPlay,
    setPlayingTrack,
    setWaveform,
    setVolume,
    handleTogglePlayPause,
    onVolumeChange,
    loadWaveform,
    setIsOpen,
    setPlayingTrackId,
    setIsOpenPlaylist,
    removeAudioList,
    handleNextOrPrevious,
    onSetLoopAudioType,
    onToggleShuffleAudio,
    handleAddAudio,
    handleChangeCurrentTime,
    hanldeShowImageBottom,
    handleRemoveImageBottom,
    handlePlayPlaylists,
    isPlayAllPlaylist,
    playlistIdPlaying,
    playlistsAlgolia,
  };
};
