import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Back } from "app/assets/icons";
import AvatarUser from "app/assets/images/UserAvatar.png";
import TextInput from "app/components/TextInput";
import { selectUserAdmin } from "app/redux/selectors/users";
import { PROFILE_TYPE, PRO_LIST_COUNTRY } from "constants/contractType";
import DeviceDetector from "device-detector-js";
import { useProfile } from "hooks/profile/useProfile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import { BeatLoader } from "react-spinners";
import { typeDivice } from "utils/checkScreen";
import ModalEditAvatarCrop from "./modalEditAvatarCrop";
import {
  changeCurrentPassword,
  changeFeeStructure,
  connectMailChimp,
  disconnectMailChimp,
  getAccessTokenMailChimp,
  getArtistsSpotifyOfUser,
  getListArtistSpotify,
  getListTracksSpotify,
  getTracksSpotifyOfUser,
  logout,
  selectedListMailChimp,
} from "app/redux/actions/users";
import {
  selectorsArtistsSpotifyOfUser,
  selectorsListArtistSpotify,
  selectorsListTrackSpotify,
  selectorsTracksSpotifyOfUser,
} from "app/redux/selectors/analytics";
import { useDebounce } from "hooks/useDebounce";
import IconAddExpertise from "app/assets/icons/IconAddExpertise";
import IconRemoveExpertise from "app/assets/icons/IconRemoveExpertise";
import IconStudioOneDaw from "app/assets/icons/IconStudioOneDaw";
import IconFlStudioDaw from "app/assets/icons/IconFlStudioDaw";
import IconAbletonDaw from "app/assets/icons/IconAbletonDaw";
import IconReasonDaw from "app/assets/icons/IconReasonDaw";
import IconProToolsDaw from "app/assets/icons/IconProToolsDaw";
import IconBandlabDaw from "app/assets/icons/IconBandlabDaw";
import IconLogicDaw from "app/assets/icons/IconLogicDaw";
import { RootState } from "types/RootState";
import { isLoadingSelector } from "app/redux/selectors/status";
import IconMailChimp from "app/assets/images/IconMailChimp.svg";
import { usePlayers } from "hooks/player/usePlayers";
import { planType } from "constants/planType";

export default function EditProfile() {
  const { t } = useTranslation();
  const history = useHistory();
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const [searchArtistSpotify, setSearchArtistSpotify] = useState<string>("");
  const [searchTrackSpotify, setSearchTrackSpotify] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const debouncedConfirmNewPassword = useDebounce<string>(
    confirmNewPassword,
    500
  );
  const currentUser = useSelector(selectUserAdmin);

  const checkPlanSubscription = useMemo(
    () => currentUser?.subscriptionType === planType.MAINSTREAM,
    [currentUser?.subscriptionType]
  );
  const isLoadingSelectedMailchimp = useSelector((state: RootState) =>
    isLoadingSelector([selectedListMailChimp.typePrefix], state)
  );
  const isLoadingDisconnectMailchimp = useSelector((state: RootState) =>
    isLoadingSelector([disconnectMailChimp.typePrefix], state)
  );

  const [checkMinPassword, setCheckMinPassword] = useState(false);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    errors,
    control,
    onSubmit,
    progress,
    setShowModalEditAvatar,
    symbols,
    showModalEditAvatar,
    setPreviewAvatar,
    previewAvatar,
    handleUpload,
    isLoadingUpdateProfile,
    proSociety,
    expertises,
    setExpertises,
    daws,
    setDaws,
  } = useProfile();
  const artistsSpotify = useSelector(selectorsListArtistSpotify);
  const tracksSpotify = useSelector(selectorsListTrackSpotify);
  const artistSpotifyOfUser = useSelector(selectorsArtistsSpotifyOfUser);
  const tracksSpotifyOfUser = useSelector(selectorsTracksSpotifyOfUser);
  const debouncedSearchArtist = useDebounce<string>(searchArtistSpotify, 200);
  const debouncedSearchTrack = useDebounce<string>(searchTrackSpotify, 200);
  const isLoadingChangePassword = useSelector((state: RootState) =>
    isLoadingSelector([changeCurrentPassword.typePrefix], state)
  );
  const isLoadingChangeFeeStructure = useSelector((state: RootState) =>
    isLoadingSelector([changeFeeStructure.typePrefix], state)
  );
  const { wavesurfer, setIsAudioPlay } = usePlayers();

  const [selectedMailchimp, setSelectedMailchimp] = useState<number>(0);
  const [selectedFeeStructure, setSelectedFeeStructure] = useState<string>(
    currentUser?.platformFee === 0 ? "BUYER_COVER_FEE" : "I_AGREED_COVER_FEE"
  );

  const EXPERTISE_TYPE = useMemo(
    () => [
      "Producer",
      "Samplemaker",
      "Loopmaker",
      "Beatmaker",
      "Composer",
      "Songwriter",
      "Engineer",
    ],
    []
  );
  useEffect(() => {
    const currentMailchimp = currentUser?.mailchimp?.mailLists?.find(
      (item) => item?.isSelected
    );
    setSelectedMailchimp(currentMailchimp?.webId);
  }, [currentUser?.mailchimp?.mailLists]);

  const DAW_TYPE = useMemo(
    () => [
      {
        name: "Studio One",
        value: "studioOne",
        icon: <IconStudioOneDaw />,
      },
      {
        name: "Fl Studio",
        value: "flStudio",
        icon: <IconFlStudioDaw />,
      },
      {
        name: "Ableton",
        value: "ableton",
        icon: <IconAbletonDaw />,
      },
      {
        name: "Logic",
        value: "logic",
        icon: <IconLogicDaw />,
      },
      {
        name: "Reason",
        value: "reason",
        icon: <IconReasonDaw />,
      },
      {
        name: "Pro Tools",
        value: "proTools",
        icon: <IconProToolsDaw />,
      },
      {
        name: "Bandlab",
        value: "bandlab",
        icon: <IconBandlabDaw />,
      },
    ],
    []
  );

  const filterExpertise = useMemo(() => {
    return EXPERTISE_TYPE.filter((item) => !expertises?.includes(item));
  }, [EXPERTISE_TYPE, expertises]);
  const filterDaw = useMemo(() => {
    return DAW_TYPE.filter((item) => !daws?.includes(item.value));
  }, [DAW_TYPE, daws]);

  const selectedDaw = useMemo(() => {
    return DAW_TYPE.filter((item) => daws?.includes(item.value));
  }, [DAW_TYPE, daws]);

  const filterSpotify = useCallback(
    (itemSpotify) => [
      ...new Map((itemSpotify || []).map((item) => [item?.id, item])).values(),
    ],
    []
  );

  const handleRemoveExpertise = useCallback(
    (value) => {
      const filterValue: string[] = expertises?.filter(
        (item: string) => item !== value
      );
      setExpertises(filterValue);
    },
    [expertises, setExpertises]
  );

  const handleConnectMailChimp = useCallback(async () => {
    if (currentUser?.connectMailChimp) {
      dispatch(disconnectMailChimp());
      return;
    } else {
      dispatch(connectMailChimp());
    }
  }, [currentUser?.connectMailChimp, dispatch]);

  const handleSelectedMailChimp = useCallback(() => {
    dispatch(selectedListMailChimp(selectedMailchimp));
  }, [dispatch, selectedMailchimp]);

  const handleRemoveDaw = useCallback(
    (value) => {
      const filterValue = daws.filter((item) => item !== value);
      setDaws(filterValue);
    },
    [daws, setDaws]
  );

  const formatArtistsSpotify = useMemo(() => {
    const totalArtists = [...artistsSpotify, ...artistSpotifyOfUser];
    return totalArtists.map((item: any) => {
      return {
        ...item,
        value: item?.name,
      };
    });
  }, [artistsSpotify, artistSpotifyOfUser]);

  const formatTracksSpotify = useMemo(() => {
    const totalTracks = [...tracksSpotify, ...tracksSpotifyOfUser];
    return totalTracks.map((item: any) => {
      return {
        ...item,
        value: item?.name,
      };
    });
  }, [tracksSpotify, tracksSpotifyOfUser]);

  const formatListsMailChimp = useMemo(() => {
    return currentUser?.mailchimp?.mailLists?.map((item) => {
      return {
        ...item,
        value: item.webId,
        label: item.name,
      };
    });
  }, [currentUser?.mailchimp?.mailLists]);

  const valueListMailchimp = useMemo(() => {
    if (selectedMailchimp !== 0) {
      return formatListsMailChimp?.filter(
        (item) => item.webId === selectedMailchimp
      );
    }
    return formatListsMailChimp?.filter((item) => item.isSelected);
  }, [formatListsMailChimp, selectedMailchimp]);

  const optionsFeeStructure = useMemo(
    () => [
      {
        value: "BUYER_COVER_FEE",
        label: t("buyerCoverFee"),
      },
      {
        value: "I_AGREED_COVER_FEE",
        label: t("agreedToCoverFee"),
      },
    ],
    [t]
  );

  const valueFeeStructure = useMemo(() => {
    return optionsFeeStructure?.filter(
      (item) => item.value === selectedFeeStructure
    );
  }, [optionsFeeStructure, selectedFeeStructure]);

  const handleChangeFeeStructure = useCallback(() => {
    dispatch(changeFeeStructure(selectedFeeStructure));
  }, [dispatch, selectedFeeStructure]);

  useEffect(() => {
    dispatch(getListArtistSpotify(debouncedSearchArtist));
  }, [debouncedSearchArtist, dispatch]);
  useEffect(() => {
    dispatch(getListTracksSpotify(debouncedSearchTrack));
  }, [debouncedSearchTrack, dispatch]);

  useEffect(() => {
    if (currentUser && currentUser?.artistSpotifyList?.length > 0) {
      dispatch(getArtistsSpotifyOfUser(currentUser?.artistSpotifyList));
    }
  }, [currentUser?.artistSpotifyList, dispatch, currentUser]);

  useEffect(() => {
    if (currentUser && currentUser?.trackSpotifyList?.length > 0) {
      dispatch(getTracksSpotifyOfUser(currentUser?.trackSpotifyList));
    }
  }, [currentUser?.trackSpotifyList, dispatch, currentUser]);

  useEffect(() => {
    if (currentUser) {
      setSelectedFeeStructure(
        currentUser?.platformFee === 0
          ? "BUYER_COVER_FEE"
          : "I_AGREED_COVER_FEE"
      );
    }
  }, [currentUser]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code && !currentUser?.connectMailChimp) {
      dispatch(getAccessTokenMailChimp(code));
    }
  }, [currentUser?.connectMailChimp, dispatch]);

  const mtFlag =
    useBreakpointValue({ base: "5px", md: "0px", xl: "5px" }) || "5px";
  const fontSizeFlag =
    useBreakpointValue({ base: "30px", md: "20px", xl: "30px" }) || "30px";
  const options = useMemo(() => countryList().getData(), []);
  const contryList = options.map((item) => {
    return {
      ...item,
      icon: (
        <ReactCountryFlag
          countryCode={item.value}
          style={{
            fontSize: "30px",
          }}
          svg={device.device?.brand === typeDivice.APPLE ? false : true}
        />
      ),
    };
  });

  const handleProfile = useCallback(() => {
    history.push(
      `/user/${currentUser.name}?profileType=${PROFILE_TYPE.TRACKS}`
    );
  }, [currentUser.name, history]);

  const handleChangeCurrentPassword = useCallback(() => {
    if (newPassword?.length < 8) {
      setCheckMinPassword(true);
      return;
    }
    dispatch(
      changeCurrentPassword({
        email: currentUser?.email,
        password: currentPassword,
        newPassword: newPassword,
      })
    );
  }, [dispatch, currentPassword, newPassword, currentUser?.email]);

  const handleLogoutAllDevices = useCallback(() => {
    dispatch(logout({ isLogoutAll: true, userId: currentUser?._id }));
    if (wavesurfer) {
      wavesurfer.current?.pause();
      wavesurfer.current = null;
      setIsAudioPlay(false);
    }
  }, [currentUser?._id, dispatch, setIsAudioPlay, wavesurfer]);

  const informations = [
    {
      idLabel: "firstName",
      nameProfile: "First Name",
      handleChange: "",
      isDisable: false,
      placeholder: "Name",
      isRequired: true,
      value: currentUser?.firstName,
    },

    {
      idLabel: "lastName",
      nameProfile: "Last Name",
      handleChange: "",
      isDisable: false,
      placeholder: "Last Name",
      isRequired: false,
      value: currentUser?.lastName,
    },
  ];

  const formatOptionLabelSpotify = ({ name, type, images, album }: any) => {
    return (
      <Flex
        background="#1F1F23"
        alignItems="center"
        borderRadius="4px"
        padding="8px 15px"
        margin="0px"
      >
        {type === "track" ? (
          <Flex alignItems="center">
            <Image
              w="36px"
              height="36px"
              borderRadius="full"
              src={album?.images?.[0]?.url}
              fallbackSrc={AvatarUser}
            />
            <Box ml="15px">
              <Text fontSize="14px" fontWeight="600">
                {name}
              </Text>
              <Text fontSize="14px" fontWeight="400" color="#ABABAB">
                {album?.artists?.[0]?.name}
              </Text>
            </Box>
          </Flex>
        ) : (
          <Flex alignItems="center">
            <Image
              w="36px"
              height="36px"
              borderRadius="full"
              src={images[0]?.url}
              fallbackSrc={AvatarUser}
            />
            <Text noOfLines={2} ml="15px" fontSize="14px" fontWeight="600">
              {name}
            </Text>
          </Flex>
        )}
      </Flex>
    );
  };

  const formatOptionLabel = ({ label, icon }: any) => (
    <Flex
      background="#1F1F23"
      alignItems="center"
      borderRadius="4px"
      justifyContent="space-between"
      height="48px"
      padding="5px 15px"
      margin="0px"
    >
      <Text fontSize="14px" fontWeight="600">
        {label}
      </Text>
      {icon}
    </Flex>
  );

  const formatOptionLabelProList = ({ label, value, name }: any) => (
    <Flex
      background="#1F1F23"
      alignItems="center"
      borderRadius="4px"
      justifyContent="space-between"
      height="48px"
      padding="5px 15px"
      margin="0px"
      direction={{ base: "row", md: "column", xl: "row" }}
    >
      <Text
        fontSize={{ base: "14px", md: "12px", xl: "14px" }}
        w={{ md: "100%", xl: "unset" }}
      >
        {label}
      </Text>
      <Flex
        alignItems="center"
        justifyContent={{ md: "space-between", xl: "start" }}
        w={{ md: "100%", xl: "unset" }}
      >
        <Text
          whiteSpace="nowrap"
          fontSize={{ base: "14px", md: "12px", xl: "14px" }}
          mr="5px"
        >
          {value}
        </Text>
        <ReactCountryFlag
          countryCode={name}
          style={{
            fontSize: fontSizeFlag,
            marginTop: mtFlag,
          }}
          svg={device.device?.brand === typeDivice.APPLE ? false : true}
        />
      </Flex>
    </Flex>
  );

  return (
    <>
      <Box p="16px 16px 50px 16px" className="edit-profile" overflow="scroll">
        <Flex alignItems="center" mb={{ base: "24px", md: "28px", xl: "32px" }}>
          <Box
            onClick={handleProfile}
            cursor="pointer"
            className="vector-button"
          >
            <Back />
          </Box>
          <Text
            fontWeight="600"
            fontSize={{ base: "24px", md: "28px", xl: "32px" }}
            lineHeight="24px"
            color="#FFFFFF"
          >
            {t("accountSettings")}
          </Text>
        </Flex>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex pb="30px" alignItems="center" gap="20px">
            <Image
              w={{ base: "90px", md: "100px", xl: "110px" }}
              height={{ base: "90px", md: "100px", xl: "110px" }}
              borderRadius="50%"
              src={currentUser?.profileImage}
              fallbackSrc={AvatarUser}
            />
            <Box>
              <Flex alignItems="center">
                <Box onClick={setShowModalEditAvatar} cursor="pointer">
                  <MdOutlineModeEdit color="#D4F70E" size="20px" />
                </Box>
                <Text
                  fontWeight="500"
                  fontSize={{ base: "14px", md: "16px" }}
                  ml="10px"
                >
                  {t("EditAvatar")}
                </Text>
              </Flex>
              {progress > 0 && (
                <Text className="progress">
                  {progress}% {t("uploaded")}
                </Text>
              )}
              <Text pt="6px" color="#535353" fontSize="12px">
                {t("MinimumSize")}
              </Text>
            </Box>
          </Flex>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={{ base: 0, md: 10 }}
            w={{ base: "100%", xl: "95%" }}
          >
            <Box>
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 2, md: 4 }}
                className="input-section"
              >
                {informations.map((information, i) => (
                  <Box key={i}>
                    <label
                      color="#535353"
                      className="label"
                      htmlFor={information.idLabel}
                    >
                      {information.nameProfile}
                    </label>
                    <TextInput
                      type="text"
                      name={information.idLabel}
                      placeholder={information.placeholder}
                      disabled={information.isDisable}
                      control={control}
                      errors={errors}
                      defaultValue={information.value}
                      className="item-input"
                      backgroundColor="#24252a"
                      height="52px"
                    />
                  </Box>
                ))}
              </SimpleGrid>
              <Box
                minH={{ base: "unset", md: "128px" }}
                mb={{ base: "16px", md: "0px" }}
              >
                <Text
                  pb="6px"
                  fontSize="16px"
                  color=" #535353"
                  pt={{ base: "16px", md: "24px" }}
                >
                  {t("Country")}
                </Text>
                <Controller
                  name={"country"}
                  render={({ field }) => {
                    const value = contryList.find(
                      (item) => item.value === field.value
                    );
                    return (
                      <Select
                        className="select-flag"
                        classNamePrefix="select"
                        options={contryList}
                        placeholder={t("searchCountry")}
                        formatOptionLabel={formatOptionLabel}
                        {...field}
                        value={value}
                      />
                    );
                  }}
                  control={control}
                />
              </Box>

              <SimpleGrid
                columns={{ base: 1, lg: 2 }}
                spacing={{ base: 2, md: 4 }}
                display={{ base: "none", md: "grid" }}
              >
                <Box
                  minH={{ base: "unset", md: "128px" }}
                  mb={{ base: "16px", md: "0px" }}
                >
                  <Text
                    pb="6px"
                    fontSize="16px"
                    color=" #535353"
                    pt={{ base: "16px", md: "24px" }}
                  >
                    {t("expertise")}
                  </Text>
                  <Flex
                    borderRadius="4px"
                    background="#25252A"
                    minH="52px"
                    padding="10px 15px"
                    flexWrap="wrap"
                    gap="7px"
                  >
                    {filterExpertise?.map((item) => (
                      <Flex
                        borderRadius="4px"
                        alignItems={"center"}
                        background="#2A2A30"
                        padding="3px 8px"
                        alignSelf="center"
                        border="1px solid #5E5E5E"
                        minW="107px"
                        justifyContent="center"
                        key={item}
                        onClick={() => setExpertises([...expertises, item])}
                        cursor="pointer"
                      >
                        <Text color="#D4F70E" fontSize="12px" mr="5px">
                          {item}
                        </Text>
                        <IconAddExpertise />
                      </Flex>
                    ))}
                  </Flex>
                  <Flex flexWrap="wrap" gap="7px" mt="15px">
                    {expertises?.map((item) => (
                      <Flex
                        borderRadius="4px"
                        alignItems={"center"}
                        background="#2A2A30"
                        padding="3px 8px"
                        alignSelf="center"
                        border="1px solid #5E5E5E"
                        key={item}
                        minW="107px"
                        justifyContent="center"
                        cursor="pointer"
                        onClick={() => handleRemoveExpertise(item)}
                      >
                        <Text color="#D4F70E" fontSize="12px" mr="5px">
                          {item}
                        </Text>
                        <IconRemoveExpertise />
                      </Flex>
                    ))}
                  </Flex>
                </Box>

                <Box
                  minH={{ base: "unset", md: "128px" }}
                  mb={{ base: "16px", md: "0px" }}
                >
                  <Text
                    pb="6px"
                    fontSize="16px"
                    color=" #535353"
                    pt={{ base: "16px", md: "24px" }}
                  >
                    {t("daw")}
                  </Text>

                  <Flex
                    borderRadius="4px"
                    background="#25252A"
                    minH="52px"
                    padding="10px 15px"
                    flexWrap="wrap"
                    gap="7px"
                  >
                    {filterDaw?.map((item) => (
                      <Flex
                        borderRadius="4px"
                        alignItems={"center"}
                        background="#2A2A30"
                        padding="3px 8px"
                        alignSelf="center"
                        border="1px solid #5E5E5E"
                        minW="107px"
                        minH="28px"
                        justifyContent="center"
                        key={item.value}
                        onClick={() => setDaws([...daws, item.value])}
                        cursor="pointer"
                      >
                        {item.icon}
                        <Text color="#D4F70E" fontSize="12px" ml="5px" mr="5px">
                          {item.name}
                        </Text>
                        <IconAddExpertise />
                      </Flex>
                    ))}
                  </Flex>
                  <Flex flexWrap="wrap" gap="7px" mt="15px">
                    {selectedDaw?.map((item) => (
                      <Flex
                        borderRadius="4px"
                        alignItems={"center"}
                        background="#2A2A30"
                        padding="3px 8px"
                        alignSelf="center"
                        border="1px solid #5E5E5E"
                        key={item.value}
                        minW="107px"
                        minH="28px"
                        justifyContent="center"
                        cursor="pointer"
                        onClick={() => handleRemoveDaw(item.value)}
                      >
                        {item.icon}
                        <Text color="#D4F70E" fontSize="12px" ml="5px" mr="5px">
                          {item.name}
                        </Text>
                        <IconRemoveExpertise />
                      </Flex>
                    ))}
                  </Flex>
                </Box>
              </SimpleGrid>

              <Box
                display={{ base: "none", md: "block" }}
                className="input-section"
                pt="21px"
                w="100%"
              >
                <label color="#535353" className="label" htmlFor={"biography"}>
                  {t("About")}
                </label>
                <TextInput
                  type="textarea"
                  name="aboutMe"
                  placeholder="Biography"
                  disabled={false}
                  control={control}
                  errors={errors}
                  className="item-input"
                  defaultValue={currentUser?.aboutMe}
                  backgroundColor="#24252a"
                />
              </Box>

              <Flex
                display={{ base: "none", md: "block" }}
                justifyContent="space-between"
                pt="16px"
              >
                <Flex>
                  <Text pr="1px" fontSize="14px" color="#535353">
                    {`${symbols}/300`}
                  </Text>
                  <Text fontSize="14px" color="#535353">
                    {t("Symbols")}
                  </Text>
                </Flex>
                {symbols >= 300 && (
                  <Text fontSize="14px" color="#ED3636">
                    {t("NotWriteMore")}
                  </Text>
                )}
              </Flex>
            </Box>
            <Box>
              <Flex gap="15px" direction={{ base: "column", md: "row" }}>
                <Box className="input-section" w={{ base: "100%", md: "45%" }}>
                  <label
                    color="#535353"
                    className="label"
                    htmlFor={t("displayName")}
                  >
                    {t("pseudonymName")}
                  </label>
                  <TextInput
                    type="text"
                    name="name"
                    placeholder={t("pseudonymName")}
                    disabled={false}
                    control={control}
                    errors={errors}
                    className="item-input"
                    defaultValue={currentUser?.name}
                    backgroundColor="#24252a"
                  />
                </Box>
                <Box className="input-section" w={{ base: "100%", md: "55%" }}>
                  <label color="#535353" className="label">
                    {t("proOption")}
                  </label>
                  <Controller
                    name={"proCountry"}
                    render={({ field }) => {
                      const value = PRO_LIST_COUNTRY.find(
                        (item) => item.label === field.value
                      );
                      return (
                        <Select
                          className="select-flag"
                          classNamePrefix="select"
                          options={PRO_LIST_COUNTRY}
                          placeholder={t("selectProSociety")}
                          formatOptionLabel={formatOptionLabelProList}
                          {...field}
                          value={value}
                        />
                      );
                    }}
                    control={control}
                  />
                  {errors?.proCountry && (
                    <Text fontSize="14px" color="red">
                      {t(`${errors?.proCountry?.message}`)}
                    </Text>
                  )}
                </Box>
              </Flex>

              <Flex
                gap={{ base: "0px", md: "15px" }}
                direction={{ base: "column", md: "row" }}
                minH="128px"
              >
                <Box
                  className="state-providence"
                  w={{
                    base: "100%",
                    md: proSociety?.value || proSociety ? "45%" : "100%",
                  }}
                  pt="16px"
                >
                  <label color="#535353" className="label" htmlFor={"state"}>
                    {t("stateOrProvidence")}
                  </label>
                  <TextInput
                    type="text"
                    name="state"
                    placeholder={t("stateOrProvidence")}
                    disabled={false}
                    control={control}
                    errors={errors}
                    className="item-Providence"
                    defaultValue={currentUser?.state}
                  />
                </Box>
                {proSociety?.value || proSociety ? (
                  <Box
                    className="abc"
                    w={{ base: "100%", md: "55%" }}
                    pt="16px"
                  >
                    <label color="#535353" className="label">
                      {t("publisherIpi")}
                    </label>
                    <TextInput
                      type="number"
                      name="publisherIpi"
                      placeholder={t("enterIpi")}
                      disabled={false}
                      control={control}
                      className="item-Providence"
                    />
                    {errors?.publisherIpi && (
                      <Text fontSize="14px" color="red">
                        {t(`${errors?.publisherIpi?.message}`)}
                      </Text>
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </Flex>

              <Box
                display={{ base: "block", md: "none" }}
                className="input-section"
                pt="12px"
                w="100%"
              >
                <label color="#535353" className="label" htmlFor={"biography"}>
                  {t("About")}
                </label>
                <TextInput
                  type="textarea"
                  name="aboutMe"
                  placeholder="Biography"
                  disabled={false}
                  control={control}
                  errors={errors}
                  className="item-input"
                  defaultValue={currentUser?.aboutMe}
                  backgroundColor="#24252a"
                />
              </Box>

              <Flex
                display={{ base: "block", md: "none" }}
                justifyContent="space-between"
                pt="8px"
              >
                <Flex>
                  <Text
                    pr="1px"
                    fontSize={{ base: "12px", md: "14px" }}
                    color="#535353"
                  >
                    {`${symbols}/300`}
                  </Text>
                  <Text fontSize={{ base: "12px", md: "14px" }} color="#535353">
                    {t("Symbols")}
                  </Text>
                </Flex>
                {symbols >= 300 && (
                  <Text fontSize={{ base: "12px", md: "14px" }} color="#ED3636">
                    {t("NotWriteMore")}
                  </Text>
                )}
              </Flex>

              <Flex
                className="input-section"
                pt={{ base: "12px", md: "19px" }}
                w="100%"
                gap="10px"
                display={{ base: "block", lg: "flex" }}
              >
                <Box w={{ base: "100%", lg: "50%" }}>
                  <Text
                    pb="6px"
                    fontSize="16px"
                    color=" #535353"
                    pt={{ base: "16px", md: "5px" }}
                  >
                    {t("artists")}
                  </Text>

                  <Controller
                    name={"artistSpotifyList"}
                    render={({ field }) => {
                      let valuesSelect: any[] = [];
                      let OptionsArtistSpotify = formatArtistsSpotify;
                      if (typeof field?.value?.[0] === "string") {
                        valuesSelect = field?.value;
                      } else {
                        OptionsArtistSpotify = [
                          ...field?.value,
                          ...formatArtistsSpotify,
                        ];
                        valuesSelect = field?.value.map((item) => item?.id);
                      }
                      const values = filterSpotify(OptionsArtistSpotify).filter(
                        (item) => valuesSelect.includes(item?.id)
                      );
                      return (
                        <Select
                          className="select-flag"
                          classNamePrefix="select"
                          options={filterSpotify(OptionsArtistSpotify)}
                          placeholder={t("artistWhoIWorkWith")}
                          formatOptionLabel={formatOptionLabelSpotify}
                          {...field}
                          onInputChange={(e) => setSearchArtistSpotify(e)}
                          isMulti
                          value={values}
                        />
                      );
                    }}
                    control={control}
                  />
                </Box>
                <Box w={{ base: "100%", lg: "50%" }}>
                  <Text
                    pb="6px"
                    fontSize="16px"
                    color=" #535353"
                    pt={{ base: "16px", md: "5px" }}
                  >
                    {t("tracks")}
                  </Text>

                  <Controller
                    name={"trackSpotifyList"}
                    render={({ field }) => {
                      let valuesSelect: any[] = [];
                      let OptionsTracksSpotify = formatTracksSpotify;
                      if (typeof field?.value?.[0] === "string") {
                        valuesSelect = field?.value;
                      } else {
                        OptionsTracksSpotify = [
                          ...field?.value,
                          ...formatTracksSpotify,
                        ];
                        valuesSelect = field?.value.map((item) => item?.id);
                      }
                      const values = filterSpotify(OptionsTracksSpotify).filter(
                        (item) => valuesSelect.includes(item?.id)
                      );
                      return (
                        <Select
                          className="select-flag"
                          classNamePrefix="select"
                          options={filterSpotify(OptionsTracksSpotify)}
                          placeholder={t("searchByTracktSpotify")}
                          formatOptionLabel={formatOptionLabelSpotify}
                          {...field}
                          onInputChange={(e) => setSearchTrackSpotify(e)}
                          isMulti
                          value={values}
                        />
                      );
                    }}
                    control={control}
                  />
                </Box>
              </Flex>
            </Box>
          </SimpleGrid>
          <Button
            mt={{ base: "40px", md: "48px" }}
            className="button-profile"
            type="submit"
            height={{ base: "50px", md: "60px" }}
            w="150px"
            mx={{ base: "auto", md: "unset" }}
            fontSize={{ base: "14px", md: "16px" }}
            borderRadius="4px"
            color="#D4F70E"
            display="flex"
            justifyContent="center"
            alignItems="center"
            _hover={{ bg: "rgba(200, 232, 21, 0.10)" }}
            bg="rgba(200, 232, 21, 0.10)"
            isLoading={isLoadingUpdateProfile}
            border="1px solid #D4F70E"
            spinner={<BeatLoader size={8} color="white" />}
          >
            {t("Save Change")}
          </Button>
        </form>
        <Divider
          m="50px 0px 30px 0px"
          borderColor="#24252A"
          borderWidth="3px"
        />
        {checkPlanSubscription && (
          <>
            <Text fontSize="20px" mb="30px">
              {t("linkedAccounts")}
            </Text>
            <Box>
              <Flex>
                {currentUser?.connectMailChimp ? (
                  <Flex mr="20px">
                    <Select
                      className="select-flag"
                      classNamePrefix="select"
                      options={formatListsMailChimp}
                      placeholder={t("searchListMailChimp")}
                      value={valueListMailchimp}
                      onChange={(e) => setSelectedMailchimp(e?.webId)}
                    />
                    <Button
                      background="#2B2E1C"
                      color="#D4F70E"
                      borderTopRightRadius="4px"
                      borderBottomRightRadius="4px"
                      borderRadius="0px"
                      border="1px solid #D4F70E"
                      h="48px"
                      padding="10px 30px"
                      _hover={{
                        background: "#2B2E1C",
                      }}
                      onClick={handleSelectedMailChimp}
                      isLoading={isLoadingSelectedMailchimp}
                      spinner={<BeatLoader size={8} color="white" />}
                    >
                      {t("save")}
                    </Button>
                  </Flex>
                ) : (
                  <Flex
                    background="#24252A"
                    padding={{ base: "8px 50px", md: "8px 130px" }}
                    borderTopLeftRadius="4px"
                    borderBottomLeftRadius="4px"
                    h="48px"
                  >
                    <Image fallbackSrc={IconMailChimp} />
                  </Flex>
                )}
                <Button
                  background="#2B2E1C"
                  color="#D4F70E"
                  borderTopRightRadius="4px"
                  borderBottomRightRadius="4px"
                  borderRadius={currentUser?.connectMailChimp ? "4px" : "0px"}
                  border="1px solid #D4F70E"
                  h="48px"
                  padding="10px 30px"
                  _hover={{
                    background: "#2B2E1C",
                  }}
                  onClick={handleConnectMailChimp}
                  spinner={<BeatLoader size={8} color="white" />}
                  isLoading={isLoadingDisconnectMailchimp}
                >
                  {currentUser?.connectMailChimp
                    ? t("disconnect")
                    : t("Connect")}
                </Button>
              </Flex>
            </Box>
            <Divider
              m="50px 0px 30px 0px"
              borderColor="#24252A"
              borderWidth="3px"
            />
          </>
        )}
        {checkPlanSubscription && (
          <>
            <Text fontSize="20px" mb="30px">
              {t("chooseYourFeeStructure")}
            </Text>
            <Flex mr="20px">
              <Select
                className="select-flag fee-structure"
                classNamePrefix="select"
                options={optionsFeeStructure}
                placeholder={t("selectChooseYourFeeStructure")}
                onChange={(e) => setSelectedFeeStructure(e?.value || "")}
                value={valueFeeStructure}
              />
              <Button
                background="#2B2E1C"
                color="#D4F70E"
                borderTopRightRadius="4px"
                borderBottomRightRadius="4px"
                borderRadius="0px"
                border="1px solid #D4F70E"
                h="48px"
                padding="10px 30px"
                _hover={{
                  background: "#2B2E1C",
                }}
                onClick={handleChangeFeeStructure}
                isLoading={isLoadingChangeFeeStructure}
                spinner={<BeatLoader size={8} color="white" />}
              >
                {t("change")}
              </Button>
            </Flex>
            <Divider
              m="50px 0px 30px 0px"
              borderColor="#24252A"
              borderWidth="3px"
            />
          </>
        )}
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 0, md: 10 }}
          w={{ base: "100%", xl: "95%" }}
        >
          <Box>
            <Text mb="30px" fontSize="20px">
              {t("loginAndSecurity")}
            </Text>
            {currentUser?.isPassword && (
              <Box mb="5px">
                <Text color="#535353">{t("currentPassword")}</Text>
                <Input
                  autoComplete="new-password"
                  w={{ base: "100%", lg: "60%" }}
                  type="password"
                  backgroundColor="#24252a"
                  border="none"
                  borderRadius="4px"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              </Box>
            )}
            <Box mb="5px">
              <Text color="#535353">{t("newPassword")}</Text>
              <Input
                autoComplete="new-password"
                w={{ base: "100%", lg: "60%" }}
                type="password"
                backgroundColor="#24252a"
                border="none"
                borderRadius="4px"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  if (e.target.value?.length >= 8) {
                    setCheckMinPassword(false);
                  }
                }}
              />
              {checkMinPassword && (
                <Text fontSize="14px" color="red">
                  {t("passwordMustBeAtLeastCharacters")}
                </Text>
              )}
            </Box>
            <Box>
              <Text color="#535353">{t("confirmNewPassword")}</Text>
              <Input
                w={{ base: "100%", lg: "60%" }}
                type="password"
                backgroundColor="#24252a"
                border="none"
                borderRadius="4px"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                min={8}
              />
              {debouncedConfirmNewPassword &&
                newPassword !== debouncedConfirmNewPassword && (
                  <Text fontSize="14px" color="red">
                    {t("passwordDoesNotMatch")}
                  </Text>
                )}
            </Box>
            <Button
              mt={{ base: "40px", md: "48px" }}
              className="button-profile"
              height={{ base: "50px", md: "60px" }}
              w="150px"
              mx={{ base: "auto", md: "unset" }}
              fontSize={{ base: "14px", md: "16px" }}
              borderRadius="4px"
              color="#D4F70E"
              display="flex"
              justifyContent="center"
              alignItems="center"
              _hover={{ bg: "rgba(200, 232, 21, 0.10)" }}
              bg="rgba(200, 232, 21, 0.10)"
              isLoading={isLoadingChangePassword}
              border="1px solid #D4F70E"
              spinner={<BeatLoader size={8} color="white" />}
              disabled={
                (currentUser?.isPassword && !currentPassword) ||
                !newPassword ||
                newPassword !== debouncedConfirmNewPassword
              }
              onClick={handleChangeCurrentPassword}
            >
              {t("Save Change")}
            </Button>
          </Box>

          <Box>
            <Button
              w="70%"
              mt={{ base: "40px", md: "82px" }}
              className="button-profile"
              height={"48px"}
              mx={{ base: "auto", md: "unset" }}
              fontSize={{ base: "14px", md: "16px" }}
              borderRadius="4px"
              color="#ED3636"
              display="flex"
              justifyContent="center"
              alignItems="center"
              _hover={{ bg: "#2D1C20" }}
              bg="#2D1C20"
              border="1px solid #ED3636"
              spinner={<BeatLoader size={8} color="white" />}
              onClick={handleLogoutAllDevices}
            >
              {t("logoutAllDevices")}
            </Button>
          </Box>
        </SimpleGrid>
      </Box>

      {showModalEditAvatar && (
        <ModalEditAvatarCrop
          setShowModal={setShowModalEditAvatar}
          showModal={showModalEditAvatar}
          previewAvatar={previewAvatar}
          setPreviewAvatar={setPreviewAvatar}
          handleUpload={handleUpload}
        />
      )}
    </>
  );
}
