import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import ModalPriceTracks from "app/components/ModalPriceTrack";
import InfiniteHits from "app/pages/Home/infiniteHits";
import useTrackSelect from "hooks/track/useTrackSelect";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useSearchBox,
  useInfiniteHits,
  useInstantSearch,
} from "react-instantsearch";
import { useHistory, useLocation } from "react-router-dom";
import { textTitleSearchAlgolia, TYPE_PARAMS } from "utils/actionText";
import Musicaians from "./musicians";
import "./styles.scss";
import TrackSearch from "./tracksSearch";
import ModalLyrics from "app/components/ModalShowLyrics";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import ModalAddToPlaylist from "app/pages/Playlists/modalAddToPlaylist";
import ModalCreatePlaylist from "app/pages/Playlists/modalCreatePlaylist";
import PlaylistItemInProfile from "app/components/MyFeed/playlistItemProfile";
import SkeletonPlaylistItemProfile from "app/components/MyFeed/skeletonPlaylistItemProfile";
import SkeletonMusicians from "../skeletonMusicians";
import SkeletonTrackSoundPack from "../skeletonTracksSoundpacks";
import EmptyTrackSoundkit from "app/components/MySoundkits/emptyTrackSoundkit";
import EmptyPlaylist from "app/pages/Playlists/emptyPlaylist";
import ModalShowFreeDownload from "app/components/ModalShowFreeDownload";
import ModalShareLinkRecentActions from "app/pages/Home/modalShareLinkRecentActions";
import CurrentRefinements from "../CurrentRefinements";
import ModalShowFreeDownloadFormMailChimp from "app/components/ModalShowFreeDownloadFormMailchimp";
import { useDispatch } from "react-redux";
import { listPlaylistsTrackSoundkitAlgolia } from "app/redux/actions/playlist";

interface ResultsComponentsProps {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}
function ResultsComponents({
  isLoading,
  setIsLoading,
}: ResultsComponentsProps) {
  const [tabActive, setTabActive] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const typeParams: any = queryString.parse(location.search);
  const { refine } = useSearchBox();
  const checkHastag = !!location.hash;
  const { hits } = useInfiniteHits();
  const { status } = useInstantSearch();

  useEffect(() => {
    const queryParamsParsed = queryString.parse(location.search);
    const searchValue = checkHastag ? location.hash : queryParamsParsed.q || "";
    refine(searchValue as string);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
      setIsLoading(true);
    };
  }, [checkHastag, location.hash, location.search, refine, setIsLoading]);

  useEffect(() => {
    if (tabActive === 1 || tabActive === 2) {
      dispatch(listPlaylistsTrackSoundkitAlgolia(hits));
    }
  }, [dispatch, hits, tabActive]);

  useEffect(() => {
    const unlisten = history.block((location) => {
      if (tabActive === 1 || tabActive === 2) {
        dispatch(listPlaylistsTrackSoundkitAlgolia([]));
      }
    });
    return () => {
      unlisten();
    };
  }, [dispatch, history, tabActive]);

  const {
    selectedTrack,
    showOptionsModal,
    setShowOptionsModal,
    onOpenOptionsModal,
    showLyrics,
    setShowLyrics,
    isEditLyrics,
    setIsEditLyrics,
    lyricsData,
    showModalFreeDownload,
    setShowModalFreeDownload,
    handleFreeDownload,
    linkTitleDownload,
    showModalShareLink,
    setShowModalShareLink,
    shortLink,
    loadingUrl,
    showModalAndGetdataShareLink,
    setLoadingUrl,
    setShortLink,
    handleShowModalFreeDownloadMailchimp,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
  } = useTrackSelect();

  const {
    trackId,
    setShowModalAddToPlaylist,
    showModalAddToPlaylist,
    handleShowModalAddTrack,
    showModalCreatePlaylist,
    setShowModalCreatePlaylist,
    playlists,
    handleSearchText,
    debouncedSearchText,
  } = usePlaylists();

  const listMenuTabs = useMemo(
    () => [
      {
        name: t("musicians"),
        params: "musicians",
        id: 0,
      },
      {
        name: t("Tracks"),
        params: "tracks",
        id: 1,
      },
      {
        name: t("soundPacks"),
        params: "soundKits",
        id: 2,
      },
      {
        name: t("playlists"),
        params: "playlists",
        id: 3,
      },
      {
        name: t("tags"),
        params: "tags",
        id: 4,
      },
    ],
    [t]
  );

  useEffect(() => {
    if (checkHastag) {
      const hashTags = location.hash;
      setTabActive(4);
      history.push(`/search?type=tags&q=${hashTags}`);
      return;
    }

    const tabs = [
      TYPE_PARAMS.musicians,
      TYPE_PARAMS.tracks,
      TYPE_PARAMS.soundKits,
      TYPE_PARAMS.playlists,
      TYPE_PARAMS.tags,
    ];
    const index = tabs.indexOf(typeParams?.type) || 0;
    setTabActive(index);
  }, [checkHastag, history, location.hash, typeParams?.type]);

  const handleChangTabs = useCallback(
    (value) => {
      const queryParamsParsed = queryString.parse(location.search);
      const searchValue = queryParamsParsed.q || "";

      setTabActive(value);
      const typeParams = textTitleSearchAlgolia(value);
      history.push(
        `/search?type=${typeParams.params}${
          searchValue ? `&q=${searchValue}` : ""
        }`
      );
    },
    [history, location.search]
  );

  const HitPlaylists = useCallback(({ hit }) => {
    return (
      <Box>
        <PlaylistItemInProfile playlist={hit} />
      </Box>
    );
  }, []);

  const HitMusicians = useCallback(({ hit }) => {
    return (
      <Box>
        <Musicaians item={hit} />
      </Box>
    );
  }, []);

  const HitTracksOrSoundPacks = useCallback(
    ({ hit }) => {
      return (
        <Box>
          <TrackSearch
            handleShowModalAddTrack={handleShowModalAddTrack}
            onOpenOptionsModal={onOpenOptionsModal}
            item={hit}
            handleFreeDownload={handleFreeDownload}
            onOpenModalShareLink={showModalAndGetdataShareLink}
            setShortLinkUrl={setShortLink}
            setLoadingUrl={setLoadingUrl}
            handleShowModalFreeDownloadMailchimp={
              handleShowModalFreeDownloadMailchimp
            }
          />
        </Box>
      );
    },
    [
      handleFreeDownload,
      handleShowModalAddTrack,
      onOpenOptionsModal,
      setLoadingUrl,
      setShortLink,
      showModalAndGetdataShareLink,
      handleShowModalFreeDownloadMailchimp,
    ]
  );

  const renderTabPanels = useMemo(
    () => [
      {
        id: 0,
        component: <InfiniteHits hitComponent={HitMusicians} />,
        loading: <SkeletonMusicians />,
        emptyComponent: <EmptyTrackSoundkit />,
      },
      {
        id: 1,
        component: <InfiniteHits hitComponent={HitTracksOrSoundPacks} />,
        loading: <SkeletonTrackSoundPack />,
        emptyComponent: <EmptyTrackSoundkit />,
      },
      {
        id: 2,
        component: <InfiniteHits hitComponent={HitTracksOrSoundPacks} />,
        loading: <SkeletonTrackSoundPack />,
        emptyComponent: <EmptyTrackSoundkit />,
      },
      {
        id: 3,
        component: <InfiniteHits hitComponent={HitPlaylists} />,
        loading: <SkeletonPlaylistItemProfile />,
        emptyComponent: <EmptyPlaylist />,
      },
      {
        id: 4,
        component: <InfiniteHits hitComponent={HitTracksOrSoundPacks} />,
        loading: <SkeletonTrackSoundPack />,
        emptyComponent: <EmptyTrackSoundkit />,
      },
    ],
    [HitMusicians, HitPlaylists, HitTracksOrSoundPacks]
  );

  return (
    <Box className="results-components">
      <Tabs
        onChange={(value) => handleChangTabs(value)}
        index={tabActive}
        variant="unstyled"
        className="tabs-panels"
      >
        <TabList
          className="tab-list-panels"
          background="none"
          position="sticky"
          top={{ base: "120px", md: "95px" }}
          zIndex={90}
          backgroundColor="#18181c"
          padding={{ base: "0px", xl: "10px 30px" }}
        >
          {listMenuTabs.map((item) => (
            <Tab
              _selected={{
                color: "#FFFFFF",
              }}
              color="#49494D"
              className="tab"
              _focus={{ border: "none" }}
              key={item.id}
              _hover={{
                border: "1px solid #38383D",
                background: "#222227 !important",
                borderRadius: "6px",
              }}
              borderColor="#38383D"
              fontSize={{ base: "12px", md: "16px" }}
            >
              {item.name}
            </Tab>
          ))}
        </TabList>
        <Box position="sticky" top="0px">
          {checkHastag && (
            <Text
              m="20px 0px 0px 35px"
              fontSize="32px"
              lineHeight="40px"
              fontWeight="700"
            >
              {t("searchResultsFor")} {location.hash || "No search"}
            </Text>
          )}
          {!!typeParams.q && (
            <Text
              m={{ base: "10px 0px 0px 0px", md: "20px 0px 0px 35px" }}
              fontSize={{ base: "22px", md: "32px" }}
              lineHeight="40px"
              fontWeight="700"
            >
              {t("searchResultsFor")} {typeParams.q || "No search"}
            </Text>
          )}
          <Box ml={{ base: "0px", md: "35px" }}>
            <CurrentRefinements />
          </Box>

          <TabPanels>
            {renderTabPanels.map((item) => (
              <TabPanel
                key={item.id}
                padding={{ base: "0px", md: "0px 10px", xl: "16px" }}
                overflow="auto"
              >
                {isLoading
                  ? item.loading
                  : hits?.length > 0
                  ? item.component
                  : ["loading", "stalled"].includes(status)
                  ? item.loading
                  : item.emptyComponent}
              </TabPanel>
            ))}
          </TabPanels>
        </Box>
      </Tabs>

      {showOptionsModal && (
        <ModalPriceTracks
          selectedTrack={selectedTrack}
          showModal={showOptionsModal}
          setShowModal={setShowOptionsModal}
          handleFreeDownload={handleFreeDownload}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      )}
      {showLyrics && (
        <ModalLyrics
          showModal={showLyrics}
          setShowModal={setShowLyrics}
          selectedTrack={selectedTrack}
          onOpenOptionsModal={onOpenOptionsModal}
          isEditLyrics={isEditLyrics}
          setIsEditLyrics={setIsEditLyrics}
          lyricsData={lyricsData}
        />
      )}
      {showModalAddToPlaylist && (
        <ModalAddToPlaylist
          trackId={trackId}
          setShowModal={setShowModalAddToPlaylist}
          showModal={showModalAddToPlaylist}
          setShowModalCreatePlaylist={setShowModalCreatePlaylist}
          playlists={playlists}
          handleSearchText={handleSearchText}
          debouncedSearchText={debouncedSearchText}
        />
      )}
      {showModalCreatePlaylist && (
        <ModalCreatePlaylist
          showModal={showModalCreatePlaylist}
          setShowModal={setShowModalCreatePlaylist}
        />
      )}

      {showModalFreeDownload && (
        <ModalShowFreeDownload
          showModal={showModalFreeDownload}
          setShowModal={setShowModalFreeDownload}
          linkTitleFreeDownload={linkTitleDownload}
        />
      )}
      {showModalShareLink && (
        <ModalShareLinkRecentActions
          showModalShareLink={showModalShareLink}
          setShowModalShareLink={setShowModalShareLink}
          selectedTrackSoundkit={selectedTrack}
          shortLinkUrl={shortLink}
          loadingUrl={loadingUrl}
        />
      )}
      {showModalFreeMailchimp && (
        <ModalShowFreeDownloadFormMailChimp
          showModal={showModalFreeMailchimp}
          setShowModal={setShowModalFreeMailchimp}
          selectedTrack={selectedTrack}
          handleFreeDownload={handleFreeDownload}
        />
      )}
    </Box>
  );
}
export default React.memo(ResultsComponents);
