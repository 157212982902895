import { Box, Text, Flex, Button } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BackReturn } from "app/assets/icons";
import { useTranslation } from "react-i18next";
import TabsMenuHeader from "./TabHeader";
import "./styles.scss";
import SelectTrackFiles from "./selectTrackFiles";
import { UploadTrackFilesModel, SelectedFilesModel } from "app/models";
import { FILE_UPLOAD_TYPE } from "constants/fileUploadTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  createDraftTracks,
  convertAutoTagged,
  prepareUploadTrackFile,
  setFileAutoTagged,
  setFileCustomTagged,
  setFileTrackoutStems,
  setFileAutoTaggedClosePage,
  setFileCustomTaggedClosePage,
  setFileTrackoutStemsClosePage,
} from "app/redux/actions/tracks";
import { planType } from "constants/planType";
import ModalUploadTrackFilesReady from "./modalUploadTrackFilesReady";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import { checkHadData } from "utils/errorMessage";
import ToastPlanSubscription from "../toast/toastplan";
import { BeatLoader } from "react-spinners";
import { useMyTrack } from "hooks/track/useMyTrack";
import ToastResumeUploading from "../toast/toastResumeUploading";
import { selectUserAdmin } from "app/redux/selectors/users";

export default function UploadTrackFiles() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(selectUserAdmin);

  const {
    filesAutoTagged,
    filesCustomTagged,
    filesTrackoutStems,
    autoTaggedInProgress,
    customTaggedInProgress,
    trackoutStemsInProgress,
  } = useMyTrack();
  const [showModalUploadTrackFiles, setShowModalUploadTrackFiles] =
    useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const [checkFirstShowToast, setCheckFirstShowToast] = useState(true);
  const [checkIsUpload, setCheckIsUpload] = useState(true);
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([convertAutoTagged.typePrefix], state)
  );
  const handleBackMyTrack = useCallback(() => {
    history.push({
      pathname: "/dashboard/my-track",
    });
  }, [history]);
  const handleBackMyTrackDraft = useCallback(() => {
    history.push({
      pathname: "/dashboard/my-track/my-draft-track",
    });
  }, [history]);
  const [autoTaggedFiles, setAutoTaggedFiles] = useState<
    UploadTrackFilesModel[]
  >(filesAutoTagged?.length > 0 ? filesAutoTagged : autoTaggedInProgress);
  const [customTaggedFiles, setCustomTaggedFiles] = useState<
    UploadTrackFilesModel[]
  >(filesCustomTagged?.length > 0 ? filesCustomTagged : customTaggedInProgress);
  const [trackoutStemsFiles, setTrackoutStemsFiles] = useState<
    UploadTrackFilesModel[]
  >(
    filesTrackoutStems?.length > 0
      ? filesTrackoutStems
      : trackoutStemsInProgress
  );
  const [selectedAutoTagged, setSelectedAutoTagged] =
    useState<UploadTrackFilesModel>({});
  const [selectedCustomTagged, setSelectedCustomTagged] =
    useState<UploadTrackFilesModel>({});
  const [selectedTrackoutStems, setSelectedTrackoutStems] =
    useState<UploadTrackFilesModel>({});

  const [selectedFile, setSelectedFile] = useState<SelectedFilesModel>({
    name: t("autoTagged"),
    type: FILE_UPLOAD_TYPE.AUDIO,
    fileType: "audio/wav",
  });

  const checkPlanSubscription = useMemo(
    () => currentUser?.subscriptionType === planType.MAINSTREAM,
    [currentUser?.subscriptionType]
  );

  useEffect(() => {
    !checkPlanSubscription && history.push({ pathname: "/add-track" });
  }, [checkPlanSubscription, history]);
  const checkReviewProceed = useMemo(() => {
    return (
      (autoTaggedFiles || []).every((item) => item.urlFile) &&
      (customTaggedFiles || []).every((item) => item.urlFile) &&
      (trackoutStemsFiles || []).every((item) => item.urlFile)
    );
  }, [autoTaggedFiles, customTaggedFiles, trackoutStemsFiles]);
  const tabsMenuUploadTrackFiles = useMemo(
    () => [
      {
        name: t("autoTagged"),
        id: 0,
        isCheck: true,
      },
      {
        name: t("customTagged"),
        id: 1,
        isCheck: [
          t("customTagged"),
          t("trackoutStems"),
          t("reviewFiles"),
        ].includes(selectedFile?.name),
      },
      {
        name: t("trackoutStems"),
        id: 2,
        isCheck: [t("trackoutStems"), t("reviewFiles")].includes(
          selectedFile?.name
        ),
      },
      {
        name: t("reviewFiles"),
        id: 3,
        isCheck: selectedFile?.name === t("reviewFiles"),
      },
    ],
    [selectedFile?.name, t]
  );

  useEffect(() => {
    if (filesAutoTagged?.length > 0 && autoTaggedInProgress?.length <= 0) {
      setSelectedFile({
        name: t("reviewFiles"),
      });
    }
  }, [autoTaggedInProgress?.length, filesAutoTagged?.length, t]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (autoTaggedFiles?.length > 0 && checkReviewProceed) {
        dispatch(setFileAutoTaggedClosePage(autoTaggedFiles));
        dispatch(setFileCustomTaggedClosePage(customTaggedFiles));
        dispatch(setFileTrackoutStemsClosePage(trackoutStemsFiles));
      } else {
        dispatch(setFileAutoTaggedClosePage([]));
        dispatch(setFileCustomTaggedClosePage([]));
        dispatch(setFileTrackoutStemsClosePage([]));
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    autoTaggedFiles,
    checkReviewProceed,
    customTaggedFiles,
    dispatch,
    trackoutStemsFiles,
  ]);

  const handleNextStep = useCallback(() => {
    if (selectedFile.name === t("autoTagged")) {
      setSelectedFile({
        name: t("customTagged"),
        type: FILE_UPLOAD_TYPE.AUDIO,
        fileType: "audio/mp3",
      });
      if (checkFirstShowToast) {
        setShowToast(true);
      }
    } else if (selectedFile.name === t("customTagged")) {
      setSelectedFile({
        name: t("trackoutStems"),
        type: FILE_UPLOAD_TYPE.ZIP,
      });
      setCheckFirstShowToast(false);
    } else {
      setSelectedFile({
        name: t("reviewFiles"),
      });
    }
  }, [checkFirstShowToast, selectedFile.name, t]);

  useEffect(() => {
    if (selectedFile.name === t("customTagged") && showToast) {
      ToastResumeUploading({
        title: t("wavFilesAutoConverted"),
        description: t("weAutomaticallyConvertYourWavFilesToMp3"),
      });
      ToastResumeUploading({
        title: t("mp3StreamingTracksTagAutoGenerated"),
        description: t("autoGeneratedMp3TagsSimplifyYourTrack"),
      });
    }
  }, [selectedFile.name, showToast, t]);

  useEffect(() => {
    const unlisten = history.block((location) => {
      const checkPageUpload = location.pathname === "/upload-track-files";
      const confirmationMessage =
        "Please wait until the upload completed. If you exit, the process will not be saved";
      if (
        !checkPageUpload &&
        autoTaggedFiles?.length > 0 &&
        checkReviewProceed &&
        checkIsUpload
      ) {
        dispatch(
          createDraftTracks({
            autoTaggedFiles,
            customTaggedFiles,
            trackoutStemsFiles,
          })
        );
        dispatch(setFileAutoTagged([]));
        dispatch(setFileCustomTagged([]));
        dispatch(setFileTrackoutStems([]));
        dispatch(setFileAutoTaggedClosePage([]));
        dispatch(setFileCustomTaggedClosePage([]));
        dispatch(setFileTrackoutStemsClosePage([]));
        ToastPlanSubscription({
          title: t("resumeEditingYourTrackAwait"),
          description: t("desResumeEditingYourTrackAwait"),
          buttonText: t("reviewYourUploads"),
          onClickButton: handleBackMyTrackDraft,
        });
      } else {
        if (!checkIsUpload) {
          return;
        }
        if (!checkPageUpload && autoTaggedFiles?.length <= 0) {
          return;
        } else if (window.confirm(confirmationMessage)) {
          unlisten();
        } else {
          return false;
        }
      }
    });
    return () => {
      unlisten();
    };
  }, [
    autoTaggedFiles,
    checkIsUpload,
    checkReviewProceed,
    customTaggedFiles,
    dispatch,
    handleBackMyTrackDraft,
    history,
    t,
    trackoutStemsFiles,
  ]);

  const handlePreviousStep = useCallback(() => {
    if (selectedFile.name === t("trackoutStems")) {
      setSelectedFile({
        name: t("customTagged"),
        type: FILE_UPLOAD_TYPE.AUDIO,
        fileType: "audio/mp3",
      });
    } else {
      setSelectedFile({
        name: t("autoTagged"),
        type: FILE_UPLOAD_TYPE.AUDIO,
        fileType: "audio/wav",
      });
      setShowToast(false);
      setCheckFirstShowToast(false);
    }
  }, [selectedFile.name, t]);

  const handleProceedAddTrack = useCallback(() => {
    const filterAutoTagged = (autoTaggedFiles || []).filter(
      (item) => item?.id !== selectedAutoTagged?.id
    );
    const filterCustomTagged = (customTaggedFiles || []).filter(
      (item) => item?.id !== selectedCustomTagged?.id
    );
    const filterTrackoutStems = (trackoutStemsFiles || []).filter(
      (item) => item?.id !== selectedTrackoutStems?.id
    );
    const listAutoTaggedSelect = (autoTaggedFiles || []).find(
      (item) => item?.id === selectedAutoTagged?.id
    );
    const listCustomTaggedSelect = customTaggedFiles.find(
      (item) => item.id === selectedCustomTagged.id
    );
    setCheckIsUpload(false);
    dispatch(setFileAutoTagged(filterAutoTagged));
    dispatch(setFileCustomTagged(filterCustomTagged));
    dispatch(setFileTrackoutStems(filterTrackoutStems));
    dispatch(setFileAutoTaggedClosePage([]));
    dispatch(setFileCustomTaggedClosePage([]));
    dispatch(setFileTrackoutStemsClosePage([]));
    const linkAutoTagged = selectedAutoTagged?.urlFile;
    const splitLink = (linkAutoTagged || "")?.split("?")[0].split("/");
    const audioString = splitLink[splitLink.length - 1];
    const replaceToMp3 = audioString.replace(/(.wav)$/i, ".mp3");
    dispatch(
      prepareUploadTrackFile({
        title: selectedAutoTagged?.name,
        untaggedFileUrl: `${process.env.REACT_APP_S3_BASE_URL}/uploads/tracks/${replaceToMp3}`,
        untaggedFileUrlWav: selectedAutoTagged?.urlFile,
        taggedFileUrl: selectedCustomTagged?.urlFile,
        trackStemsUrl: selectedTrackoutStems?.urlFile,
        timeTagDuration: listCustomTaggedSelect?.timeTagDuration,
        timeUntagDuration: listAutoTaggedSelect?.timeUntagDuration,
        playUntaggedFile: selectedAutoTagged?.urlFile,
      })
    );
    setShowModalUploadTrackFiles(true);
  }, [
    autoTaggedFiles,
    customTaggedFiles,
    trackoutStemsFiles,
    dispatch,
    selectedAutoTagged?.urlFile,
    selectedAutoTagged?.id,
    selectedAutoTagged?.name,
    selectedCustomTagged?.id,
    selectedCustomTagged?.urlFile,
    selectedTrackoutStems?.id,
    selectedTrackoutStems?.urlFile,
  ]);

  return (
    <Box
      className="add-track"
      p={{ base: "60px 16px", md: "60px 30px", xl: "60px 80px 100px 80px" }}
      position="relative"
    >
      <Flex alignItems="center">
        <Box onClick={handleBackMyTrack} cursor="pointer">
          <BackReturn />
        </Box>
        <Text
          className="fontCeraPro"
          fontSize={{ base: "24px", md: "32px" }}
          ml="15px"
          fontFamily="Cera Pro Regular"
          fontWeight="700"
        >
          {t("bulkTrackUpload")}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent={{ base: "center", md: "space-between" }}
        flexWrap="wrap"
      >
        <Box
          mt="32px"
          className="tabs-panels"
          display={{ base: "none", md: "block" }}
          justifyContent="center"
        >
          <TabsMenuHeader tabsMenu={tabsMenuUploadTrackFiles} />
        </Box>
        <Flex
          mt="32px"
          gap="10px"
          w={{ base: "100%", lg: "max-content" }}
          justifyContent="center"
        >
          {selectedFile.name !== t("reviewFiles") && (
            <Button
              height="56px"
              background="#212125"
              color="white"
              disabled={selectedFile.name === t("autoTagged")}
              onClick={handlePreviousStep}
              _hover={{ background: "#212125" }}
              w={{ base: "40%", md: "max-content" }}
            >
              {t("previousStep")}
            </Button>
          )}
          {selectedFile.name === t("reviewFiles") ? (
            <Button
              height="56px"
              background="#34391A"
              color="#D4F70E"
              onClick={handleProceedAddTrack}
              _hover={{ background: "#34391A" }}
              _active={{
                border: "1px solid #D4F70E",
              }}
              isLoading={isLoading}
              isDisabled={
                !checkReviewProceed ||
                autoTaggedFiles?.length === 0 ||
                checkHadData(selectedAutoTagged)
              }
              spinner={<BeatLoader size={8} color="white" />}
            >
              {t("proceedAddTrack")}
            </Button>
          ) : (
            <Button
              height="56px"
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              onClick={handleNextStep}
              disabled={autoTaggedFiles?.length === 0}
              w={{ base: "40%", md: "max-content" }}
            >
              {t("nextStep")}
            </Button>
          )}
        </Flex>
      </Flex>

      <SelectTrackFiles
        autoTaggedFiles={autoTaggedFiles}
        customTaggedFiles={customTaggedFiles}
        trackoutStemsFiles={trackoutStemsFiles}
        setAutoTaggedFiles={setAutoTaggedFiles}
        setCustomTaggedFiles={setCustomTaggedFiles}
        setTrackoutStemsFiles={setTrackoutStemsFiles}
        selectedFile={selectedFile}
        selectedAutoTagged={selectedAutoTagged}
        setSelectedAutoTagged={setSelectedAutoTagged}
        selectedCustomTagged={selectedCustomTagged}
        setSelectedCustomTagged={setSelectedCustomTagged}
        selectedTrackoutStems={selectedTrackoutStems}
        setSelectedTrackoutStems={setSelectedTrackoutStems}
      />
      {showModalUploadTrackFiles && (
        <ModalUploadTrackFilesReady
          showModal={showModalUploadTrackFiles}
          setShowModal={setShowModalUploadTrackFiles}
        />
      )}
    </Box>
  );
}
