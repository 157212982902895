import {
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Spinner,
} from "@chakra-ui/react";
import ArrowDown from "app/assets/icons/arrowDown";
import ArrowUp from "app/assets/icons/arrowUp";
import IconAddToCart from "app/assets/icons/iconAddToCart";
import IconAudioSteams from "app/assets/icons/iconAudioSteams";
import IconBookRenawal from "app/assets/icons/iconBooksRenawal";
import IconFileMusic from "app/assets/icons/iconFileMusic";
import IconFreeDownloads from "app/assets/icons/iconFreeDownloads";
import IconEntertainment from "app/assets/icons/iconMusicEntertainment";
import IconMusicRecording from "app/assets/icons/iconMusicRecording";
import IconMusicVideo from "app/assets/icons/iconMusicVideo";
import IconNegotiationCart from "app/assets/icons/IconNegotiationCart";
import IconRadio from "app/assets/icons/iconRadio";
import IconVideoStream from "app/assets/icons/iconVideoStreams";
import { Licenses, TrackSoundKit, HandleFreeDownloadModel } from "app/models";
import { addToCart, selectedLisence } from "app/redux/actions/cart";
import { selectTrackNegotiation } from "app/redux/actions/negotiations";
import { useToggle } from "hooks/useToggle";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaypalNew from "app/assets/images/PaypalNew.svg";
import StripeNew from "app/assets/images/StripeNew.svg";
import FreeDownload from "app/assets/icons/freeDownload";
import { checkFreeDownload, checkTypeDownload } from "utils/convertTracks";
import { checkFollowUser } from "app/redux/actions/tracks";
import { selectUserAdmin, selectLoggedIn } from "app/redux/selectors/users";
import ToastPlanSubscription from "../toast/toastplan";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import BistarLicense from "app/assets/icons/BiStarLicense";
import "./styles.scss";

interface ModalPriceTrack {
  showModal: boolean;
  setShowModal: () => void;
  selectedTrack: TrackSoundKit | null;
  handleFreeDownload: ({
    url,
    title,
    _id,
    isTrack,
  }: HandleFreeDownloadModel) => void;
  handleShowModalFreeDownloadMailchimp: (track: TrackSoundKit) => void;
}

export default function ModalPriceTracks({
  showModal,
  setShowModal,
  selectedTrack,
  handleFreeDownload,
  handleShowModalFreeDownloadMailchimp,
}: ModalPriceTrack) {
  const { t } = useTranslation();
  const [isShowUsageTerm, setIsShowUsageTerm] = useToggle();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(selectUserAdmin);
  const loggedIn = useSelector(selectLoggedIn);

  const onSelectLicense = (trackId: string, license: Licenses) => {
    dispatch(addToCart(selectedTrack));
    dispatch(selectedLisence({ trackId, license }));
    setShowModal();
  };
  const isLoadingCheckFreeDownload = useSelector((state: RootState) =>
    isLoadingSelector([checkFollowUser.typePrefix], state)
  );
  const checkButtonFreeDownload = checkFreeDownload(selectedTrack);

  const handleNegotiation = useCallback(() => {
    history.push("/negotiations");
    setShowModal();
    dispatch(selectTrackNegotiation(selectedTrack));
  }, [dispatch, history, selectedTrack, setShowModal]);

  const [selectLicense, setSelectLicense] = useState<Licenses>();

  useEffect(() => {
    const premiumLicense = selectedTrack?.licenses?.some(
      (item) => item.name === "Premium"
    );
    const popularLicense = selectedTrack?.licenses?.some(
      (item) => item?.highlight
    );
    if (popularLicense) {
      setSelectLicense(
        selectedTrack?.licenses?.find((item) => item?.highlight)
      );
    } else if (premiumLicense) {
      setSelectLicense(
        selectedTrack?.licenses?.find((item) => item.name === "Premium")
      );
    } else {
      setSelectLicense(selectedTrack?.licenses?.[0]);
    }
  }, [selectedTrack?.licenses]);

  const handleSelectLicense = useCallback((license) => {
    setSelectLicense(license);
  }, []);

  const isExclusive = useMemo(
    () => (selectedTrack?.licenses || []).every((license) => license.exclusive),
    [selectedTrack]
  );

  const isMakeOffersOnly = useMemo(
    () =>
      (selectedTrack?.licenses || []).every(
        (license) => license?.makeOffersOnly
      ),
    [selectedTrack?.licenses]
  );

  const handleDownloadTrack = useCallback(async () => {
    if (selectedTrack?.requireEmailCapture) {
      handleShowModalFreeDownloadMailchimp(selectedTrack);
      return;
    }
    const isFollowing = await dispatch(
      checkFollowUser(selectedTrack?.createdBy?._id || "")
    );
    if (
      isFollowing?.payload ||
      currentUser?._id === selectedTrack?.createdBy?._id
    ) {
      handleFreeDownload({
        url: checkTypeDownload(selectedTrack)
          ? (selectedTrack?.mergeAudio as string)
          : (selectedTrack?.untaggedFileUrl as string),
        title: selectedTrack?.title || "",
        _id: selectedTrack?._id || "",
        isTrack: true,
      });
    } else {
      ToastPlanSubscription({
        title: t("followForAFreeDownload"),
        description: t("desFollowGetDownload"),
      });
    }
  }, [
    currentUser?._id,
    dispatch,
    handleFreeDownload,
    handleShowModalFreeDownloadMailchimp,
    selectedTrack,
    t,
  ]);

  return (
    <Modal
      returnFocusOnClose={false}
      isOpen={showModal}
      onClose={setShowModal}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "350px", sm: "450px", md: "650px", xl: "890px" }}
        position="relative"
        margin={{ base: "10px", md: "0px" }}
        maxH={{ base: "700px", md: "850px" }}
      >
        <ModalHeader
          padding={{ base: "20px", xl: "20px 40px" }}
          fontSize={{ base: "24px", xl: "32px" }}
          fontFamily="Cera Pro Regular"
        >
          {t("chooseLicense")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={5}
          top={{ base: "10px", xl: "25px" }}
          fontSize={{ base: "24px", xl: "30px" }}
          position="absolute"
          color="#d4f70e"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "20px",
          }}
          padding="20px"
        />
        <ModalBody
          padding={{ base: "0px 20px", xl: "0px 40px" }}
          overflow="scroll"
        >
          <SimpleGrid
            columns={3}
            spacing={{ base: "5px", md: "10px", xl: "16px" }}
            mt={{ base: "0px", md: "0px" }}
            display={{ base: "block", md: "grid" }}
          >
            {selectedTrack?.licenses?.map((license, index) => {
              return (
                <Box
                  key={index}
                  backgroundColor={
                    license?._id === selectLicense?._id
                      ? "rgba(212, 247, 14, 0.1)"
                      : "#24252A"
                  }
                  borderRadius="8px"
                  p={{ base: "10px 5px", xl: "10px 15px" }}
                  onClick={() => handleSelectLicense(license)}
                  border={
                    license?._id === selectLicense?._id
                      ? "0.5px solid #D4F70E"
                      : "none"
                  }
                  cursor="pointer"
                  mt={{ base: "10px", md: "0px" }}
                >
                  <Flex justifyContent="space-between" mb="7px">
                    <Text
                      fontSize={{ base: "14px", xl: "18px" }}
                      w={license?.highlight ? "70%" : "100%"}
                      color={
                        license?._id === selectLicense?._id
                          ? "#D4F70E"
                          : "white"
                      }
                      fontWeight={
                        license?._id === selectLicense?._id ? "600" : "500"
                      }
                    >
                      {license?.name?.includes("License") ||
                      license?.name?.includes("license")
                        ? license?.name
                        : `${license?.name} License`}
                    </Text>

                    {license?.highlight && (
                      <Box className="box-popular-license" height="max-content">
                        <Flex
                          padding="0px 3px"
                          borderRadius="4px"
                          alignItems="center"
                          background="linear-gradient(to right, #36204d, #38244b, #402e49, #483b43, #524a3c)"
                        >
                          <BistarLicense />
                          <Text
                            fontSize="12px"
                            color="#D4F70E"
                            fontWeight="700"
                            ml="2px"
                          >
                            {t("popular")}
                          </Text>
                        </Flex>
                      </Box>
                    )}
                  </Flex>
                  <Text
                    fontSize={{ base: "14px", xl: "16px" }}
                    lineHeight="19px"
                    mb="7px"
                  >
                    $
                    {license?.exclusive
                      ? Number(license?.exclusiveLicensePrice)?.toFixed(2)
                      : Number(
                          license?.trackPrice
                            ? license?.trackPrice
                            : license?.defaultTrackPrice
                        )?.toFixed(2)}
                  </Text>
                  <Flex>
                    <Text
                      fontSize={{ base: "10px", xl: "12px" }}
                      lineHeight="14px"
                      color="#535353"
                      mb="7px"
                    >
                      MP3
                    </Text>
                    {license?.extraWav && (
                      <Text
                        fontSize={{ base: "10px", xl: "12px" }}
                        lineHeight="14px"
                        color="#535353"
                        mb="7px"
                      >
                        ,WAV
                      </Text>
                    )}
                    {license?.trackStems && (
                      <Text
                        fontSize={{ base: "10px", xl: "12px" }}
                        lineHeight="14px"
                        color="#535353"
                        mb="7px"
                      >
                        ,TRACK STEMS
                      </Text>
                    )}
                  </Flex>
                </Box>
              );
            })}
          </SimpleGrid>

          {(selectedTrack?.createdBy?.sellerConnectedPayPal ||
            selectedTrack?.createdBy?.sellerConnectedStripe) && (
            <Flex
              m={{ base: "20px 0px", md: "30px 0px 25px 0px" }}
              alignItems="center"
              display={{ base: "block", md: "flex" }}
            >
              <Text
                fontSize="18px"
                lineHeight="24px"
                mr="10px"
                mb={{ base: "10px", md: "0px" }}
              >
                {t("acceptPayment")}
              </Text>
              <Flex gap="10px">
                {selectedTrack?.createdBy?.sellerConnectedStripe && (
                  <Image fallbackSrc={StripeNew} />
                )}
                {selectedTrack?.createdBy?.sellerConnectedPayPal && (
                  <Image fallbackSrc={PaypalNew} />
                )}
              </Flex>
            </Flex>
          )}
          {!selectedTrack?.createdBy?.sellerConnectedPayPal &&
            !selectedTrack?.createdBy?.sellerConnectedStripe && (
              <Text
                color="red"
                fontSize={{ base: "12px", md: "14px" }}
                mt="5px"
              >
                {t("errorAddToCart")}
              </Text>
            )}
          <Divider mt="15px" borderColor="rgba(250, 250, 250, 0.04)" />
          <Box mt="15px">
            <Text fontSize="16px" fontWeight="700">
              {t("fileDescription")}:
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  marginLeft: "2px",
                }}
              >
                {isExclusive
                  ? t("descriptionExclusive")
                  : t("descriptionNonExclusive")}
              </span>
            </Text>
          </Box>

          <Divider mt="15px" borderColor="rgba(250, 250, 250, 0.04)" />
          {selectLicense && (
            <Box m="20px 0px 30px 0px">
              <Flex onClick={setIsShowUsageTerm} alignItems="center">
                <Text
                  mr="10px"
                  color={isShowUsageTerm ? "#D4F70E" : "white"}
                  fontSize={{ md: "20px", xl: "24px" }}
                  lineHeight="24px"
                  mb="5px"
                  cursor="pointer"
                >
                  {t("usageTerms")}
                </Text>
                {isShowUsageTerm ? <ArrowUp /> : <ArrowDown />}
              </Flex>
              {isShowUsageTerm && (
                <SimpleGrid
                  columns={{ base: 1, md: 3 }}
                  spacing={{ md: "10px", xl: "20px" }}
                  spacingY={{ base: "10px", md: "0px" }}
                  backgroundColor="#1C1E1C"
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25) inset"
                  padding={{ md: "15px", xl: "20px 25px" }}
                  borderRadius="4px"
                >
                  {!selectLicense?.exclusive ? (
                    <>
                      <Flex alignItems="center">
                        <IconMusicRecording />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                          fontFamily="Cera Pro Regular"
                        >
                          {t("useForMusicRecording")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconVideoStream />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                          fontFamily="Cera Pro Regular"
                        >
                          {selectLicense?.nonMonetizedVideo >= 0
                            ? t("dontUse")
                            : t("use")}
                          {t("nonVideoStreams")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconFileMusic />
                        <Box>
                          <Text
                            ml="10px"
                            fontSize="12px"
                            lineHeight={{ md: "16px", xl: "20px" }}
                            fontFamily="Cera Pro Regular"
                          >
                            {t("filesToBeDelivered")}
                          </Text>
                          <Flex ml="10px" mt="5px">
                            <Text
                              fontSize="12px"
                              lineHeight="15px"
                              color="#D4F70E"
                              border="0.5px solid rgba(212, 247, 14, 0.1)"
                              padding="2px 8px"
                              borderRadius="4px"
                              fontFamily="Cera Pro Regular"
                            >
                              {t("mp3")}
                            </Text>
                            {selectLicense?.extraWav && (
                              <Text
                                fontSize="12px"
                                lineHeight="15px"
                                color="#D4F70E"
                                border="0.5px solid rgba(212, 247, 14, 0.1)"
                                padding="2px 8px"
                                borderRadius="4px"
                                ml="5px"
                                fontFamily="Cera Pro Regular"
                              >
                                {t("wav")}
                              </Text>
                            )}
                            {selectLicense?.trackStems && (
                              <Text
                                fontSize="12px"
                                lineHeight="15px"
                                color="#D4F70E"
                                border="0.5px solid rgba(212, 247, 14, 0.1)"
                                padding="2px 8px"
                                borderRadius="4px"
                                ml="5px"
                                fontFamily="Cera Pro Regular"
                              >
                                {t("stems")}
                              </Text>
                            )}
                          </Flex>
                        </Box>
                      </Flex>
                      <Flex alignItems="center">
                        <IconFreeDownloads />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                          fontFamily="Cera Pro Regular"
                        >
                          {selectLicense?.freeDownloads >= 0
                            ? t("upToFreeDownloads")
                            : t("unlimitedFreeDownloads")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconEntertainment />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                          fontFamily="Cera Pro Regular"
                        >
                          {selectLicense?.profitPerformance
                            ? t("allowed")
                            : t("notAllow")}
                          {t("profitPerformance")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconAudioSteams />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                          fontFamily="Cera Pro Regular"
                        >
                          {selectLicense?.audioStreams >= 0
                            ? t("UpTo")
                            : t("unlimited")}
                          {t("audioStreams")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconMusicVideo />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                          fontFamily="Cera Pro Regular"
                        >
                          {selectLicense?.musicVideo >= 0
                            ? t("UpTo")
                            : t("unlimited")}
                          {t("musicStreams")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconRadio />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                          fontFamily="Cera Pro Regular"
                        >
                          {selectLicense?.allowBroadCasting
                            ? t("allowedBroadcastingRights")
                            : t("notAllowBroadcastingRights")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconBookRenawal />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                          fontFamily="Cera Pro Regular"
                        >
                          {selectLicense?.licenseRenewal === 1
                            ? `1 ${t("year")}(s)`
                            : `2 ${t("year")}(s)`}
                          {t("licenseRenewalTerm")}
                        </Text>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Flex alignItems="center">
                        <IconMusicRecording />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                        >
                          {t("useForMusicRecording")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconVideoStream />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                        >
                          {t("unlimited")} {t("monetizedVideoStreams")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconFileMusic />
                        <Box>
                          <Text
                            ml="10px"
                            fontSize="12px"
                            lineHeight={{ md: "16px", xl: "20px" }}
                          >
                            {t("filesToBeDelivered")}
                          </Text>
                          <Flex ml="10px" mt="5px">
                            <Text
                              fontSize="12px"
                              lineHeight="15px"
                              color="#D4F70E"
                              border="0.5px solid rgba(212, 247, 14, 0.1)"
                              padding="2px 8px"
                              borderRadius="4px"
                            >
                              {t("mp3")}
                            </Text>
                            {selectLicense?.extraWav && (
                              <Text
                                fontSize="12px"
                                lineHeight="15px"
                                color="#D4F70E"
                                border="0.5px solid rgba(212, 247, 14, 0.1)"
                                padding="2px 8px"
                                borderRadius="4px"
                                ml="5px"
                              >
                                {t("wav")}
                              </Text>
                            )}
                            {selectLicense?.trackStems && (
                              <Text
                                fontSize="12px"
                                lineHeight="15px"
                                color="#D4F70E"
                                border="0.5px solid rgba(212, 247, 14, 0.1)"
                                padding="2px 8px"
                                borderRadius="4px"
                                ml="5px"
                              >
                                {t("stems")}
                              </Text>
                            )}
                          </Flex>
                        </Box>
                      </Flex>
                      <Flex alignItems="center">
                        <IconFreeDownloads />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                        >
                          {t("unlimitedFreeDownload")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconEntertainment />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                        >
                          {t("unlimited")} {t("profitPerformance")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconAudioSteams />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight={{ md: "16px", xl: "20px" }}
                        >
                          {t("unlimited")} {t("audioStreams")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <IconMusicVideo />
                        <Text ml="10px" fontSize="12px" lineHeight="24px">
                          {t("unlimited")} {t("musicStreams")}
                        </Text>
                      </Flex>
                    </>
                  )}
                </SimpleGrid>
              )}
              <Divider
                m={{ base: "10px 0px 10px 0px", md: "15px 0px 15px 0px" }}
                borderColor="rgba(250, 250, 250, 0.04)"
              />

              <Flex
                m={{ base: "0px", xl: "40px 0px 40px 0px" }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontSize={{ base: "18px", xl: "24px" }} lineHeight="24px">
                  $
                  {selectLicense?.exclusive
                    ? Number(selectLicense?.exclusiveLicensePrice)?.toFixed(2)
                    : selectLicense?.trackPrice
                    ? Number(selectLicense?.trackPrice)?.toFixed(2)
                    : Number(selectLicense?.defaultTrackPrice)?.toFixed(2)}
                </Text>
                <Flex
                  gridGap="10px"
                  overflow="hidden"
                  flexWrap="wrap"
                  justifyContent="flex-end"
                >
                  {checkButtonFreeDownload &&
                    (isLoadingCheckFreeDownload ? (
                      <Spinner color="#d4f70e" />
                    ) : (
                      <Button
                        leftIcon={<FreeDownload />}
                        backgroundColor="rgba(212, 247, 14, 0.1)"
                        borderRadius="4px"
                        color="#D4F70E "
                        border="1px solid rgba(212, 247, 14, 0.1)"
                        _hover={{ background: "rgba(212, 247, 14, 0.1)" }}
                        _active={{ background: "#D4F70E" }}
                        onClick={loggedIn ? handleDownloadTrack : () => null}
                      >
                        {t("freeDownloadButton")}
                      </Button>
                    ))}

                  {isExclusive && isMakeOffersOnly && (
                    <Button
                      leftIcon={<IconAddToCart />}
                      isDisabled={
                        !selectedTrack?.createdBy?.sellerConnectedPayPal &&
                        !selectedTrack?.createdBy?.sellerConnectedStripe
                      }
                      backgroundColor="rgba(212, 247, 14, 0.1)"
                      border="1px solid rgba(212, 247, 14, 0.1)"
                      borderRadius="4px"
                      color="#D4F70E"
                      _hover={{ background: "rgba(212, 247, 14, 0.1)" }}
                      _active={{ background: "#D4F70E" }}
                      onClick={() =>
                        (selectedTrack?.createdBy.sellerConnectedPayPal ||
                          selectedTrack?.createdBy.sellerConnectedStripe) &&
                        isExclusive &&
                        onSelectLicense(selectedTrack?._id, selectLicense)
                      }
                    >
                      {t("addToCart")}
                    </Button>
                  )}

                  <Button
                    leftIcon={
                      isExclusive && isMakeOffersOnly ? (
                        <IconNegotiationCart />
                      ) : (
                        <IconAddToCart />
                      )
                    }
                    isDisabled={
                      !selectedTrack?.createdBy?.sellerConnectedPayPal &&
                      !selectedTrack?.createdBy?.sellerConnectedStripe
                    }
                    backgroundColor="rgba(212, 247, 14, 0.1)"
                    borderRadius="4px"
                    color="#D4F70E"
                    border="1px solid rgba(212, 247, 14, 0.1)"
                    _hover={{ background: "rgba(212, 247, 14, 0.1)" }}
                    _active={{ background: "#D4F70E" }}
                    onClick={() =>
                      (selectedTrack?.createdBy.sellerConnectedPayPal ||
                        selectedTrack?.createdBy.sellerConnectedStripe) &&
                      isExclusive &&
                      isMakeOffersOnly
                        ? handleNegotiation()
                        : onSelectLicense(selectedTrack?._id, selectLicense)
                    }
                  >
                    {isExclusive && isMakeOffersOnly
                      ? t("startNegotiation")
                      : t("addToCart")}
                  </Button>
                </Flex>
              </Flex>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
