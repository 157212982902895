import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { TrackSoundKit } from "app/models";
import { getListAnalyticsTotalTrack } from "app/redux/actions/Analytics";
import { selectorsListTracks } from "app/redux/selectors/analytics";
import { AnalyticType, timeType } from "constants/analyticsType";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmptyAnalytic from "../EmptyAnalytics";
import IconEmptyAnalytics from "app/assets/icons/EmptyAnalytics";
import AvatarImageTrack from "app/assets/images/AvatarMusicLoading.svg";

import "./styles.scss";
import { isLoadingSelector } from "app/redux/selectors/status";
import { RootState } from "types";
import { FadeLoader } from "react-spinners";
import OptionsAnalytics from "./optionsAnalytics";
import { Link } from "react-router-dom";
import LinkToTrackOrProfile from "app/pages/IndividualTrackPage/linkToTrackProfile";
import { checkTypeTrackOrSoundPack } from "utils/convertTracks";
import { planType } from "constants/planType";
import UpgradeYourPlan from "../NotFoundPage/upgradeYourPlan";
import { selectUserAdmin } from "app/redux/selectors/users";

export default function TopTrackItem() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectOptionMost, setSelectOptionMost] = useState(AnalyticType.PLAYED);
  const [selectOptionPeriod, setSelectOptionPeriod] = useState(timeType.DAY);
  const currentUser = useSelector(selectUserAdmin);

  const checkPlanSubscription = useMemo(
    () => currentUser?.subscriptionType === planType.MAINSTREAM,
    [currentUser?.subscriptionType]
  );

  const analyticsListTracks = useSelector(selectorsListTracks);

  useEffect(() => {
    if (checkPlanSubscription) {
      dispatch(
        getListAnalyticsTotalTrack({
          timeType: selectOptionPeriod,
          analyticType: selectOptionMost,
        })
      );
    }
  }, [checkPlanSubscription, dispatch, selectOptionMost, selectOptionPeriod]);

  const totalToptracks = useCallback(
    (item) => {
      const total = {
        [AnalyticType.PLAYED]: item?.totalPlayTrackAnalytics || 0,
        [AnalyticType.PURCHASED]: item?.totalPurchasedTrack || 0,
        [AnalyticType.SOLD]: item?.totalSoldTrack || 0,
        [AnalyticType.DOWNLOADED]: item?.totalDownloadedTrack || 0,
        [AnalyticType.FAVORITED]: item?.totalFavoritedTrack || 0,
      };
      return total[selectOptionMost];
    },
    [selectOptionMost]
  );

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([getListAnalyticsTotalTrack.typePrefix], state)
  );

  const renderTopTrackItem = useCallback(() => {
    return (
      <Box maxHeight="450px" overflow="scroll">
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <FadeLoader color={"#d4f70e"} loading={isLoading} />
          </Box>
        ) : (analyticsListTracks || []).length <= 0 ? (
          <EmptyAnalytic
            title={t("youHaveNoAnalytics")}
            icon={<IconEmptyAnalytics />}
          />
        ) : (
          (analyticsListTracks || []).map(
            (item: TrackSoundKit, index: number) => {
              const linkDetail = checkTypeTrackOrSoundPack(item)
                ? `/track/${item?.slug}`
                : `/soundkit/${item?.slug}`;
              return (
                <Flex
                  key={item?._id}
                  alignItems="center"
                  mb="20px"
                  borderBottom="1px solid rgba(255, 255, 255, 0.06)"
                  padding="10px"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center" w="90%">
                    <Text mr="30px">{index + 1}</Text>
                    <Image
                      w="48px"
                      height="48px"
                      src={item?.coverUrl}
                      fallbackSrc={AvatarImageTrack}
                    />
                    <Box ml="20px" overflow="hidden">
                      <Link to={linkDetail}>
                        <Text
                          noOfLines={1}
                          _hover={{
                            textDecor: "underline",
                          }}
                        >
                          {item?.title}
                        </Text>
                      </Link>

                      <Text fontSize="14px" color="#464649">
                        {`By `}
                        <LinkToTrackOrProfile
                          name={item?.createdBy?.name || ""}
                          type="user"
                        />
                      </Text>
                    </Box>
                  </Box>

                  <Text mr="10px" fontSize="16px" lineHeight="16px">
                    {totalToptracks(item)}
                  </Text>
                </Flex>
              );
            }
          )
        )}
      </Box>
    );
  }, [analyticsListTracks, isLoading, t, totalToptracks]);

  return (
    <Flex w={"100%"} padding={{ base: "16px", md: "30px" }}>
      <Box overflowY="auto" w="100%">
        <OptionsAnalytics
          selectOptionMost={selectOptionMost}
          selectOptionPeriod={selectOptionPeriod}
          setSelectOptionMost={setSelectOptionMost}
          setSelectOptionPeriod={setSelectOptionPeriod}
        />
        {!checkPlanSubscription ? <UpgradeYourPlan /> : renderTopTrackItem()}
      </Box>
    </Flex>
  );
}
