import "./styles.scss";
import React, { useCallback, useEffect, useRef } from "react";
import { usePlayers } from "hooks/player/usePlayers";

interface Props {
  checkPlaying: boolean;
}

let existingAudioContext: AudioContext | null = null;
let existingMediaElementSourceNode: MediaElementAudioSourceNode | null = null;
let analyser: AnalyserNode | null = null;

const AudioVisualizer: React.FC<Props> = ({ checkPlaying = false }) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const ctx = useRef<CanvasRenderingContext2D | null>(null);
  const bufferLength = useRef<number>(0);
  const dataArray = useRef<Uint8Array | null>(null);
  const barWidth = useRef<number>(0);
  const spaceWidth = useRef<number>(0);
  const timePercent = useRef<number>(0);
  const animationFrameId = useRef<number>(0);
  const isUnmounted = useRef<boolean>(false);
  const { wavesurferRef, isPlaying } = usePlayers();

  const animate = useCallback(() => {
    try {
      let x = 0;
      if (ctx.current && dataArray.current) {
        ctx.current.clearRect(
          0,
          0,
          canvas.current!.width,
          canvas.current!.height
        );
        analyser?.getByteFrequencyData(dataArray.current);

        let barHeight = 0;
        const borderRadius = 3;
        const totalBars = bufferLength.current;
        const progressIndex = Math.floor(
          (timePercent.current / 100) * totalBars
        );

        for (let i = 0; i < totalBars; i++) {
          let color =
            i <= progressIndex
              ? "rgba(212, 247, 14, 1)"
              : "rgba(255, 255, 255, 0.3)";
          let blurColor =
            i <= progressIndex
              ? "rgba(212, 247, 14, 0.8)"
              : "rgba(255, 255, 255, 0)";

          barHeight = Math.max(dataArray.current[i] / 6, 2);

          ctx.current.beginPath();
          ctx.current.shadowOffsetX = 0;
          ctx.current.shadowOffsetY = 0;
          ctx.current.shadowBlur = 20;
          ctx.current.shadowColor = blurColor;
          ctx.current.fillStyle = color;

          roundRect(
            ctx.current,
            x,
            canvas.current!.height - barHeight,
            barWidth.current,
            barHeight,
            borderRadius
          );

          x += barWidth.current + spaceWidth.current;
        }
      }

      if (!isUnmounted.current) {
        animationFrameId.current = requestAnimationFrame(animate);
      }
    } catch (error) {
      console.log(error, "error");
    }
  }, []);

  useEffect(() => {
    const container = document.getElementById("containerMusicId");
    const canvasElement = canvas.current;

    if (container && canvasElement) {
      canvasElement.width = container.clientWidth;
      canvasElement.height = container.clientHeight;
      ctx.current = canvasElement.getContext("2d");
    }

    return () => {
      isUnmounted.current = true;
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  useEffect(() => {
    const setupAudioContext = () => {
      if (!existingAudioContext) {
        existingAudioContext = new (window.AudioContext ||
          (window as any).webkitAudioContext)();
      }

      if (!analyser) {
        analyser = existingAudioContext.createAnalyser();
        analyser.fftSize = 512;
      }

      const mediaElement = wavesurferRef.media;

      if (mediaElement) {
        if (
          !existingMediaElementSourceNode ||
          existingMediaElementSourceNode.mediaElement !== mediaElement
        ) {
          try {
            if (existingMediaElementSourceNode) {
              existingMediaElementSourceNode.disconnect();
            }
            existingMediaElementSourceNode =
              existingAudioContext.createMediaElementSource(mediaElement);
            existingMediaElementSourceNode.connect(analyser);
            analyser.connect(existingAudioContext.destination);
          } catch (error) {
            console.log("Error creating MediaElementSource: ", error);
          }
        }
      }

      bufferLength.current = 120;
      dataArray.current = new Uint8Array(bufferLength.current);
      barWidth.current = (canvas.current!.width / bufferLength.current) * 0.8;
      spaceWidth.current = (canvas.current!.width / bufferLength.current) * 0.2;

      isUnmounted.current = false;
      animate();
    };

    if (checkPlaying && isPlaying && wavesurferRef) {
      wavesurferRef.on("audioprocess", () => {
        timePercent.current =
          (wavesurferRef.getCurrentTime() / wavesurferRef.getDuration()) * 100;
      });

      setupAudioContext();
    } else {
      isUnmounted.current = true;
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    }

    return () => {
      if (wavesurferRef && wavesurferRef.un) {
        wavesurferRef.un("audioprocess");
      }
      isUnmounted.current = true;
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [animate, checkPlaying, isPlaying, wavesurferRef]);

  useEffect(() => {
    if (!isUnmounted.current) {
      animate();
    }
  }, [animate]);

  const roundRect = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number
  ) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
    ctx.fill();
  };

  return (
    <div id="containerMusicId">
      <canvas ref={canvas} id="canvasMusicId"></canvas>
    </div>
  );
};

export default AudioVisualizer;
