export const TRANSLATIONS_POR = {
  loopAndSample: "Loops e Amostras de Terceiros",
  thisSample: "Onde você obteve esta amostra?",
  nameSample: "Qual é o nome da amostra/loop?",
  addSample: "Adicionar outro Loop/Amostra",
  welcome: "Bem-vindo ao tutorial",
  uploadeTrack: "Adicionar Faixa",
  addSoundkit: "Adicionar Kit de Som",
  dashboard: "Painel",
  home: "Início",
  privacy: "Privacidade",
  terms: "Termos",
  contactUs: "Contate-Nos",
  profile: "Perfil",
  stats: "Estatísticas",
  analytics: "Análises",
  myTracks: "Minhas Faixas",
  collaborations: "Colaborações",
  discounts: "Descontos",
  payment: "Pagamentos e Carteiras",
  subscription: "Planos de Assinatura",
  contracts: "Licenças e Contratos",
  negotiations: "Negociações",
  tracks: "Faixas",
  today: "Hoje",
  week: "Semana",
  month: "Mês",
  year: "Ano",
  addNewTrack: "Você pode adicionar uma nova faixa",
  openFile: "Abrir arquivo ou arrastar",
  uploadNewTrack: "Enviar Nova Faixa",
  showStats: "Mostrar estatísticas completas",
  newArtists: "Novos Artistas no Superprodutor",
  Statistic: "Estatística",
  CustomPeriod: "Período Personalizado",
  Week: "Semana",
  Month: "Mês",
  Year: "Ano",
  TotalPlays: "Total de Reproduções",
  Top10Countries: "Top 10 países",
  payments: "Pagamentos e Carteiras",
  stripe: "Stripe",
  paypal: "PayPal",
  connect:
    "Conecte sua conta no Stripe e comece a aceitar uma ampla variedade de métodos de pagamento, incluindo cartões de crédito e débito.",
  yourEmail: "Seu e-mail",
  connectStripe: "Conectar Stripe",
  connectpaypal:
    "Conecte sua conta do PayPal para aceitar pagamentos com cartão de débito e crédito sem esforço.",
  connectPaypal: "Conectar PayPal",
  save: "Salvar",
  TopTrack: "Principais faixas",
  TopUser: "Topo",
  Most: "Mais:",
  Website: " Website:",
  Period: "Período:",
  extended: "Visão geral estendida",
  totalSales: "Vendas Totais:",
  PRICINGPLANS: "PLANOS DE PREÇOS",
  flexiblePlan: "Escolha o plano perfeito para você",
  Annually: "Anualmente",
  Monthly: "Mensalmente",
  fullDetails: "Mostrar detalhes completos",
  FreeDemoDownloads: "Downloads de demonstração gratuitos",
  FastPayoutsFunds: "Saques rápidos de fundos",
  AcceptCreditCardAndPaypal: "Aceitar Cartão de Crédito e Paypal",
  UploadSoundKits: "Enviar Kits de Som",
  UploadTrackoutStems: "Enviar faixas separadas (STEMS)",
  withdraw: "Retirar",
  addNewCard: "Adicionar novo cartão",
  myTrack: "Minhas Faixas",
  released: "Lançado",
  purchased: "Comprado",
  favorites: "Favoritos",
  drafts: "Rascunhos",
  filter: "Filtrar",
  Discounts: "Descontos",
  PromoCode: "Código Promocional",
  noDiscounts: "Você não tem descontos",
  createPromo: "Criar um Novo Código Promocional",
  BulkOffer: "Oferta em Volume",
  noBulkOffers: "Você não tem ofertas em volume",
  NewBulkOffer: "Criar uma Nova Oferta em Volume",
  ApplyPromoCode: "Aplicar Código Promocional nas seguintes licenças",
  Create: "Criar",
  Cancle: "Cancelar",
  Back: "Voltar para descontos",
  Percent: "Percentagem de 1%-50% Apenas",
  ProductType: "Tipo de Produto",
  ExpirationDate: "Data de Expiração",
  NonExpiring: "Este é um Código Promocional Não Expirável",
  Standard: "Licença Padrão (MP3)",
  Premium: "Licença Premium (MP3) (WAV)",
  Pro: "Pro (MP3) (WAV) (STEMS)",
  NameBulkOffer: "Nome da Oferta em Volume",
  Sample: "Amostra",
  Minimum: "Número mínimo de faixas no carrinho",
  willBeForFree: "Faixas que serão gratuitas",
  bulkDiscountOption: "Criar uma nova opção de desconto em volume",
  bulkDiscount: "Aplicar este desconto em volume nas seguintes licenças",
  licensesContracts: "Licenças e Contratos",
  createALicense: "Criar uma licença",
  exclusive: "Exclusivo",
  nonExclusive: "Não-Exclusivo",
  editLicense: "Editar a licença?",
  youWillNotChange: "Você não vai mudar.",
  cancel: "Cancelar",
  done: "Feito",
  deleteLicense: "Excluir licença?",
  notRecoverIt: "Esta ação pode causar problemas com a licença da faixa.",
  delete: "Excluir",
  createNewLicense: "Criar nova licença",
  generalInfo: "Informações gerais",
  description: "Descrição opcional",
  meataData: "Metadados",
  contractDetails: "Detalhes do contrato",
  address: "Endereço",
  licensesAvailability: "Disponibilidade de Licenças",
  collaborators: "Colaboradores",
  backToLicense: "Voltar para Licenças",
  name: "Nome",
  price: "Preço",
  typeOfContract: "Tipo de contrato",
  minimum: "Preço mínimo para negociação (opcional)",
  enableAllow: "Ativar 'Permitir ofertas'",
  enable: "Ativar",
  filesToDeliver: "Arquivos a serem entregues",
  includeMp3: "Incluir MP3 (obrigatório)",
  includeWav: "Incluir WAV",
  includeTrackout: "Incluir faixas individuais (STEMS)",
  contractDetail: "Detalhes do contrato",
  distribution: "Cópias de distribuição",
  audioStreams: "Streams de áudio",
  videoStreams: "Streams de vídeo",
  musicStreams: "Vídeos musicais",
  paidPerformances: "Apresentações pagas",
  profitPerformances: "Apresentações sem fins lucrativos",
  unlimited: "Ilimitado",
  optionInformation: "Informações opcionais",
  descriptionOption: "Breve descrição (opcional)",
  featureList: "Lista de recursos",
  separateEach: "Separe cada elemento com uma quebra de linha",
  reset: "Redefinir",
  broadcastingRight: "Permitir direitos de transmissão",
  allowProfitPerformances: "Permitir apresentações com fins lucrativos",
  newLicense: "Nova licença",
  exclusivePrice: "Preço exclusivo dos direitos",
  allowOffers: "Permitir Ofertas",
  SaveToDraft: "Salvar como rascunho",
  PublishTrack: "Publicar faixa",
  BeatsPublishing: "Participação na publicação das batidas",
  BeatsWithHook: "Participação na publicação de batidas com gancho",
  TopLines: "Participação na publicação de linhas principais e vocais",
  ExclusiveLicenses: "Ativar 'Fazer ofertas apenas' para licenças exclusivas",
  QuickCustomization: "Personalização rápida",
  addTrack: "Adicionar faixa",
  metadata: "Metadados",
  trackTitle: "Título da faixa",
  beatLegacy: "Legado das batidas",
  trackCover: "Capa da faixa",
  addPicture: "Adicionar imagem",
  trackType: "Tipo de faixa",
  releaseDate: "Data de lançamento",
  url: "URL",
  audioFiles: "Arquivos de áudio para MP3 download",
  selectAudioFileForStreaming: "Selecione o arquivo de áudio para streaming",
  saveToDraft: "Salvar como rascunho",
  publishTrack: "Publicar faixa",
  coverSize: "Tamanho da capa",
  requireDownload: "Exigir download",
  twitter: "Twitter",
  soundCloud: "SoundCloud",
  superProductor: "Superprodutor",
  socialFollow: "Siga nas redes sociais",
  connectTwitter: "Conectar com o Twitter",
  connectSoundcloud: "Conectar com o SoundCloud",
  freeDownload: "Opções de download gratuito",
  no: "Não",
  yesWithVoice: "Sim, com voz (versão MP3)",
  yesUntagged: "Sim, sem tags (versão MP3 - 320kbps)",
  allowAnonymous: "Permitir download anônimo para convidados",
  maximumText: "de 355 caracteres máximos permitidos",
  bpm: "BPM",
  key: "Chave",
  tags: "Tags",
  addTags: "Adicionar tags",
  standardLicense: "Licença padrão (preço em MP3)",
  miniumAmount: "Valor mínimo de oferta",
  toDisableALicense:
    "Para desativar uma licença específica para esta faixa, insira 0 (ou 0,00).",
  thisWillDisable: "Isso desativará as compras para esse usuário específico",
  user: "Usuário",
  role: "Função",
  profitShare: "Participação nos lucros",
  publishing: "Publicação",
  Notifications: "Notificações",
  addACollaborator: "Adicionar um colaborador",
  moreNotifications: " mais notificações não lidas",
  ClearAll: "Limpar tudo",
  NoNotification: "Você está atualizado! Não tem novas notificações.",
  soundKitTitle: "Título do kit de som",
  soundKitName: "Digite o nome do kit de som",
  cover: "Capa",
  soundKitType: "Tipo de kit de som",
  UploadingSoundKit: "Carregando Kit de Som",
  UploadNewSoundKit: "Carregar Novo Kit de Som",
  UploadFiles: "Carregar Arquivos",
  OpenorDrag: "Abrir ou Arrastar",
  AudioFile: "Arquivo de Áudio",
  Edit: "Editar",
  Uploading: "Carregando...",
  addCoverArt: "Adicionar Capa",
  beforeYouAddCollaborators:
    "Antes de adicionar colaboradores, certifique-se de que você e seus colaboradores atendam aos seguintes requisitos:",
  youMustBe:
    "- Você deve ser o 'Contribuinte Principal' para adicionar colaboradores.",
  makeSure: "- Certifique-se de seguir um ao outro.",
  yourCollaborator:
    "- Seu colaborador deve ser um membro registrado do Superprodutor e pelo menos ter uma conta de assinatura 'GRATUITA'.",
  needToHave:
    "- Precisa ter seu endereço de e-mail do PayPal configurado corretamente.",
  youCanOnly: "- Você pode adicionar até cinco (5) colaboradores por faixa.",
  chooseFile: "Escolher arquivo (ou arraste aqui)",
  upLoadFiles: "Carregar arquivos",
  chooseFiles: "Escolher arquivos",
  passWordOptional: "Senha (opcional)",
  orCompress: "Ou comprima as faixas em um único arquivo .zip",
  uploadAZipFile: "Carregar um arquivo .zip",
  clientName: "Nome do Cliente",
  modifying: "Modificar esta Licença?",
  numberOfRadio: "Número de Estações de Rádio",
  numberOfProfit: "Número de Atuações com Fins Lucrativos",
  licenseRenewalTerm: "Termos de Renovação da Licença",
  freeDownloads: "Downloads Gratuitos",
  nonMonetizedVideo: "Transmissões de Vídeo Não Monetizadas",
  monetizedVideos: "Transmissões de Vídeo Monetizadas",
  yearTerm: "Prazo Anual",
  stateOrProvidence: "Estado ou Província",
  country: "País",
  Modifying: "Modificar esta Oferta a Granel?",
  EditOffer: "Editar esta Oferta a Granel",
  EditPromoCode: "Editar este Código Promocional",
  DeleteOffer: "Excluir esta Oferta a Granel",
  DeletePromoCode: "Excluir este Código Promocional",
  resetItBack: "Você pode redefinir isso.",
  deleteThisOffer: "Tem certeza de que deseja excluir este item?",
  Reactivate: "Reativar Código Promocional",
  Activate: "Ativar",
  Active: "Ativo",
  Free: "Gratuito",
  Upgrade: "Atualizar",
  CancelSubscription: "Cancelar Assinatura",
  MainstreamPlan: "Deseja manter seu plano atual do Mainstream?",
  cancelyourCurrent:
    "Isso cancelará seu plano atual e você voltará ao plano gratuito.",
  Proceed: "Continuar",
  emailAdress: "Endereço de E-mail",
  login: "Entrar",
  password: "Senha",
  username: "Nome de Usuário",
  signUp: "Inscrever-se",
  seeYourSupport: "Descubra o seu Som: Seu Único Destino para Batidas",
  signUpWithEmail: "ou Inscreva-se com E-mail",
  iGreeToThe: "Eu concordo com os",
  termsConditions: "Termos e Condições",
  alreadyAccount: "Já tem uma conta?",
  signIn: " Entrar",
  superproductorAllRight: "Superprodutor™ Todos os Direitos Reservados, 2023.",
  support: "Descubra o seu crescimento e obtenha suporte de consultoria!",
  Consistent:
    "Qualidade e experiência consistentes em todas as plataformas e dispositivos.",
  Streaming:
    "Plataforma de streaming para que os artistas descubram os ritmos de rap mais populares para seu próximo sucesso.",
  Superproductor: "Superprodutor™ Todos os Direitos Reservados, 2023.",
  CreateAnAccount: "Criar uma Conta",
  NotRegisteredYet: "Ainda não está registrado?",
  ForgetPassword: "Esqueceu sua Senha?",
  Email: "E-mail",
  Password: "Senha",
  SignUpWithEmail: "ou Inscreva-se com E-mail",
  RememberMe: "Lembrar-me",
  EditProfile: "Editar Perfil",
  OfersBulkDeals: "Ofertas a Granel",
  Followers: "Seguidores",
  Plays: "Reproduções",
  Tracks: "Faixas",
  LastActivity: "Última Atividade",
  MyProfile: "Meu Perfil",
  // TRACK TYPE
  SoundKits: "Kits de Som",
  beat: "Batida",
  beatWithChorus: "Batida com Coro",
  topLine: "Linha Principal",
  vocals: "Vocais",
  song: "Música",
  all: "Tudo em Um",
  oneShot: "Única",
  drumLoops: "Loops de Bateria",
  midiPack: "Kit de MIDI",
  melodicLoops: "Loops Melódicos",
  effects: "Efeitos",
  presets: "Presets",
  // KEY
  aFlatMinor: "Lá Bemol Menor (Lá♭m)",
  aFlatMajor: "Lá Bemol Maior (Lá♭M)",
  aMinor: "Lá Menor (Am)",
  aMajor: "Lá Maior (AM)",
  aSharpMinor: "Lá Sustenido Menor (Lá♯m)",
  aSharpMajor: "Lá Sustenido Maior (Lá♯M)",
  bFlatMinor: "Si Bemol Menor (Si♭m)",
  bFlatMajor: "Si Bemol Maior (Si♭M)",
  bMinor: "Si Menor (Bm)",
  bMajor: "Si Maior (BM)",
  bSharpMinor: "Si Sustenido Menor (Si♯m)",
  bSharpMajor: "Si Sustenido Maior (Si♯M)",
  cFlatMinor: "Dó Bemol Menor (Dó♭m)",
  cFlatMajor: "Dó Bemol Maior (Dó♭M)",
  cMinor: "Dó Menor (Cm)",
  cMajor: "Dó Maior (CM)",
  cSharpMinor: "Dó Sustenido Menor (Dó♯m)",
  cSharpMajor: "Dó Sustenido Maior (Dó♯M)",
  dFlatMinor: "Ré Bemol Menor (Ré♭m)",
  dFlatMajor: "Ré Bemol Maior (Ré♭M)",
  dMinor: "Ré Menor (Dm)",
  dMajor: "Ré Maior (DM)",
  dSharpMinor: "Ré Sustenido Menor (Ré♯m)",
  dSharpMajor: "Ré Sustenido Maior (Ré♯M)",
  eFlatMinor: "Mi Bemol Menor (Mi♭m)",
  eFlatMajor: "Mi Bemol Maior (Mi♭M)",
  eMinor: "Mi Menor (Em)",
  eMajor: "Mi Maior (EM)",
  eSharpMinor: "Mi Sustenido Menor (Mi♯m)",
  eSharpMajor: "Mi Sustenido Maior (Mi♯M)",
  fFlatMinor: "Fá Bemol Menor (Fá♭m)",
  fFlatMajor: "Fá Bemol Maior (Fá♭M)",
  fMinor: "Fá Menor (Fm)",
  fMajor: "Fá Maior (FM)",
  fSharpMinor: "Fá Sustenido Menor (Fá♯m)",
  fSharpMajor: "Fá Sustenido Maior (Fá♯M)",
  gFlatMinor: "Sol Bemol Menor (Sol♭m)",
  gFlatMajor: "Sol Bemol Maior (Sol♭M)",
  gMinor: "Sol Menor (Gm)",
  gMajor: "Sol Maior (GM)",
  gSharpMinor: "Sol Sustenido Menor (Sol♯m)",
  gSharpMajor: "Sol Sustenido Maior (Sol♯M)",
  // ADD TRACK REQUIRED
  yes: "Sim",
  soundkitAddedSuccessfully: "Kit de Som Adicionado com Sucesso",
  soundkitAddedDescription:
    "Seu kit de som foi adicionado com sucesso à página. Vá para o feed para vê-lo",
  emptyString: "String Vazia",
  publishSoundkit: "Publicar Kit de Som",
  trackTitleRequired: "Por favor, adicione um título de faixa",
  urlRequired: "Por favor, adicione uma URL",
  soundkitTitleRequired: "Por favor, adicione um título de kit de som",
  soundkitBasicPriceRequired: "Por favor, adicione um preço",
  coverUrlRequired: "Por favor, adicione uma imagem de capa",
  trackTypeRequired: "Por favor, selecione um tipo de faixa",
  soundkitTypeRequired: "Por favor, selecione um tipo de kit de som",
  bpmRequired: "BPM é um campo obrigatório",
  keyRequired: "Selecione uma chave",
  primaryGenreRequired: "Selecione um gênero principal",
  secondaryGenreRequired: "Selecione um subgênero",
  primaryMoodRequired: "Selecione um humor principal",
  descriptionRequired: "A descrição é um campo obrigatório",
  sampleRequired: "Este é um campo obrigatório",
  contractTypeRequired: "Por favor, selecione um tipo de contrato",
  untaggedFileUrlRequired: "Por favor, faça o upload de um arquivo de áudio",
  licenseRequired: "Por favor, selecione pelo menos uma licença",
  tagsRequired: "Por favor, crie pelo menos 4 etiquetas",
  uploadTrackStems: "Por favor, faça o upload dos STEMS da faixa",
  uploadTrackWav: "Por favor, faça o upload dos WAV da faixa",
  collaboratorsRequired: "Por favor, selecione pelo menos um colaborador",
  audioFileUrlRequired: "O arquivo de áudio é obrigatório",
  downloadFilesUrlRequired: "É necessário o arquivo de download",
  taggedFileUrlRequired:
    "Por favor, faça o upload de um arquivo de áudio marcado",
  yourFileMustBeWavOrFlac:
    "- Seu arquivo deve estar no formato WAV ou FLAC com uma frequência de amostragem de 44,1 kHz em estéreo e com 16 bits de compressão.",
  uploadAudioFile: "Enviar arquivo de áudio",
  uploadCompressFile: "Enviar arquivo compactado",
  WAVorMP3: ".MP3 (ou WAV)",
  fileDescription: "Descrição",
  uploadCover: "Enviar Capa",
  SelectTypeOfContract: "Selecione o tipo de contrato",
  SelectTrackType: "Selecione o tipo de faixa",
  SelectSoundKitType: "Selecione o tipo de kit de som",
  selectGenre: "Selecione um gênero",
  selectSubGenre: "Selecione um subgênero",
  selectKey: "Selecione uma chave",
  view: "Visualizar",
  selectPrimaryMood: "Selecione um humor principal",
  selectSecondMood: "Selecione um segundo humor",
  enterDescription: "Digite a descrição aqui",
  maxBpm: "O BPM máximo é 999",
  maximunDescription: "Máximo de 355 caracteres permitidos",
  cropCover: "Recortar capa",
  enableAllowOffersNonExclusive:
    "Habilitar 'Permitir Ofertas' para Licenças Não Exclusivas",
  includeBulkOfferDiscout: "Incluir em descontos a granel",
  licenseNote: "Nota:",
  customizeYourLicense: "Você pode personalizar suas licenças aqui:",
  LicenseAndContracts: "Licenças e Contratos",
  private: "Privado",
  excludeBulkOfferDiscout: "Excluir de Descontos a Granel",
  notForSale: "Não Disponível para Venda",
  freeDownloadEnabledRequired:
    "Por favor, selecione uma Opção de Download Gratuito",
  includesMp3AndWav: "Inclui MP3 e WAV",
  includesMp3WavAndTrackStems: "Inclui MP3, WAV e STEMS da Faixa",
  includesMp3AndTrackStems: "Inclui MP3 e STEMS da Faixa",
  includesMp3: "Inclui MP3",
  tagPreview: "Prévia da Etiqueta",
  audioTagForStreaming: "Etiqueta de Áudio para Streaming",
  defaultAudioVoiceTag: "Etiqueta de Voz Padrão de Superprodutor.mp3",
  uploadFile: "Arquivo Enviado:",
  uploadAudioVoiceTag: "Etiqueta de Áudio Personalizada.mp3",
  noteUploadCover: "Mínimo: 500x500, Preferido: 1500x1500",
  uploadSuccess: "Carregado com sucesso!",
  MP3CustomVoiceTag: "Etiqueta de Voz Personalizada MP3",
  MP3CustomVoiceTagNote:
    "Se você não tiver uma etiqueta: \n Uma etiqueta padrão do Superprodutor será reproduzida automaticamente.\n A etiqueta começará após 1 segundo e, em seguida, será reproduzida a cada 30 segundos.",
  trackStems: "STEMS da Faixa",
  trackStemsDetail: ".ZIP para Download",
  untaggedWAV: "WAV sem Etiquetas",
  untaggedMP3: "MP3 sem Etiquetas",
  minBitrateWAV: ".WAV (Arquivo recomendado em 44,1 WAV 24 bits ou superior)",
  minBitrateWAVorMP3: ".MP3 (Mín. Taxa de Bits 128)",
  errorUploadAudio: "Erro ao enviar o arquivo",
  minimunBitrate:
    "Por favor, selecione um áudio com uma taxa de bits de pelo menos 128 kbps",
  fieldToDeliver: "Campo para Entregar",
  NoDescriptionYet: "Ainda não há descrição",
  EditAvatar: "Editar Avatar",
  MinimumSize: "Mínimo: 500x500 pixels, Preferido: 1500x1500 pixels",
  Country: "País",
  About: "Sobre",
  Symbols: "Símbolos",
  NotWriteMore: "você não pode escrever mais palavras",
  Credits: "Créditos",
  SeparateEachLine: "Separe cada linha pressionando o botão Enter.",
  editLicenses: "Editar Licença",
  update: "Atualizar",
  upToDate: "Você está atualizado!",
  uploaded: "enviado",
  displayName: "Nome de Usuário",
  trackAddedSuccessfully: "Uma Faixa foi Adicionada ao seu Perfil",
  trackAddedDescription:
    "Sua faixa foi adicionada com sucesso à página. Vá para o feed para vê-la",
  backToFeed: "Voltar ao feed",
  contract: "Contrato",
  producer: "Produtor",
  selectRole: "Selecione um papel",
  publishingRequired: "Publicação é obrigatória",
  profitShareRequired: "Compartilhamento de Lucros é obrigatório",
  roleRequired: "Um papel é obrigatório",
  profitShareMin: "Pelo menos 1% de Compartilhamento de Lucros é obrigatório",
  profitShareMax: "Compartilhamento de Lucros deve ter um máximo",
  publishingMin: "Pelo menos 1% de Publicação é obrigatório",
  publishingMax: "Publicação deve ter um máximo",
  publishingMaxExclusive: "Publicação deve ter um máximo de 50%",
  profitShareNumber: "Compartilhamento de Lucros deve ser um número",
  publishingNumber: "Compartilhamento de Lucros deve ser um número",
  exclusiveContract: "Contrato Exclusivo",
  nonExclusiveContract: "Contrato Não Exclusivo",
  requiredUploadFiles:
    "Para ativar esta licença, você deve fazer o upload dos seguintes arquivos:",
  passwordUser: "senha",
  PasswordNotMatch: "Senha não corresponde",
  confirmPassword: "Confirmar Senha",
  ResetYourPassword: "Redefinir sua senha?",
  emailSent: "E-mail enviado com instruções",
  enterYourEmail:
    "Digite seu e-mail abaixo e enviaremos instruções sobre como criar uma nova senha.",
  forgotYourPassword: "Esqueceu sua senha?",

  // SUBSCRIPTION PLAN
  freePlan: "Plano Gratuito",
  proPlan: "Plano Pro",
  freePlanPrice: "Grátis",
  free: "Grátis",
  freePlanPriceDescription: "$0.00",
  freePlanDescription:
    "Comece Grátis, Cresça Grande: Preços Escaláveis para Músicos",
  mainstreamMonthlyPlan: "Plano Mensal Mainstream",
  mainstreamAnnualPlan: "Plano Anual Mainstream",
  mainstreamMonthlyPlanPrice: "$1.67 / Mês",
  mainstreamAnnualPlanPrice: "$19.99 / Ano",
  mainstreamPlanPriceDescriptionMonth: "Faturado a cada mês",
  mainstreamPlanPriceDescriptionYear: "$99.88 / Ano",
  mainstreamPlanPriceDiscountMonth: "0% de desconto",
  mainstreamPlanPriceDiscountYear: "17% de desconto",
  mainstreamMonthlyPlanDescription:
    "Para vendedores que desejam expandir seu negócio de música e acessar recursos selecionados",
  mainstreamAnnualPlanDescription:
    "Desbloqueie 0% de Taxas: Faça o Upgrade por Apenas $19,99 Hoje!",
  activePlan: "Plano Ativo",
  active: "Ativo",
  upgrade: "Atualizar",
  cancelDateText: "Esta assinatura está ativa até ",
  cancelSubscription: "Cancelar Assinatura",
  discoutOff: "-17% de Desconto",
  cancelDescription:
    "Seu Plano de Assinatura Mainstream foi cancelado. Quando você cancela sua assinatura a qualquer momento durante seu ciclo mensal ou anual, sua conta permanece ativa até o final do período de faturamento. No entanto, sua conta permanecerá ativa como uma conta gratuita. Se for um erro, avise-nos e ajudaremos imediatamente.",
  upgradeDescription:
    "Seu Plano de Assinatura Mainstream foi confirmado. Quando você cancela sua assinatura a qualquer momento durante seu ciclo mensal ou anual, sua conta permanece ativa até o final do período de faturamento. No entanto, sua conta permanecerá ativa como uma conta gratuita. Se for um erro, avise-nos e ajudaremos imediatamente.",
  cancelLabel: "Assinatura Cancelada",
  upgradeLabel: "Assinatura Confirmada",
  cancelYourCurrent:
    "Isso rebaixar seu plano atual e voltará ao plano gratuito.",
  mainstreamAnnualPriceDescription: "Faturado a cada ano",
  mainstreamMonthlyPriceDescription: "Faturado a cada mês",
  planUpgrade: "Atualizar Plano",
  planReview: "Revisão do Plano",
  thanksForBuying: "Obrigado por comprar:",
  upgradeToMainstreamPlan: "Atualizar para o Plano Mainstream",
  pleaseCheckEmail:
    "Por favor, verifique seu e-mail para confirmação do pedido e informações detalhadas de entrega.",
  // END SUBSCRIPTION PLAN
  cart: "Carrinho",
  basketIsEmpty:
    "Seu carrinho está vazio. Explore novas faixas na sua busca pela sua biblioteca musical",
  purchaseDetail: "Detalhes da Compra",
  cartSummary: "Resumo do Carrinho:",
  gotPromoCode: "Tem um código promocional?",
  placeYourOrder: "Faça seu pedido",
  chooseLicense: "Escolha a Licença",
  addToCart: "Adicionar ao Carrinho",
  acceptPayment: "Formas de Pagamento Aceitas:",
  debitOrCredit: "Cartão de Débito ou Crédito",
  usageTerms: "Termos de Uso",
  errorAddToCart:
    "Este usuário ainda não vinculou o PayPal ou Stripe. Você não pode adicionar esta faixa ao carrinho.",
  distributionCopies: "Cópias de Distribuição",
  nonVideoStreams: "Transmissões de Vídeo Não Monetizadas",
  monetizedVideoStreams: "Transmissões de Vídeo Monetizadas",
  profitLive: "Lucro de Apresentações ao Vivo",
  profitPerformance: "Lucro de Performances",
  yearLicenseRenewal: "Renovação Anual de Licença",
  mustCredit: "Deve dar crédito a:",
  prodBy: "Prod. por:",
  filesToBeDelivered: "Arquivos a serem entregues:",
  mp3: "MP3",
  wav: "WAV",
  stems: "STEMS",
  unlimitedFreeDownload: "Download Ilimitado Gratuito",
  license: "Licença",
  licenseReview: "Revisão de Licença",
  playTrack: "Reproduzir faixa",
  remove: "Remover",
  businessName: "Nome da Empresa",
  bankAccount: "Conta Bancária",
  transactionId: "ID da Transação",
  saleDate: "Data da Venda",
  specification: "Especificações",
  addStripe: "Adicionar Stripe",
  addPaypal: "Adicionar Paypal",
  registerSuccessfully: "Registro bem-sucedido",
  backToPayment: "Voltar para Pagamentos e Carteiras",
  selectAll: "Selecionar Tudo",
  removeFromCart: "Remover do Carrinho",
  removeFromTrack: "Remover da Faixa",
  removeFromSoundkit: "Remover do Kit de Som",
  paymentMethods: "Métodos de Pagamento",
  confirm: "Confirmar",
  paymentMethod: "Método de Pagamento:",
  checkoutWithPaypal: "Finalizar com o Paypal",
  confirmPayment: "Confirmar Pagamento",
  checkOut: "Finalizar Compra",
  yourTrackOrder: "Recebemos seu ",
  trackOrder: "pedido de faixa.",
  contactYourPurchase:
    "Houve um erro com sua compra. Entre em contato com seu provedor.",
  contactYourBank:
    "Por favor, contate seu banco. Um botão para tentar novamente.",
  serverError: "Erro Interno do Servidor",
  backToCheckout: "Voltar ao processo de compra",
  creditCard: "Cartão de Crédito:",
  cardHolderName: "Nome do Titular do Cartão",
  cardNumber: "Número do Cartão",
  date: "Data",
  cvc: "CVC",
  continue: "Continuar",
  errorCardNumber: "Número do Cartão Inválido",
  dateIsRequired: "A Data é um campo obrigatório",
  invalidCvc: "CVC Inválido",
  nameIsRequired: "O Nome é um campo obrigatório",
  change: "Mudar",
  disconnect: "Desconectar",
  youWantToDisconnect:
    "Desconectar sua conta do PayPal impedirá que você ofereça serviços e produtos do PayPal em nosso site.",
  paymentConflict: "Deseja continuar?",
  paymentAccounts: "Contas de Pagamento",
  disconnectSuccessfully: "Desconexão bem-sucedida",
  disconnectFailed: "Falha na Desconexão",
  rememberThisCard: "Lembrar deste cartão para transações futuras",
  offersBulkDeals: "Ofertas em Massa",
  message: "Mensagem",
  follow: "Seguir",
  bulkOfferDetails: "Detalhes da Oferta em Massa",
  addToQueue: "Adicionar à Fila",
  removeFromQueue: "Remover da Fila",
  missingTrackTitle: "Título da faixa ausente",
  missingTrackFiles: "Arquivos ausentes",
  missingTrackLicenses: "Licenças e Contratos ausentes",
  alreadyRepost: "Você já repostou esta faixa anteriormente",
  alreadyRepostSK: "Você já repostou este kit de som anteriormente",
  lyricTitle: "Título da Letra",
  writeLyricTitle: "Escreva o título da letra",
  lyrics: "Letra",
  writeLyricshere: "Escreva suas letras aqui...",
  editLyrics: "Editar Letra",
  saveLyrics: "Salvar Letra",
  trackTo: "Faixa para",
  toFavorites: "para Favoritos",
  missTypeSoundkit: "Tipo de kit de som ausente",
  editTrack: "Editar Faixa",
  soundkit: "Kit de Som",
  writeLyrics: "Escreva a Letra",
  backToMyTrack: "Voltar para as minhas faixas",
  editTrackSuccessfully: "Faixa editada com sucesso",
  editSoundkit: "Editar Kit de Som",
  editSoundkitSuccess: "Kit de Som editado com sucesso",
  backToMySoundkit: "Voltar para os meus kits de som",
  minimumTracksRequired: "Um número mínimo de faixas é necessário",
  freeTracksLessThan:
    "O número de faixas gratuitas deve ser menor que o número mínimo de faixas",
  freeTracksRequired: "Um número mínimo de faixas gratuitas é necessário",
  atLeastOneLicense: "Por favor, selecione pelo menos uma licença",
  EditBulkOffer: "Editar Oferta em Massa",
  ApplyToLicense: "Aplicar às seguintes licenças",
  editExitBulkOffer: "Editar uma Oferta em Massa existente",
  queue: "Fila",
  playingQueue: "Fila de Reprodução",
  clearQueue: "Limpar Fila",
  ModifyingPromoCode: "Modificando Código Promocional",
  promoCodeRequired: "O código promocional é um campo obrigatório",
  productType: "Tipo de Produto",
  productTypeRequired: "O Tipo de Produto é um campo obrigatório",
  percent: "Percentagem de Desconto (Apenas 1% - 50%)",
  percentPositive: "A Percentagem de Desconto deve ser um número positivo",
  percentLessThan50: "A Percentagem de Desconto deve ser menor que 50",
  percentRequired: "A Percentagem de Desconto é um campo obrigatório",
  expirationDate: "Data de Expiração",
  setExpirationDate: "Definir Data de Expiração do Código Promocional",
  expirationDateRequired: "A Data de Expiração é um campo obrigatório",
  nonExpiring: "Este é um Código Promocional que não expira",
  licenses: "Aplicar este código promocional às seguintes licenças",
  NewPromoCode: "Criar um Novo Código Promocional",
  mustBeLaterThanToday: "A data deve ser posterior à de hoje.",
  editExistPromoCode: "Editar código promocional existente",
  editPromo: "Editar Código Promocional",
  startNegotiation: "Iniciar Negociação",
  youHaveNo: "Você não tem",
  negotiation: "negociações",
  NoHaveNegotiations: "Se você tiver negociações, elas aparecerão nesta página",
  negotiationDeal: "Acordo de Negociação",
  paymentCompleted: "Pagamento Completo",
  negotiationsStatus: {
    ACCEPTED: "Aguardando pagamento",
    COUNTEROFFER: "Contraoferta enviada",
    EXPIRED: "Expirado",
    REJECT_BY_SELLER: "Rejeitado pelo vendedor",
    CANCEL_BY_BUYER: "Cancelado pelo comprador",
    COUNTEROFFER_ACCEPTED: "Aguardando pagamento",
    COMPLETED: "Concluído",
    PENDING: "Pendente",
    SOLD_TO_SOMEONE_ELSE: "Vendido para outra pessoa",
  },
  counterofferTimeout:
    "Tempo limite da contraoferta. Crie uma nova contraoferta para continuar.",
  expiresIn: "Expira em",
  expiredDayOn: "Expirou em ",
  exclusiveLicense: "Licença Exclusiva",
  publishingNegotiation: "Publicação:",
  accept: "Aceitar",
  counteroffer: "Contraoferta",
  paynow: "Pagar agora",
  reject: "Rejeitar",
  upgradePlan: "Atualizar Plano",
  myProfile: "Meu perfil",
  messages: "Mensagens",
  playlistsHub: "Hub de Listas de Reprodução",
  offerExpiresIn: "Oferta expira em",
  negotiationOffer: "Oferta de Negociação",
  yourCounterOffer: "Sua Contraoferta",
  reviewCounteroffer: "Revisar Contraoferta",
  yourOffer: "Sua Oferta",
  quantity: "Quantidade",
  total: "Total",
  submitOffer: "Enviar Oferta",
  editOffer: "Editar Oferta",
  newConversations: "Nova Conversa",
  startConversation: "Iniciar Conversa",
  typeYourMessageHere: "Digite sua mensagem aqui...",
  send: "Enviar",
  emptyConversation: "Quando você receber uma mensagem, verá aqui.",
  youHaveCollaborate: "Você tem",
  invitationToCollaborate: "convite para Colaborar",
  youHaveOpen:
    "Se você tiver convites abertos para colaboração, eles aparecerão nesta página",
  openInvitations: "Convites Abertos",
  closeInvitations: "Fechar Convites",
  profitShareCollaborations: "Compartilhamento de Lucros",
  publishCollaborations: "Publicação",
  emptyNotificatioon: "Quando você receber notificações, verá aqui.",
  selectTypeBeforeCollabotations:
    "Selecione o tipo de contrato antes de adicionar colaboradores",
  selectLicenseBeforeCollabotations:
    "Selecione a Licença antes de adicionar colaboradores",
  invieExpiredOn: "Convite Expirado em ",
  youHaveNoAnalytics: "Você não tem atividade de análise",
  dataAnalytics: "Análise de Dados",
  this: "isso",
  ofMyTrack: "das minhas faixas",
  emptyTopCountry: "Você não tem atividade recente",
  hideExtendedOverview: "Ocultar visão geral estendida",
  showExtendedOverview: "Mostrar visão geral estendida",
  mostPopularLicense: "Licença Mais Popular",
  youHaveNoRecentActivity: "Você não tem atividade recente",
  schedule: "Agenda",
  charactersLeft: "caracteres restantes.",
  aMinimumOfMaximum: "Serão aceitos de 0 a 70 caracteres no mínimo e máximo",
  openCollaborations: "Convites Abertos",
  forThe: "para o",
  of: "de ",
  productorPerformance: "Desempenho do Produto",
  consumerBeHaviour: "Comportamento do Consumidor",
  noLyricsYet: "Ainda não há letras",
  contentEmptyLyrics:
    "Não há nada como compor. Comece agora a escrever suas letras!",
  createLyrics: "Criar Letras",
  accepted: "Aceito",
  from: "de",
  youHave: "Você tem",
  freeTrackRemain: "faixas gratuitas restantes",
  addMore: "Adicionar mais ",
  toApplyBulkOffer: "faixas para aplicar a oferta em massa: ",
  noneDiscount: "Sem Desconto",
  discount: "Desconto:",
  bulkDealApplied: "Oferta em Massa Aplicada",
  applyPromoCode: "Aplicar Código Promocional",
  checkPromoCode: "Verificar Código Promocional",
  invalidCode: "Código inválido",
  promoCodeDiscount: "Desconto do Código Promocional:",
  buyInBulkDeals: "Comprar em Ofertas em Massa",
  feed: "Feed",
  discover: "Descobrir",
  explore: "Explorar",
  hotProducers: "Produtores em Alta",
  recentActions: "Ações Recentes",
  newTalents: "Novos Talentos",
  recommended: "Recomendado",
  freeBeats: "Beats Grátis",
  trendingTags: "Tags em Tendência",
  track: "Faixa",
  slugTrack: "Slug já existe",
  underRadar: "Abaixo do Radar",
  trendingBeats: "Beats em Tendência",
  genres: "Gêneros",
  moods: "Mood",
  refresh: "Atualizar",
  newRelease: "Novo lançamento:",
  searchResultsFor: "Resultados da busca por:",
  trackName: "Nome da Faixa",
  musicianName: "Nome do Músico",
  soundPacksName: "Nome do Kit de Sons",
  playlistName: "Nome da Lista de Reprodução",
  nameTag: "Tag de Nome",
  musicians: "Músicos",
  soundPacks: "Kits de Som",
  playlist: "Lista de Reprodução",
  subGenres: "Subgêneros",
  showMore: "Mostrar mais",
  showLess: "Mostrar menos",
  location: "Localização",
  noNewRelease: "Sem lançamentos novos",
  globalCounsumers: "Consumidores Globais",
  unfollow: "Deixar de seguir",
  textDescriptionFeedHome:
    "Você terá acesso rápido para ir direto a novas faixas com base no seu gosto musical, facilitando a busca por novas inspirações.",
  textDescriptionDiscoverHome:
    "Descubra as faixas mais quentes do dia em todo o mundo. Você certamente encontrará sua próxima faixa favorita com novos talentos. Portanto, dê uma ouvida e deixe sua imaginação correr solta!",
  textDescriptionExploreHome:
    "Quer esteja explorando um novo gênero ou apenas procurando novos sons para curtir, esta é uma ótima maneira de expandir seus horizontes musicais.",
  inActive: "Inativo",
  mySoundKits: "Meus Kits de Sons",
  searchSoundPacks: "Buscar Kits de Sons",
  shareLink: "Compartilhar Link",
  shortLink: "Link Curto",
  copy: "Copiar",
  FREE: "GRÁTIS",
  noDescription: "Sem descrição",
  whoAreYou: "Quem é você?",
  next: "Próximo",
  tellUsAboutYour: "Conte-nos um pouco sobre o seu histórico!",
  musicListener: "Você é um Ouvinte de Música ou Supervisor de Música? *",
  songWrite: "Você é Cantor, Compositor, Dançarino ou Criador de Conteúdo? ",
  artist: "Artista",
  doYouMakeBeats: "Você produz batidas? ",
  chooseYourInterest: "Escolha seu interesse",
  descriptionInterest:
    "Você está livre para escolher qualquer interesse em gênero musical. Isso nos ajudará a mostrar conteúdo com base em seus interesses.",
  allNotification: "Ver todas as notificações",
  unRead: "Não lido",
  limitUploadTrack: "Você atingiu o limite de uploads de faixas",
  limitNewLicense: "Você atingiu o limite de criação de licenças",
  noData: "Sem Dados",
  guest: "Convidado",
  updatePictureProfile: "Atualizar Foto de Perfil",
  pictureProfile: "Foto de Perfil",
  preview: "Visualização",
  chooseFileImage: "Escolher Arquivo",
  saveChanges: "Salvar Alterações",
  titleLicenseLimit: "Limite de Acordos de Licença Eletrônica Atingido",
  descriptionLimit:
    "Olá! Você atingiu o limite de 4 Acordos de Licença Eletrônica. Faça upgrade do seu plano para continuar criando mais acordos.",
  upgradeYourPlanToUpload:
    "Faça upgrade do seu plano para fazer upload de MP3 Custom Voice Tag",
  pleaseUpgradeYourPlan:
    "Olá! Para fazer o upload de um MP3 Custom Voice Tag, faça upgrade do seu plano.",
  uploadTrackLimitReached: "Limite de Upload de Faixa Atingido",
  youHaveReachedTrack:
    "Ops! Você atingiu o limite de 10 faixas enviadas. Faça upgrade do seu plano para continuar enviando",
  limitUploadSoundPacks: "Faça upgrade do seu plano para continuar enviando",
  titleLimitUploadSoundPacks: "Enviar kit de som",
  messagesLimitReached: "Limite de Mensagens Atingido",
  descriptionFiveMessagesLimitReached:
    "Olá! Você atingiu o limite de 5 mensagens. Faça upgrade do seu plano para continuar enviando mensagens.",
  descriptionTwentyMessagesLimitReached:
    "Olá! Você atingiu o limite de 20 mensagens por mês",
  charactersRemaining: "caracteres restantes",
  youHaveExceededTheMaximum: "Você excedeu o limite máximo de caracteres",
  add: "Adicionar",
  more: "mais",
  leaveAddTrackPage: "Sair da página Adicionar Faixa?",
  descriptionLeaveTrackPage:
    "A faixa será salva como rascunho. Continue de onde parou mais tarde.",
  leave: "Sair",
  stay: "Ficar",
  termAndConditions: "Termos e Condições",
  privacyPolicy: "Política de Privacidade",
  enterTrackTitle: "Insira o Título da Faixa",
  enterTag: "Insira a Tag",
  enterBpm: "Insira o BPM",
  inCart: "No Carrinho",
  descriptionNonExclusive:
    "Esta licença de beat não exclusiva lhe dá o direito de usar o beat, mas o proprietário ainda pode dar permissão a outras pessoas para usá-lo também. Isso significa que outros artistas que também têm permissão para usar o beat também podem usá-lo.",
  descriptionExclusive:
    "Uma licença exclusiva de beat significa que você será o único que pode usar o beat. O proprietário não pode permitir que mais ninguém o use durante o período da licença.",
  descriptionAddTrack:
    "Procurando a Batida Perfeita? Não Procure Mais! Nossa Biblioteca de Faixas tem tudo o que você precisa! De Grooves Cativantes a Estilos Específicos de Gênero, Temos Tudo Isso. Seja para um EP, Álbum, Mixtape, Videoclipe ou qualquer projeto que precise de uma batida, nós temos o que você procura!",
  collaborationInviteSent: "Convite para Colaboração Enviado!",
  descriptionCollaborationInviteSent:
    "Agora, aguardemos até que o(s) colaborador(es) envie(m) a resposta. A faixa deve estar na área de trabalho dentro das faixas na seção de rascunhos, aguardando aprovação para ser publicada.",
  firstNameIsRequired: "O Nome é um campo obrigatório",
  lastNameIsRequired: "O Sobrenome é um campo obrigatório",
  completeYourProfile: "Complete seu Perfil",
  beforePublishingYourTracks:
    "Antes de publicar suas faixas, reserve um momento para completar seu perfil.",
  pseudonymName: "Nome de Pseudônimo",
  pageNotFound: "Página não encontrada",
  editCard: "Editar cartão",
  editCreditOrDebitCard: "Editar cartão de crédito ou débito",
  invalidDate: "Data inválida",
  removeCard: "Remover cartão",
  removeThisCard: "Remover este cartão?",
  areYouSureRemoveCard: "Tem certeza de que deseja remover este cartão?",
  action: "Ação",
  soundPack: "Kit de Som",
  soundPackIsNotForSale: "Este kit de sons não está à venda.",
  availability: "Disponibilidade",
  addPaymentAndWallets: "Adicionar conta de pagamento",
  desAddPaymentAndWallets:
    "Antes de publicar suas faixas, reserve um momento para adicionar uma conta de pagamento.",
  open: "Abrir",
  tyOfContract: "Tipo de contrato",
  sharePrivately: "Compartilhar Privadamente",
  setLinkExpiration: "Definir Expiração do Link",
  expirationMode: "Modo de Expiração",
  expireAfter: "Expira Após",
  clicks: "Cliques",
  usersAdded: "Usuários Adicionados",
  placeHolderLinkExpiration:
    "Olá!\nTenho algo especial para você - uma faixa exclusiva apenas para alguns selecionados, e você é um deles! Clique no link privado abaixo para ouvir e aproveitar. Deixe-me saber o que você acha! Seu feedback é muito importante para mim.",
  desUserAdded: "Apenas pessoas com acesso podem abrir este link",
  createPassword: "Criar Senha",
  optional: "Opcional",
  userRequired: "Selecione pelo menos um usuário para continuar.",
  expireRequired: "Forneça uma data válida para continuar.",
  aTrackHasBeenSharePrivately: "Uma Faixa Foi Compartilhada Privadamente",
  congratulationsDes:
    "Parabéns! Sua faixa foi compartilhada com sucesso de forma privada com usuários selecionados usando nosso link exclusivo e seguro de compartilhamento privado.",
  trackSaveAsDraft: "Salvar Faixa como Rascunho",
  desTrackSaveAsDraft:
    "Ótimo progresso! Sua faixa foi salva como rascunho. Ela ainda não está pronta para o mundo, mas você está no caminho certo. Sinta-se à vontade para voltar a qualquer momento.",
  trackHasChangedToRelease: "Confirmação de Lançamento da Faixa",
  desTrackHasChangedToRelease:
    "Parabéns! Sua faixa foi movida com sucesso de Rascunho para Lançamento. Agora está pronta para alcançar seu público.",
  playlists: "Listas de Reprodução",
  createNew: "Criar Nova",
  createNewPlaylist: "Criar Nova Lista de Reprodução",
  enterPlaylistName: "Insira o nome da sua lista de reprodução",
  enterUpToPlaylistTag: "Insira até 3 tags para a lista de reprodução",
  enterAnOptionalDescription: "Insira uma descrição opcional",
  visibility: "Visibilidade",
  savePlaylist: "Salvar Lista de Reprodução",
  datedAdded: "Data de Adição:",
  playlistOwner: "Proprietário da Lista de Reprodução: ",
  information: "Informações",
  published: "Publicado",
  runtime: "Duração",
  lastUpdated: "Última Atualização",
  noPlaylists: "Sem Listas de Reprodução",
  thereAreNoPlaylistAvailable: "Não há listas de reprodução disponíveis.",
  addToPlaylist: "Adicionar à lista de reprodução",
  myPlaylists: "Minhas Listas de Reprodução",
  editPlaylist: "Editar Lista de Reprodução",
  alreadyRepostPlaylist: "Você já repostou esta lista de reprodução",
  noTracks: "Sem Faixas",
  thereAreNoTracksAvailable: "Não há faixas disponíveis.",
  progress: "Progresso",
  myPlaylist: "Minha Lista de Reprodução",
  following: "Seguindo",
  playAll: "Reproduzir Tudo",
  emptyPurchasedHistory: "Histórico de Compras Vazio",
  item: "Item",
  purchaser: "Comprador",
  seller: "Vendedor",
  purchasedDate: "Data da Compra",
  downloadConfirmation: "Confirmação de Download",
  downloadFiles: "Baixar Arquivos",
  Pending: "Pendente",
  Downloaded: "Baixado",
  geographicalIpFinder: "Localizador Geográfico de IP",
  Eng: "Inglês",
  Spa: "Espanhol",
  Por: "Português",
  placeHolderSearchInputHeader: "Digite o que você está procurando",
  clear: "Limpar",
  logout: "Terminar sessão",
  downloads: "Downloads",
  sales: "Vendas",
  itemSold: "Item Vendido",
  sold: "Vendido",
  Position: "Posição",
  gross: "Bruto",
  collab: "Colaboração%",
  super: "Super%",
  earnings: "Ganhos",
  by: "Por",
  played: "Tocado",
  favorited: "Favoritado",
  listeners: "Ouvintes",
  userWithMoreDownloads: "Usuário com mais downloads",
  userWithMoreRepost: "Usuário com mais repostagens",
  buyers: "Compradores",
  searchTrack: "Buscar Faixa",
  selectPromoCodeType: "Selecionar Tipo de Código Promocional",
  get: "obter",
  freeBulk: "grátis",
  comingSoon: "Em Breve",
  licenseName: "Nome da Licença",
  selectTypeOfContract: "Selecionar Tipo de Contrato",
  enterStateOrProvidence: "Digite o estado ou província",
  enterBeatsPublishing: "Insira a publicação de beats",
  enterBeatsHookPublishing: "Digite a publicação de gancho de beats",
  enterTopLinesVocalsPublishing:
    "Insira a publicação de linhas principais e vocais",
  selectYearTerm: "Selecione o prazo em anos",
  automaticallyDeducts:
    "Deduz automaticamente o método de pagamento do cliente no prazo de renovação anual selecionado.",
  enterPromoCode: "Inserir código promocional",
  newestFirst: "Mais recentes primeiro",
  lowestPrice: "Lowest Price",
  highestPrice: "Highest Price",
  "Released a": "Publicou um",
  "Reposted a": "Repostou um",
  searchPlaylist: "Pesquisar lista de reprodução",
  rejected: "Rejeitado",
  youInvite: "Você convida",
  toCollaborateAs: "colaborar como um",
  inviteYouToCollaborateAs: "convidamos você a colaborar como um",
  invitationSentToCollaborateAs: "Convite enviado para colaborar como um",
  hasAccepted: "aceitou",
  hasRejected: "rejeitou",
  invitationToCollaborateAs: "convite para colaborar como um",
  you: "Você",
  offersYou: "oferece-te",
  for: "para",
  Item: "artigo",
  youCreatedAOffers: "Você criou uma oferta",
  offerWithPrice: "Oferta com preço",
  isPending: "está pendente",
  your: "Seu",
  offerHasExpire: "a oferta expirou",
  offer: "Oferta",
  hasBeenCanceledBy: "foi cancelada por",
  hasBeenRejectBy: "foi rejeitada por",
  counterOfferPrice: "preço de contraproposta",
  hasSent: "enviou",
  youA: "Você um",
  youHaveReceivedPayment: "Você recebeu um pagamento",
  thanksForYourPayment: "Obrigada pelo seu pagamento",
  fromNow: "a partir de agora",
  searchCollaborators: "Procurar colaboradores",
  enterPublishing: "Entrar na publicação",
  enterProfitShare: "Entrar na partilha de lucros",
  "Instant payouts funds": "Fundos de pagamentos instantâneos",
  "Unlimited Automated e-License Agreements":
    "Acordos de licença eletrônica automatizados ilimitados",
  "Collaborations with Revenue Splits": "Colaborações com divisões de receita",
  "Discounts Offers": "Ofertas de descontos",
  "Custom Voice Tag": "Etiqueta de voz personalizada",
  "Sales Analytics": "Análises de vendas",
  "Accept Offers & Negotiate": "Aceitar ofertas e negociar",
  "Schedule Uploads": "Agendar uploads",
  "Free Demo Downloads": "Downloads de demonstrações gratuitas",
  "Upload Up to 10 Trackout Stems (.zip) Files":
    "Carregar até 10 arquivos de faixas separadas (.zip)",
  "Only 10 Trackout Restrictions for Free Users":
    "Apenas 10 restrições de faixas separadas para usuários gratuitos",
  "Upload Unlimited Trackout Stems (.zip) Files":
    "Carregar arquivos de faixas separadas (.zip) ilimitados",
  "Up to 20 Private Monthly Messages": "Até 20 mensagens privadas mensais",
  "Up to 5 Private Monthly Messages": "Até 5 mensagens privadas mensais",
  "Only 5 Monthly Messages Restrictions for Free Users":
    "Apenas 5 restrições de mensagens mensais para usuários gratuitos",
  "Accept Credit Card and Paypal": "Aceitar cartão de crédito e Paypal",
  "Upload Up to 10 Tracks": "Carregar até 10 faixas",
  "Only 10 Tracks Restrictions for Free Users":
    "Apenas 10 restrições de faixas para usuários gratuitos",
  "Upload Unlimited Tracks": "Carregar faixas ilimitadas",
  "Coupon Codes": "Códigos de cupom",
  "Limited-Time Only": "Apenas por tempo limitado",
  "Sales Automation": "Automatização de vendas",
  "Keep 100% your revenue": "Mantenha 100% da Receita de Vendas",
  "Keep 90% your revenue": "Mantenha 90% da Receita de Vendas",
  "Platform Fee Waive On Free Plans":
    "Isenção de taxas de plataforma em planos gratuitos",
  "MP3 Custom Voice Tag ( Audio Tag for Streaming ) Restrictions for Free Users":
    "Restrições de etiqueta de voz personalizada em MP3 (etiqueta de áudio para streaming) para usuários gratuitos",
  "Sales & Premium Analytics": "Análises de vendas e premium",
  "Upload 10 Sound Kits (.zip) Files": "Carregar 10 kit de sons (.zip)",
  "Exclusive Tracks Link Sharing":
    "Compartilhamento de links de faixas exclusivas",
  All: "Todo",
  "Add ": "Adicionar ",
  useForMusicRecording: "Usar para gravação de música",
  dontUse: "Não usar",
  use: "Usar",
  upToFreeDownloads: "Até 0 downloads gratuitos",
  unlimitedFreeDownloads: "Downloads gratuitos ilimitados",
  allowed: "Permitido",
  notAllow: "Não permitido",
  UpTo: "Até 0",
  allowedBroadcastingRights: "Direitos de transmissão permitidos",
  notAllowBroadcastingRights: "Não permitir direitos de transmissão",
  others: "Outros",
  upload: "Carregar",
  shareLinkPlaylist: "Compartilhar Link da Lista de Reprodução",
  searchItem: "Procurar Item",
  searchCountry: "Buscar País",
  proOption: "PRO (Opcional)",
  publisherIpi: "Editor IPI (Opcional)",
  selectProSociety: "Selecionar Sociedade PRO",
  enterIpi: "Digite o IPI",
  requireDownloadersTo: "Exigir que os Baixadores",
  freeDownloadUsagePolicy: "Política de Uso de Download Gratuito",
  pleaseNoteFreeDownload:
    "Por favor, note que os downloads gratuitos são destinados apenas para uso sem fins lucrativos. Isso significa que não há permissão para distribuição, apresentações públicas ou monetização. Desfrute com responsabilidade!",
  useTrackCommercially:
    "Para utilizar esta faixa comercialmente, você precisará de uma licença do produtor. Você pode comprar uma diretamente onde obteve a versão gratuita, se estiver disponível.",
  disagree: "Discordar",
  agree: "Concordar",
  followForAFreeDownload: "Siga para um download gratuito",
  desFollowGetDownload:
    "Toque em 'Seguir' para obter seu download gratuito! Uma vez que você esteja seguindo o criador, o arquivo será todo seu.",
  sortBy: "Sort By",
  proSocietyIsRequired: "Por favor, inclua o seu IPI com a seleção PRO.",
  minIpi: "IPI mínimo 9 caracteres",
  maxIpi: "IPI máximo 11 caracteres",
  referralProgram: "Programa de Indicação",
  referrals: "Indicações",
  totalReach: "Alcance Total",
  totalUsersReferred: "Usuários Totais Indicados",
  totalSuccessfullReferrals: "Total de Indicações Bem-Sucedidas",
  referralsConverted: "Indicações que se Converteram",
  pendingInvites: "Convites Pendentes",
  inviteYourFriendsEmail: "Convide seus amigos por e-mail",
  doubleTheBenefits: "Duplique os Benefícios: Indique e Ganhe!",
  invite: "Convidar",
  desSharingYourReferralLink:
    "Compartilhe seu link de indicação para oferecer aos seus amigos um desconto de 5% em sua primeira assinatura anual. Se eles o utilizarem, também iremos recompensá-lo com uma comissão de $5!",
  criteriaNewReferrals: "Critérios para Novas Indicações",
  uniqueNewSubscriber: "1. Novo Assinante Único:",
  desUniqueNewSubscriber:
    "Cada assinante indicado deve ter um endereço de e-mail único e não pode ter uma conta pré-existente no Superprodutor.",
  noSelfReferral: "2. Sem Autoreferência:",
  desNoSelfReferral:
    "Os usuários não podem se referir a si mesmos; os detalhes do referente e do referido devem ser distintos.",
  successfullPayment: "3. Pagamento Bem-Sucedido:",
  desSuccessfullPayment:
    "A indicação só é validada quando o novo assinante efetua um pagamento bem-sucedido pela sua primeira assinatura anual.",
  validReferralLink: "4. Link de Indicação Válido:",
  desValidReferralLink:
    "Os indicados devem usar o link exclusivo fornecido por um usuário existente do Superproductor para se cadastrarem.",
  activeAccount: "5. Conta Ativa:",
  desActiveAccount:
    "O novo assinante deve mostrar atividade (por exemplo, fazer login, usar recursos) nos primeiros 30 dias após o registro.",
  termsOfService: "Termos de Serviço",
  shareThroughSocialMedia: "Compartilhar nas redes sociais ",
  yourShareLink: "Seu link para compartilhar",
  commissionForYou:
    "Comissões de $5 para você, 5% de desconto para novos assinantes em sua primeira assinatura anual.",
  recentReferrals: "Indicações Recentes",
  users: "Usuários",
  comissionsEarn: "Comissões Ganhas",
  emailAdressIsRequired: "O endereço de e-mail é um campo obrigatório",
  emailAdressIsNotValid: "O endereço de e-mail não é válido",
  sendLinkReferralSuccess: "Enviar link de indicação com sucesso",
  noRecentReferrals: "Sem Indicações Recentes",
  licenseStatus: "Estado da Licença",
  expireOn: "Expira em",
  indefinitely: "Indefinidamente",
  monthlyReferrals:
    "📆 Indicações Mensais: Indique amigos e ganhe um teste gratuito de 1 mês.",
  annualReferrals:
    "🎉 Indicações Anuais: Indique amigos para aproveitar uma taxa anual especial de $199.99 (apenas $16.67 por mês). Como o indicador, você ganhará $10 em créditos por indicação, com limite de $200.",
  asTheReferrer:
    "Como o indicador, você ganha $5 em créditos para cada indicação bem-sucedida; lembre-se de que ainda se aplica uma comissão de 10% sobre as vendas.",
  commissionWaiverOnReferral:
    "Além disso, a isenção de 10% de comissão nas vendas por indicação é o toque final!",
  creditBalance: "Saldo de Crédito",
  enterEmailAddress: "Inserir endereço de email",
  unlockRewards: "Desbloquear Recompensas: Compartilhar & Receber!",
  dashboardOverview: "Visão Geral do Painel de Controle",
  monthlyActiveListeners: "Ouvintes Ativos Mensais",
  thanLastMonth: "do que no mês passado",
  dateJoined: "Data de adesão",
  totalCustomers: "Clientes Totais",
  totalRefers: "Referências Totais",
  totalRevenue: "Receita Totais",
  totalItems: "Itens Totais",
  lastLoggedInOn: "Último acesso em ",
  quickAnalytics: "Análise Rápida: ",
  yourDataAtAGlance: "Seus Dados em um Relance",
  repost: "Repost",
  newFollowers: "Novos Seguidores",
  upcomingLicenseExpiry: "Monitoramento de Licenças Vendidas",
  search: "Pesquisar",
  sendReminder: "Enviar Lembrete",
  totalFavorites: "Favoritos Totais",
  totalRepost: "Reposts Totais",
  notificationLicenseExpiry: "Sua licença para ",
  isAboutToExpire: "está prestes a expirar.",
  pleaseRenewToContinue:
    " Por favor, renove-a para continuar desfrutando do produto.",
  sendReminderSuccess: "Lembrete Enviado com Sucesso",
  noUpcomingLicenseExpiry: "Sem Próximo Vencimento da Licença",
  freeDownloadButton: "Download grátis",
  audioFilesWav: "Arquivos de áudio para WAV download",
  pending: "Pendente",
  last30Days: "Últimos 30 dias",
  last90Days: "Últimos 90 dias",
  last365Days: "Últimos 365 dias",
  last730Days: "Últimos 730 dias",
  theSaferEasierWayToPay: "A maneira mais segura e fácil de pagar",
  desDiconnectStripe:
    "Desconectar sua conta do Stripe impedirá que você receba pagamentos e ofereça serviços e produtos em nosso site",
  trackNo: "Track no",
  licenseAndContract: "Licença & Contrato",
  dateReleased: "Data de lançamento",
  fileDelivery: "Entrega de arquivo",
  actions: "Ações",
  packNo: "Pack no",
  soundPackType: "Tipo de Kit de Sonido",
  playlistNo: "Playlist no",
  playlistStatus: "Estado da lista de reprodução",
  playlistData: "Dados da lista de reprodução",
  dateAdded: "Data de adição",
  trackAvailability: "Disponibilidade de Rastreamento",
  chooseYourLicense: "Escolha sua licença",
  uploadTrackFiles: "Enviar Arquivos de Faixa",
  autoTagged: "Etiquetado automático",
  customTagged: "Etiquetado personalizado",
  trackoutStems: "Stems de faixa",
  reviewFiles: "Revisar arquivos",
  previousStep: "Etapa anterior",
  nextStep: "Próxima etapa",
  selectAFile: "Selecionar um arquivo",
  uploadGuidelinesForUntaggedWavFiles:
    "Carregar diretrizes para arquivos WAV não etiquetados",
  forOptimalQualityUploadUntagged:
    "Para obter qualidade ótima, faça o upload de arquivos WAV não etiquetados em 44,1 kHz/24 bits ou superior. Basta arrastar seu arquivo para a seção de áudio não etiquetado. (O tamanho do arquivo WAV não deve exceder 164 MB).",
  uploadingWavFileHereAutomatically:
    "Fazer upload de um arquivo WAV aqui gera automaticamente uma versão em mp3 com uma tag 'Superprodutor' para proteção da faixa.",
  uploadGuidelinesForUntaggedMp3Files:
    "Diretrizes de upload para arquivos MP3 etiquetados",
  forOptimalPerformanceUploadYourMp3:
    "Para um desempenho ideal, faça o upload do seu MP3 com etiquetas personalizadas (recomendado 128kbps, 44.1kHz) (O tamanho do arquivo MP3 não deve exceder 164 MB).",
  thisFileWillBeAvailableForStreaming:
    "Este arquivo estará disponível para uso em streaming ou download gratuito, se oferecido. Caso contrário, será aplicada a etiqueta padrão Superprodutor.",
  uploadGuidelinesForTrackoutZipFiles:
    "Diretrizes de upload para arquivos ZIP de stems de faixa",
  uploadZipFileOfYourTrackStems:
    "Faça o upload de um arquivo ZIP (ZIP com no máximo 1 GB) dos stems da sua faixa. Embora seja opcional, os stems são frequentemente esperados para licenças profissionais ou acordos exclusivos.",
  includingThemCanSignificantlyIncrease:
    "Incluí-los pode aumentar significativamente o apelo da sua faixa para potenciais compradores.",
  proceedAddTrack: "Prossiga para Adicionar Faixa",
  wavOrMp3FilesSizeNoMoreLargerThan:
    "( Arquivos WAV com tamanho não superior a 164 MB )",
  mp3FileSizeNoMoreLargerThan:
    "( Tamanho do arquivo MP3 não superior a 164 MB )",
  zipFileSizeNoMoreLargerThan: "Tamanho do arquivo ZIP não superior a 2 GB",
  minimunMegabyte:
    "Por favor, selecione um áudio que tenha tamanho inferior a 164 MB",
  minimunGigabyte:
    "Por favor, selecione um áudio que tenha tamanho inferior a 2 GB",
  uploadReady: "Faixas carregadas com sucesso",
  goToEditTrack: "Prosseguir para editar a faixa",
  proceedToEdit: "Prossiga para Editar",
  fileSelectionNeededForNextTrack:
    "Seleção de arquivo necessária para a próxima faixa",
  stepIsCrucialToAchieve:
    "Este passo é crucial para alcançar uma correspondência perfeita entre os arquivos entregues e seus contratos.",
  readyToFineTuneYourTrack:
    "Pronto para aperfeiçoar suas faixas? Edite os detalhes da faixa para um lançamento perfeito.",
  wavFilesAutoConverted:
    "Arquivos WAV convertidos automaticamente em cópias MP3 de 320 kbps",
  weAutomaticallyConvertYourWavFilesToMp3:
    "Convertemos automaticamente seus arquivos WAV para MP3 de 320 kbps, garantindo áudio de alta qualidade tanto para entrega quanto para transmissão. Isso garante que sua música esteja pronta para impressionar, sem nenhum esforço extra de sua parte.",
  mp3StreamingTracksTagAutoGenerated:
    "Tags de faixas de streaming MP3 geradas automaticamente",
  autoGeneratedMp3TagsSimplifyYourTrack:
    "As tags MP3 geradas automaticamente simplificam a transmissão das suas faixas e aumentam a segurança. Sua música está pronta para ser descoberta e apreciada em nossas plataformas por ouvintes em todo o mundo.",
  yourOfferIsLowerThanMinimumOfferPrice:
    "Sua oferta é menor que o preço mínimo de oferta",
  resumeEditingYourTrackAwait: "Continuar a editar: Suas faixas aguardam você",
  desResumeEditingYourTrackAwait:
    "Faixas adicionais estão aguardando para serem editadas. Por favor, vá para 'Rever seus Envios'; suas faixas estão mais próximas de alcançar sua audiência.",
  reviewYourUploads: "Reveja seus Envios",
  enabled: "Ativado",
  highlight: "Realçar",
  popular: "POPULAR",
  quickStepNeeded: "Passo rápido necessário! 👍 ",
  toAddItemsToYourCart:
    "Para adicionar itens ao seu carrinho, é necessário um login rápido ou configuração de conta. Isso ajuda a manter sua compra segura e personalizada. ",
  shoppingContinue:
    "Não se preocupe, é rápido e você voltará imediatamente a esta página para continuar sua jornada de compras conosco.",
  alreadyHaveAnAccount: "Já tem uma conta?",
  verificationCode: "Código de Verificação",
  pleaseEnterYourConfirmationWeSentViaEmail:
    "Por favor, insira a confirmação que enviamos por e-mail",
  promoCodeAccepted: "Código promocional aceito!",
  desPromoCodeAccepted:
    "Aplicamos o seu código promocional. Seu desconto está refletido no total. Pronto para fazer seu pedido?",
  share: "Compartilhar",
  like: "Curtir",
  goToTrack: "Ir para a Pista",
  goToProfile: "Ir para o Perfil",
  unlike: "Descurtir",
  buy: "Comprar",
  goToSoundPack: "Ir para o Sound Kit",
  coverArt: "Capa",
  badges: "Distintivos",
  paymentsActive: "Pagamentos Ativos",
  hotProducer: "Produtor em Alta",
  spVerified: "SP Verificado",
  searchByArtistSpotify: "Buscar por Artista no Spotify",
  searchByTrackSpotify: "Buscar por Faixa",
  artistWhoIWorkWith: "Artista com quem trabalho",
  artists: "Artistas",
  expertise: "Perícia",
  daw: "DAW",
  accountSettings: "Configurações da Conta",
  currentPassword: "Senha Atual",
  newPassword: "Nova Senha",
  confirmNewPassword: "Confirmar Nova Senha",
  passwordMustBeAtLeastCharacters: "A senha deve ter pelo menos 8 caracteres",
  passwordDoesNotMatch: "A senha não coincide",
  logoutAllDevices: "Desconectar de todos os dispositivos",
  Connect: "Conectar",
  searchListMailChimp: "Pesquisar lista mailchimp",
  linkedAccounts: "Complementos de Marketing de Terceiros",
  emailCapture: "Captura de e-mail",
  inputYourEmailAddressAndFullName:
    "Insira seu endereço de e-mail e nome completo",
  weWillDeliverYourFreeTrackToTheEmailAddress:
    "Entregaremos sua faixa gratuita imediatamente. Obrigado!",
  emailAddress: "Endereço de e-mail",
  provideEmailAddress: "Forneça endereço de e-mail",
  provideName: "Forneça nome",
  fullName: "Nome completo",
  continueUploadMoreTracks: "Continuar carregando mais faixas",
  startOverWithNewUpload: "Recomeçar com novo upload",
  bulkTrackUpload: "Upload em massa de faixas",
  lastName: "Sobrenome",
  provideLastName: "Forneça o Sobrenome",
  customers: "Clientes",
  myCustomers: "Meus Clientes",
  type: "Tipo",
  downloadDate: "Data de Download",
  downloadedItem: "Item Baixado",
  downloadOn: "Baixado em",
  searchEmail: "Pesquisar Email",
  deleted: "Excluído",
  recentCustomerActivity: "Atividade Recente do Cliente",
  startNowDayTrial: "Comece Agora o Teste de 7 Dias",
  deactivateTrial: "Desativar Teste",
  upgradeYourPlanToContinue: "Atualize seu plano para continuar",
  featureIsLocked:
    "Este recurso está bloqueado—Atualize seu plano para obter acesso",
  upgradeYourPlan: "Atualize seu plano",
  titleUpgradeYourPlanToEnableFreeDownload:
    "Atualize seu plano para habilitar o download gratuito",
  desUpgradeYourPlanToEnableFreeDownload:
    "Olá! Para habilitar as opções de download gratuito, por favor atualize seu plano",
  titleUpgradeYourPlanAddCollaborator:
    "Atualize seu plano para adicionar um colaborador",
  desUpgradeYourPlanAddCollaborator:
    "Olá! Para adicionar um colaborador, por favor atualize seu plano",
  titleUpgradeYourPlanChooseAContractExclusive:
    "Atualize seu plano para escolher um contrato exclusivo",
  desUpgradeYourPlanChooseAContractExclusive:
    "Olá! Para escolher um contrato exclusivo, por favor atualize seu plano",
  chooseYourFeeStructure: "Escolha sua estrutura de taxas",
  agreedToCoverFee: "Concordei em cobrir a taxa de 10%",
  buyerCoverFee: "O comprador cobre a taxa de 10%",
  joinNow: "Junte-se agora!",
  selectChooseYourFeeStructure: "Selecione Escolha Sua Estrutura de Taxas",
  totalService: "Taxa de serviço",
};
