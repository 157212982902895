import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import IconCreateConversation from "app/assets/icons/IconCreateConversation";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { planType } from "constants/planType";
import { useToggle } from "hooks/useToggle";
import queryString from "query-string";
import {
  default as React,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useConversation } from "../../hooks/useConversations";
import ConversationDetail from "../ChatWeb";
import EmptyConversation from "../EmptyConversation";
import ModalCreateConversation from "../ModalCreateConversation";
import ConversationItem from "./conversationsItem";
import SkeletonMessagesPage from "./skeletonMessagesPage";
import "./styles.scss";

import { Back } from "app/assets/icons";
import Skeleton from "app/components/common/Skeleton/skeleton";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserAdmin } from "app/redux/selectors/users";

export default function ItemSelectMessages() {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const [showChatMobile, setShowChatMobile] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const isMobileDevice = () => {
    return window.innerWidth <= 767;
  };

  const tabMessages = queryString.parse(location.search);
  const currentUser = useSelector(selectUserAdmin);

  const checkPlanSubscription = useMemo(
    () => currentUser?.subscriptionType === planType.MAINSTREAM,
    [currentUser?.subscriptionType]
  );

  const [openCreateConversation, setOpenCreateConversation] = useToggle();
  const {
    allConversations,
    selectAllCheckbox,
    handleCheckbox,
    handleSelectAll,
    onDeleteConversation,
    getAllMsgCurrentUser,
    totalMsg,
    loading,
  } = useConversation();

  useEffect(() => {
    getAllMsgCurrentUser();
  }, [getAllMsgCurrentUser]);

  const handleShowChatMobile = () => {
    setShowChatMobile(true);
  };

  const unReadMessages = allConversations?.filter(
    (item) => item.readByMe !== true
  );

  const listTabsMessages = [
    {
      name: `${t("All")} (${allConversations?.length || 0})`,
      id: 0,
    },
    {
      name: `${t("unRead")} (${unReadMessages?.length || 0})`,
      id: 1,
    },
  ];

  const tabName = tabIndex === 0 ? "unread" : "all";

  const handleChangeTab = useCallback(
    (index) => {
      if (tabIndex === 0) {
        history.push(`/messages?tab=${tabName}`);
        setTabIndex(index);
      } else {
        history.push(`/messages?tab=${tabName}`);
        setTabIndex(index);
      }
    },
    [history, tabIndex, tabName]
  );

  useEffect(() => {
    tabMessages.tab === "all" ? setTabIndex(0) : setTabIndex(1);
  }, [tabMessages.tab]);

  const handleCreateMsg = useCallback(() => {
    getAllMsgCurrentUser();
    if (!checkPlanSubscription && totalMsg.length < 5) {
      setOpenCreateConversation();
    } else if (checkPlanSubscription) {
      setOpenCreateConversation();
    } else {
      ToastPlanSubscription({
        title: t("messagesLimitReached"),
        description: !checkPlanSubscription
          ? t("descriptionFiveMessagesLimitReached")
          : t("descriptionTwentyMessagesLimitReached"),
      });
    }
  }, [
    checkPlanSubscription,
    getAllMsgCurrentUser,
    setOpenCreateConversation,
    t,
    totalMsg.length,
  ]);

  const redirectBack = useCallback(() => {
    if (isMobileDevice()) {
      history.push("/messages?tab=all");
      setShowChatMobile(false);
      return;
    }
    history.push("/home");
  }, [history]);

  return (
    <>
      <Flex alignItems="center" mb="32px">
        <Box cursor="pointer" className="vector-button" onClick={redirectBack}>
          <Back />
        </Box>
        <Text fontSize={{ base: "28px", md: "32px" }}>{t("messages")}</Text>
      </Flex>
      <Box
        className="left-conversations-item"
        w="100%"
        mt={{ base: "90px", md: "0px" }}
        display={showChatMobile ? "none" : "block"}
      >
        <Tabs
          index={tabIndex}
          className="tabs-panels"
          variant="unstyled"
          onChange={handleChangeTab}
        >
          <TabList overflow="auto" className="tab-list-panels">
            {listTabsMessages.map((item) => (
              <Tab
                className="tab"
                _selected={{ boxShadow: "none" }}
                key={item.id}
              >
                <Flex w="100%" justifyContent="space-between">
                  <Text ml="55px">{item.name} </Text>
                  {item.id === 0 && (
                    <Box
                      height="25px"
                      borderLeft="1px solid rgba(255, 255, 255, 0.2)"
                    />
                  )}
                </Flex>
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            <TabPanel
              padding="0px"
              pt={{ base: "24px", md: "28px", xl: "32px" }}
            >
              {loading ? (
                <SkeletonMessagesPage />
              ) : (allConversations || [])?.length > 0 ? (
                <>
                  <Flex gap="30px">
                    <Box
                      w={{
                        base: "100%",
                        md: !!tabMessages.room ? "60%" : "100%",
                      }}
                    >
                      <Box maxHeight="560px" overflowY="auto">
                        {allConversations?.map((item) => (
                          <ConversationItem
                            valueCheckBox={selectAllCheckbox}
                            handleCheckBox={handleCheckbox}
                            conversation={item}
                            key={item.id}
                            handleShowChatMobile={handleShowChatMobile}
                          />
                        ))}
                      </Box>
                      {(allConversations || [])?.length > 0 && (
                        <Flex
                          gap={{ md: "30px", xl: "42px" }}
                          direction={{ base: "row", md: "column" }}
                          mt={{ base: "24px", md: "28px", xl: "32px" }}
                          alignItems={{ base: "center", md: "unset" }}
                          justifyContent={{
                            base: "space-between",
                            md: "unset",
                          }}
                        >
                          <Text
                            fontSize="16px"
                            lineHeight="16px"
                            fontWeight="400"
                            color="#D4F70E"
                            onClick={handleSelectAll}
                            cursor="pointer"
                          >
                            {t("selectAll")}
                          </Text>
                          <Button
                            backgroundColor="rgba(212, 247, 14, 0.1)"
                            borderRadius="4px"
                            border="1px solid #D4F70E"
                            color="#D4F70E"
                            _hover={{ background: "rgba(212, 247, 14, 0.1)" }}
                            _active={{ background: "#D4F70E" }}
                            w="100px"
                            onClick={onDeleteConversation}
                          >
                            {t("remove")}
                          </Button>
                        </Flex>
                      )}
                    </Box>

                    {!!tabMessages.room && (
                      <Box w="40%" display={{ base: "none", md: "block" }}>
                        <ConversationDetail />
                      </Box>
                    )}
                  </Flex>
                </>
              ) : (
                <EmptyConversation />
              )}
            </TabPanel>
            <TabPanel
              padding="0px"
              pt={{ base: "24px", md: "28px", xl: "32px" }}
            >
              {loading ? (
                <SkeletonMessagesPage />
              ) : (unReadMessages || [])?.length > 0 ? (
                <Flex>
                  <Box w="100%" maxHeight="560px" overflowY="auto">
                    {unReadMessages?.map((item) => (
                      <ConversationItem conversation={item} key={item.id} />
                    ))}
                  </Box>
                </Flex>
              ) : (
                <EmptyConversation />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Box
          position="absolute"
          top={{ base: "87px", md: "30px", xl: "40x" }}
          right={{ base: "16px", md: "30px", xl: "80x" }}
          left={{ base: "16px", md: "unset" }}
        >
          {loading ? (
            <Skeleton w="198px" h="48px" borderRadius="6px" />
          ) : (
            <Button
              w="100%"
              background="rgba(219, 243, 82, 0.1)"
              color="#D4F70E"
              border="1px solid #D4F70E"
              borderRadius="4px"
              _active={{ background: "#D4F70E" }}
              padding="20px"
              _hover={{ background: "none" }}
              leftIcon={<IconCreateConversation />}
              size="lg"
              fontSize={{ base: "16px", md: "18px" }}
              onClick={handleCreateMsg}
            >
              {t("newConversations")}
            </Button>
          )}
        </Box>

        <ModalCreateConversation
          showModal={openCreateConversation}
          setShowModal={setOpenCreateConversation}
        />
      </Box>
      <Box>{showChatMobile && <ConversationDetail />}</Box>
    </>
  );
}
