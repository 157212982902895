import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import { Licenses, TrackSoundKit } from "app/models";
import {
  deleteSoundkitDraft,
  deleteSoundkitRelease,
  prepareEditSoundkit,
} from "app/redux/actions/addSoundkit";
import {
  deleteTrackDraft,
  deleteTrackRelease,
} from "app/redux/actions/myTrack";
import {
  checkIsPlayPlaylists,
  checkPlaylistIdPlaying,
} from "app/redux/actions/playlist";
import { getLyrics, prepareEditTrack } from "app/redux/actions/tracks";
import { selectorIsLoadingDelete } from "app/redux/selectors/myTrack";
import { isLoadingSelector } from "app/redux/selectors/status";
import { PROFILE_TYPE } from "constants/contractType";
import { TRACK_STATE } from "constants/index";
import { planType } from "constants/planType";
import { TRACK_TYPE } from "constants/trackTypes";
import saveAs from "file-saver";
import { usePlayers } from "hooks/player/usePlayers";
import { t } from "i18next";
import JSZip from "jszip";
import { default as React, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { RootState } from "types";
import { timeFromNow } from "utils/timeAgo";
import BackgroundDrop from "../BackgroundDrop";
import "./styles.scss";
import IconCalendarMobileMyTrack from "app/assets/icons/IconCalendarMobileMyTrack";
import { BsThreeDotsVertical } from "react-icons/bs";
import { selectUserAdmin } from "app/redux/selectors/users";

interface MusicCardMyTrackMobileProps {
  item: TrackSoundKit;
  index: number;
  handleCheckbox?: (id: string) => void;
  handleCheckboxSK?: (id: string) => void;
  selectCheckbox?: string[];
  selectCheckboxSK?: string[];
  isSK?: boolean;
  isMyTrackRelease?: boolean;
  isPlaylists?: boolean;
  isDraft?: boolean;
  setShowModalLinkExpiration?: (track: TrackSoundKit) => void;
}

export default function MusicCardMyTrackMobile({
  item: track,
  isMyTrackRelease = false,
  isPlaylists = false,
  isDraft = false,
  isSK = false,
  index,
  handleCheckbox = () => {},
  handleCheckboxSK = () => {},
  selectCheckbox = [],
  selectCheckboxSK = [],
  setShowModalLinkExpiration = () => {},
}: MusicCardMyTrackMobileProps) {
  const {
    playingTrackId,
    handlePlayOrPause,
    handleTogglePlayPause,
    playlistId,
    isPlayAllPlaylist,
    handlePlayPlaylists,
  } = usePlayers();
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalEditDelete, setModalEditDelete] = useBoolean();
  const { path } = useRouteMatch();
  const isLoadingDelete = useSelector(selectorIsLoadingDelete);
  const currentUser = useSelector(selectUserAdmin);

  const onClickToTrack = useCallback(() => {
    if (!track?.mergeAudio && !track.audioFileUrl) {
      return;
    }
    if (!isPlayAllPlaylist && !isPlaylists) {
      handlePlayPlaylists([]);
    }
    if (playlistId) {
      dispatch(checkIsPlayPlaylists(false));
      dispatch(checkPlaylistIdPlaying(playlistId));
    } else {
      dispatch(checkIsPlayPlaylists(true));
      dispatch(checkPlaylistIdPlaying(""));
    }
    if (playingTrackId !== track?._id) {
      handlePlayOrPause(track);
    } else {
      handleTogglePlayPause();
    }
  }, [
    dispatch,
    handlePlayOrPause,
    handlePlayPlaylists,
    handleTogglePlayPause,
    isPlayAllPlaylist,
    isPlaylists,
    playlistId,
    playingTrackId,
    track,
  ]);

  const handlePlayTrackSoundkit = useCallback(() => {
    !isDraft && onClickToTrack();
  }, [isDraft, onClickToTrack]);

  const isLoadingDeleteSKRelease = useSelector((state: RootState) =>
    isLoadingSelector([deleteSoundkitRelease.typePrefix], state)
  );
  const isLoadingDeleteSKDraft = useSelector((state: RootState) =>
    isLoadingSelector([deleteSoundkitDraft.typePrefix], state)
  );
  const checkPlanSubscription = useMemo(
    () => currentUser?.subscriptionType === planType.MAINSTREAM,
    [currentUser?.subscriptionType]
  );
  const [selectedTrackId, setSelectedTrackId] = useState("");
  const licensesName = useMemo(
    () => (track?.licenses || []).map((license: Licenses) => license?.name),
    [track]
  );
  const isExclusive = useMemo(
    () => (track?.licenses || []).every((license) => license.exclusive),
    [track]
  );

  const handleEditTrackSoundkit = useCallback(() => {
    if (track?.isSK) {
      dispatch(prepareEditSoundkit(track));
      history.push({
        pathname: "/add-soundkit",
        search: "type=isEdit",
      });
      return;
    } else {
      dispatch(getLyrics({ trackId: track?._id }));
      history.push({
        pathname: "/add-track",
        search: "type=isEdit",
      });
      dispatch(prepareEditTrack(track));
    }
  }, [dispatch, history, track]);

  const handleDeleteSoundkit = useCallback(() => {
    setModalEditDelete.off();
    setSelectedTrackId(track?._id);
    dispatch(deleteSoundkitRelease(track?._id));
  }, [dispatch, setModalEditDelete, track?._id]);

  const handleTrackDetail = useCallback(
    (e) => {
      e.stopPropagation();
      if (isSK) {
        history.push(`/soundkit/${track?.slug}`);
        return;
      }
      history.push(`/track/${track?.slug}`);
    },
    [history, isSK, track?.slug]
  );

  const redirectProfile = useCallback(
    (e, name) => {
      const checkParams = path.split("/")[1];
      e.stopPropagation();
      if (checkParams === "user") {
        history.push(`${name}?profileType=${PROFILE_TYPE.TRACKS}`);
        return;
      }
      history.push(`/user/${name}?profileType=${PROFILE_TYPE.TRACKS}`);
    },
    [history, path]
  );

  const handleDeleteTrack = useCallback(() => {
    setModalEditDelete.off();
    setSelectedTrackId(track?._id);
    if (track?.isDraft) {
      dispatch(deleteTrackDraft(track?._id));
      return;
    }
    dispatch(deleteTrackRelease(track?._id));
  }, [dispatch, setModalEditDelete, track?._id, track?.isDraft]);

  const checkS3Amazon = useCallback((link?: string) => {
    if (link) {
      return link?.includes("amazonaws.com");
    }
  }, []);

  const fileDownload = useMemo(() => {
    return [
      checkS3Amazon(track?.untaggedFileUrl) ? "" : track?.untaggedFileUrl,
      track?.untaggedFileUrlWav,
      track?.trackStemsUrl,
      track?.taggedFileUrl,
    ];
  }, [
    checkS3Amazon,
    track?.taggedFileUrl,
    track?.trackStemsUrl,
    track?.untaggedFileUrl,
    track?.untaggedFileUrlWav,
  ]);

  const trackCollaboration = useMemo(() => {
    return track.trackState === TRACK_STATE.PENDING && !track.isDraft;
  }, [track.isDraft, track.trackState]);

  const handleShowLinkExpiration = useCallback(() => {
    setModalEditDelete.off();
    setShowModalLinkExpiration(track);
  }, [setModalEditDelete, setShowModalLinkExpiration, track]);

  const handleDownloadFiles = useCallback(async () => {
    const zip = new JSZip();
    const promises = (fileDownload || []).map((url, index) => {
      if (url) {
        const lastTailMatch = url.match(/\.([^.?]+)(\?.*)?$/i);
        const lastTail = lastTailMatch ? lastTailMatch[1] : null;
        return fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.blob();
          })
          .then((data) => {
            zip.file(`${track?.title}${index + 1}.${lastTail}`, data);
          })
          .catch((error) => {
            console.error(`Error fetching data for file ${index + 1}:`, error);
          });
      }
    });
    await Promise.all(promises);
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, `${track?.title}.zip`);
    });
    setModalEditDelete.off();
  }, [fileDownload, setModalEditDelete, track?.title]);

  return (
    <>
      <Flex
        w="100%"
        alignItems="center"
        padding="15px 0px 25px 0px"
        borderBottom="1px solid #212125"
      >
        <Flex w="15%">
          {!trackCollaboration && (
            <Checkbox
              borderRadius="4px"
              _focusVisible={{ outline: "none" }}
              size="lg"
              mr="12px"
              onChange={() =>
                !isSK
                  ? handleCheckbox(track._id ?? "")
                  : handleCheckboxSK(track._id ?? "")
              }
              isChecked={
                !isSK
                  ? !!selectCheckbox.find(
                      (value: string) => value === track?._id
                    )
                  : !!selectCheckboxSK.find(
                      (value: string) => value === track?._id
                    )
              }
            />
          )}
          <Text>{index + 1}</Text>
        </Flex>
        <Box w="75%">
          <Flex
            alignItems="center"
            onClick={handlePlayTrackSoundkit}
            overflow="hidden"
          >
            <Image
              w={"35px"}
              h={"36px"}
              src={track?.coverUrl}
              fallbackSrc={ImageDefault}
              borderRadius="4px"
              objectFit="cover"
              mr={{ base: "8px", md: "12px", xl: "20px" }}
            />

            <Box w="100%" overflow="hidden">
              {track?.title ? (
                <Text
                  onClick={handleTrackDetail}
                  fontSize="14px"
                  fontWeight="700"
                  color={"white"}
                  fontFamily="Cera Pro Bold"
                  noOfLines={1}
                >
                  {track?.title}
                </Text>
              ) : (
                <Text
                  fontSize="14px"
                  lineHeight="14px"
                  fontWeight="500"
                  color={"#ED3636"}
                >
                  {t("missingTrackTitle")}
                </Text>
              )}
              <Flex overflow="hidden" alignItems="center">
                {TRACK_TYPE[track?.type] && (
                  <Box
                    display="flex"
                    alignItems="center"
                    borderRadius="4px"
                    padding="2px 10px"
                    mr="5px"
                    backgroundColor="#2b2e1b"
                  >
                    <Text
                      w="max-content"
                      fontSize={{ base: "10px", md: "12px" }}
                      color="#d4f70e"
                    >
                      {t(TRACK_TYPE[track?.type])}
                    </Text>
                  </Box>
                )}
                <Text
                  noOfLines={1}
                  fontSize={{ base: "12px", md: "14px" }}
                  color="#464649"
                  _hover={{
                    textDecor: "underline",
                    color: "white",
                  }}
                  cursor="pointer"
                  fontFamily="Cera Pro Regular"
                  onClick={(e) => redirectProfile(e, track?.createdBy?.name)}
                >
                  {`${t("by")} ${track?.createdBy?.name} `}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex mt="15px" gap="10px">
            <Flex
              alignItems="center"
              background="#2b2e1b"
              padding="2px 8px"
              borderRadius="4px"
            >
              <IconCalendarMobileMyTrack />
              <Text
                color="#d4f70e"
                fontSize="12px"
                ml="3px"
                mt="2px"
                w="max-content"
              >
                {timeFromNow(track, "D MMM YYYY")}
              </Text>
            </Flex>

            <Flex
              alignItems="center"
              background="#2b2e1b"
              padding="2px 8px"
              borderRadius="4px"
            >
              <Text
                fontSize="12px"
                color="#d4f70e"
                fontFamily="Cera Pro Regular"
                w="max-content"
              >
                {licensesName?.length === 0
                  ? t("missingTrackLicenses")
                  : isExclusive
                  ? t("exclusiveContract")
                  : t("nonExclusiveContract")}
              </Text>
            </Flex>
          </Flex>
        </Box>

        <Flex justifyContent="end" w="10%">
          {modalEditDelete && <BackgroundDrop />}
          <Popover
            isOpen={modalEditDelete}
            closeOnBlur={true}
            onOpen={setModalEditDelete.on}
            onClose={setModalEditDelete.off}
          >
            <PopoverTrigger>
              <Box cursor="pointer">
                {(isLoadingDelete ||
                  isLoadingDeleteSKRelease ||
                  isLoadingDeleteSKDraft) &&
                track?._id === selectedTrackId ? (
                  <Spinner color="#d4f70e" />
                ) : (
                  <BsThreeDotsVertical />
                )}
              </Box>
            </PopoverTrigger>
            <Box zIndex={120}>
              <PopoverContent
                backgroundColor="#25252A"
                border="none"
                mt="5px"
                maxW="200px"
              >
                <PopoverArrow
                  backgroundColor="#25252A"
                  borderColor="#25252A "
                  boxShadow="unset !important"
                />

                <PopoverBody>
                  <Flex flexDirection="column">
                    <Button
                      backgroundColor="#25252A"
                      borderColor="#37383C"
                      padding="25px 0px"
                      mb="10px"
                      _hover={{
                        background: "#2D2E33",
                        border: "1px solid #37383C",
                      }}
                      onClick={handleEditTrackSoundkit}
                    >
                      {t("Edit")}
                    </Button>
                    {checkPlanSubscription && track?.private && (
                      <Button
                        backgroundColor="#25252A"
                        borderColor="#37383C"
                        padding="25px 0px"
                        mb="10px"
                        _hover={{
                          background: "#2D2E33",
                          border: "1px solid #37383C",
                        }}
                        onClick={handleShowLinkExpiration}
                      >
                        {t("sharePrivately")}
                      </Button>
                    )}
                    {isMyTrackRelease && (
                      <Button
                        backgroundColor="#25252A"
                        borderColor="#37383C"
                        mb="10px"
                        padding="25px 0px"
                        _hover={{
                          background: "#2D2E33",
                          border: "1px solid #37383C",
                        }}
                        onClick={handleDownloadFiles}
                      >
                        {t("downloadFiles")}
                      </Button>
                    )}
                    <Button
                      color="red"
                      _hover={{
                        background: "#2C262A",
                        border: "1px solid #ED363680",
                      }}
                      padding="25px 0px"
                      borderColor="#ED363680"
                      backgroundColor="#25252A"
                      onClick={
                        track?.isSK ? handleDeleteSoundkit : handleDeleteTrack
                      }
                    >
                      {t("delete")}
                    </Button>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Box>
          </Popover>
        </Flex>
      </Flex>
    </>
  );
}
