import { createReducer } from "@reduxjs/toolkit";
import { Collaborator, User } from "app/models";
import * as actions from "app/redux/actions/addSoundkit";
import { releaseFormatDate } from "utils/timeAgo";

export interface AddSoundkitState {
  coverUrl: string;
  title: string;
  isDraft: boolean;
  type: string;
  basicPrice: number | undefined;
  mainGenre: string;
  subGenre: string;
  primaryMood?: string;
  secondaryMood?: string;
  tags: string[];
  description: string;
  releaseDate: undefined | string;
  slug?: undefined | string;
  private?: boolean;
  excludeFromBulkDiscounts: boolean;
  notForSale: boolean;
  collaborators?: Collaborator[];
  audioFileUrl: string;
  downloadFilesUrl: string;
  freeDownloadEnabled: string;
  _id?: string;
  isEdit?: boolean;
  requireDownloadSp?: boolean;
}
export interface InitSoundkitState {
  newSoundkit: AddSoundkitState;
  isUploadSoundPack: boolean;
}

const initialState: InitSoundkitState = {
  newSoundkit: {
    title: "",
    coverUrl: "",
    isDraft: false,
    type: "",
    basicPrice: undefined,
    mainGenre: "",
    subGenre: "",
    tags: [],
    description: "",
    slug: "",
    // NOTE: update me in pull request  dayjs
    releaseDate: "",
    excludeFromBulkDiscounts: false,
    private: false,
    notForSale: false,
    collaborators: [],
    audioFileUrl: "",
    downloadFilesUrl: "",
    freeDownloadEnabled: "",
    requireDownloadSp: false,
  },
  isUploadSoundPack: true,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.prepareEditSoundkit, (state, action) => {
      const {
        title,
        coverUrl,
        isDraft,
        type,
        basicPrice,
        mainGenre,
        subGenre,
        releaseDate,
        tags,
        description,
        slug,
        excludeFromBulkDiscounts,
        private: soundkitPrivate,
        notForSale,
        collaborators,
        audioFileUrl,
        downloadFilesUrl,
        freeDownloadEnabled,
        _id,
        requireDownloadSp,
      } = action.payload;

      return {
        ...state,
        newSoundkit: {
          title,
          coverUrl,
          isDraft,
          type,
          basicPrice,
          mainGenre,
          subGenre,
          releaseDate: releaseFormatDate(releaseDate),
          tags,
          description,
          slug,
          excludeFromBulkDiscounts,
          private: soundkitPrivate,
          notForSale,
          collaborators: (collaborators || []).map(
            (collaborator: Collaborator) => {
              return {
                name: (collaborator?.user as User)?.name,
                profitShare: collaborator?.profitShare,
                publishing: collaborator?.publishing,
                avatar: (collaborator?.user as User)?.profileImage,
                user: (collaborator?.user as User)?._id,
                role: collaborator?.role,
              };
            }
          ),
          audioFileUrl,
          downloadFilesUrl,
          freeDownloadEnabled,
          _id,
          requireDownloadSp,
        },
      };
    })
    .addCase(actions.submitSoundkit.fulfilled, () => ({
      ...initialState,
    }))
    .addCase(actions.clearFormSoundkit, (state) => ({
      ...state,
      newSoundkit: initialState.newSoundkit,
    }))
    .addCase(actions.checkUploadSoundPacks.fulfilled, (state, action) => ({
      ...state,
      isUploadSoundPack: action.payload,
    }))
);
