import { Box } from "@chakra-ui/react";
import { TrackSoundKit } from "app/models";
import InfiniteHits from "app/pages/Home/infiniteHits";
import { convertTrackKey } from "constants/trackKey";
import useTrackSelect from "hooks/track/useTrackSelect";
import React, { useCallback, useEffect, useState } from "react";
import ModalPriceTracks from "../ModalPriceTrack";
import ModalLyrics from "../ModalShowLyrics";
import MusicCard from "../MusicCard";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import ModalAddToPlaylist from "app/pages/Playlists/modalAddToPlaylist";
import ModalCreatePlaylist from "app/pages/Playlists/modalCreatePlaylist";
import {
  Configure,
  useInfiniteHits,
  useInstantSearch,
} from "react-instantsearch";
import EmptyTrackSoundkit from "../MySoundkits/emptyTrackSoundkit";
import SkeletonTrackItem from "../Skeleton/skeletonTrackItem";
import ModalShowFreeDownload from "../ModalShowFreeDownload";
import ModalShareLinkRecentActions from "app/pages/Home/modalShareLinkRecentActions";
import ModalShowFreeDownloadFormMailChimp from "../ModalShowFreeDownloadFormMailchimp";
import { useDispatch } from "react-redux";
import { listPlaylistsTrackSoundkitAlgolia } from "app/redux/actions/playlist";
import { useHistory } from "react-router-dom";

interface MyTrackProfileProps {
  userId: string | undefined;
}

function MyTrackProfile({ userId }: MyTrackProfileProps) {
  const {
    selectedTrack,
    onOpenOptionsModal,
    showLyrics,
    setShowLyrics,
    showOptionsModal,
    setShowOptionsModal,
    isEditLyrics,
    setIsEditLyrics,
    lyricsData,
    showModalFreeDownload,
    setShowModalFreeDownload,
    linkTitleDownload,
    handleFreeDownload,
    showModalShareLink,
    setShowModalShareLink,
    shortLink,
    loadingUrl,
    showModalAndGetdataShareLink,
    setLoadingUrl,
    setShortLink,
    handleShowModalFreeDownloadMailchimp,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
  } = useTrackSelect();
  const {
    trackId,
    setShowModalAddToPlaylist,
    showModalAddToPlaylist,
    handleShowModalAddTrack,
    showModalCreatePlaylist,
    setShowModalCreatePlaylist,
    playlists,
    handleSearchText,
    debouncedSearchText,
  } = usePlaylists();
  const { hits } = useInfiniteHits();
  const { status } = useInstantSearch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    dispatch(listPlaylistsTrackSoundkitAlgolia(hits));
  }, [dispatch, hits]);

  useEffect(() => {
    const unlisten = history.block((location) => {
      dispatch(listPlaylistsTrackSoundkitAlgolia([]));
    });
    return () => {
      unlisten();
    };
  }, [dispatch, history]);

  const Hit = useCallback(
    ({ hit, index }: TrackSoundKit) => {
      const convertTrack = convertTrackKey(hit);
      return (
        <MusicCard
          onOpenOptionsModal={onOpenOptionsModal}
          item={convertTrack}
          isProfile
          index={index}
          handleShowModalAddTrack={handleShowModalAddTrack}
          handleFreeDownload={handleFreeDownload}
          onOpenModalShareLink={showModalAndGetdataShareLink}
          setShortLinkUrl={setShortLink}
          setLoadingUrl={setLoadingUrl}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      );
    },
    [
      onOpenOptionsModal,
      handleShowModalAddTrack,
      handleFreeDownload,
      showModalAndGetdataShareLink,
      setShortLink,
      setLoadingUrl,
      handleShowModalFreeDownloadMailchimp,
    ]
  );

  const renderData = useCallback(() => {
    if (isLoading) {
      return <SkeletonTrackItem isProfile />;
    }
    if (
      hits?.length > 0 &&
      hits?.every((item) => item?.createdBy?._id === userId)
    ) {
      return <InfiniteHits hitComponent={Hit} />;
    }
    return ["loading", "stalled"].includes(status) ? (
      <SkeletonTrackItem isProfile />
    ) : (
      <EmptyTrackSoundkit />
    );
  }, [Hit, status, isLoading, userId, hits]);

  return (
    <Box paddingBottom="50px">
      <Configure hitsPerPage={15} filters={`createdBy._id:${userId}`} />
      {renderData()}
      {showOptionsModal && (
        <ModalPriceTracks
          selectedTrack={selectedTrack}
          showModal={showOptionsModal}
          setShowModal={setShowOptionsModal}
          handleFreeDownload={handleFreeDownload}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      )}
      {showLyrics && (
        <ModalLyrics
          showModal={showLyrics}
          setShowModal={setShowLyrics}
          selectedTrack={selectedTrack}
          onOpenOptionsModal={onOpenOptionsModal}
          isEditLyrics={isEditLyrics}
          setIsEditLyrics={setIsEditLyrics}
          lyricsData={lyricsData}
        />
      )}
      {showModalAddToPlaylist && (
        <ModalAddToPlaylist
          trackId={trackId}
          setShowModal={setShowModalAddToPlaylist}
          showModal={showModalAddToPlaylist}
          setShowModalCreatePlaylist={setShowModalCreatePlaylist}
          playlists={playlists}
          handleSearchText={handleSearchText}
          debouncedSearchText={debouncedSearchText}
        />
      )}
      {showModalCreatePlaylist && (
        <ModalCreatePlaylist
          showModal={showModalCreatePlaylist}
          setShowModal={setShowModalCreatePlaylist}
        />
      )}
      {showModalFreeDownload && (
        <ModalShowFreeDownload
          showModal={showModalFreeDownload}
          setShowModal={setShowModalFreeDownload}
          linkTitleFreeDownload={linkTitleDownload}
        />
      )}
      {showModalShareLink && (
        <ModalShareLinkRecentActions
          showModalShareLink={showModalShareLink}
          setShowModalShareLink={setShowModalShareLink}
          selectedTrackSoundkit={selectedTrack}
          shortLinkUrl={shortLink}
          loadingUrl={loadingUrl}
        />
      )}
      {showModalFreeMailchimp && (
        <ModalShowFreeDownloadFormMailChimp
          showModal={showModalFreeMailchimp}
          setShowModal={setShowModalFreeMailchimp}
          selectedTrack={selectedTrack}
          handleFreeDownload={handleFreeDownload}
        />
      )}
    </Box>
  );
}
export default React.memo(MyTrackProfile);
