import { Box } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { returnUrl } from "utils/paypalUrl";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkOffersSelector,
  cartLicensesSelector,
  cartPromoCodeSelector,
  shoppingCartSelector,
} from "app/redux/selectors/cart";
import { useHistory } from "react-router-dom";
import { capturePaypal } from "app/redux/actions/cart";
import { getIpAddress } from "utils/getData";
import Lottie from "lottie-react";
import transactionProcess from "../../../assets/LottieIAnimations/transactionProcessCheckout.json";

export default function CapturePaypal() {
  const listCart = useSelector(shoppingCartSelector);
  const cartLicenses = useSelector(cartLicensesSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const capturePaypalUrl = window.location.href;

  const bulkOffers = useSelector(bulkOffersSelector);
  const promoCode = useSelector(cartPromoCodeSelector);

  const [orderTrack, setOrderTrack] = useState(false);
  const onSuccess = useCallback(
    async (params) => {
      try {
        const ipAddress = await getIpAddress();
        dispatch(
          capturePaypal({
            orderId: params.token,
            cart: listCart,
            promoCode: promoCode?.promoCode,
            licenses: cartLicenses.map((license: any) => {
              return {
                trackId: license?.trackId,
                licenseId: license?.license?._id,
                negotiationId: license?.negotiation?._id,
              };
            }),
            bulkOffers,
            ipAddress: ipAddress,
          })
        ).then((res: any) => {
          const purchasedData = res.payload?.data || [];
          if (purchasedData.length === 1 && !purchasedData[0].success) {
            // Nếu chỉ có một phần tử và success là false
            history.push("/thank-you?status=error");
            return;
          }
          if (res.payload?.success && purchasedData?.length > 0) {
            history.push("/thank-you?status=success");
          } else {
            history.push("/thank-you?status=error");
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    [
      dispatch,
      listCart,
      promoCode?.promoCode,
      cartLicenses,
      bulkOffers,
      history,
    ]
  );

  useEffect(() => {
    if (!orderTrack && capturePaypalUrl.includes(returnUrl)) {
      let params = queryString.parse(capturePaypalUrl.replace(returnUrl, ""));
      onSuccess(params);
      setOrderTrack(true);
    }
  }, [orderTrack, capturePaypalUrl, onSuccess]);

  return (
    <Box display="flex" justifyContent="center" alignContent="center">
      <Box w="500px" mt="50px">
        <Lottie animationData={transactionProcess} />
      </Box>
    </Box>
  );
}
