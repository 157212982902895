import { Box, Flex, Text, Divider } from "@chakra-ui/react";
import ModalPriceTracks from "app/components/ModalPriceTrack";
import ModalLyrics from "app/components/ModalShowLyrics";
import MusicCard from "app/components/MusicCard";
import { TrackSoundKit, HandleFreeDownloadModel } from "app/models";
import { convertTrackKey } from "constants/trackKey";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import useTrackSelect from "hooks/track/useTrackSelect";
import React, { useCallback, useEffect } from "react";
import {
  useInfiniteHits,
  RefinementList,
  useInstantSearch,
} from "react-instantsearch";
import ModalAddToPlaylist from "../Playlists/modalAddToPlaylist";
import ModalCreatePlaylist from "../Playlists/modalCreatePlaylist";
import InfiniteHits from "./infiniteHits";
import { useTranslation } from "react-i18next";
import SkeletonExplore from "./skeletonExplore";
import EmptyTrackSoundkit from "app/components/MySoundkits/emptyTrackSoundkit";
import ModalShareLinkRecentActions from "./modalShareLinkRecentActions";
import CurrentRefinements from "../SearchFilterPage/CurrentRefinements";
import ModalShowFreeDownloadFormMailChimp from "app/components/ModalShowFreeDownloadFormMailchimp";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { listPlaylistsTrackSoundkitAlgolia } from "app/redux/actions/playlist";

interface TrackExploreHomeProps {
  isLoading: boolean;
  handleFreeDownload: ({
    url,
    title,
    _id,
    isTrack,
  }: HandleFreeDownloadModel) => void;
}

function TrackExploreHome({
  isLoading,
  handleFreeDownload,
}: TrackExploreHomeProps) {
  const { hits } = useInfiniteHits();
  const dispatch = useDispatch();
  const history = useHistory();
  const { status } = useInstantSearch();
  const { t } = useTranslation();
  const {
    selectedTrack,
    onOpenOptionsModal,
    showLyrics,
    setShowLyrics,
    showOptionsModal,
    setShowOptionsModal,
    isEditLyrics,
    setIsEditLyrics,
    lyricsData,
    showModalShareLink,
    setShowModalShareLink,
    shortLink,
    loadingUrl,
    showModalAndGetdataShareLink,
    setLoadingUrl,
    setShortLink,
    handleShowModalFreeDownloadMailchimp,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
  } = useTrackSelect();

  const {
    trackId,
    setShowModalAddToPlaylist,
    showModalAddToPlaylist,
    handleShowModalAddTrack,
    showModalCreatePlaylist,
    setShowModalCreatePlaylist,
    playlists,
    handleSearchText,
    debouncedSearchText,
  } = usePlaylists();

  useEffect(() => {
    dispatch(listPlaylistsTrackSoundkitAlgolia(hits));
  }, [dispatch, hits]);

  useEffect(() => {
    const unlisten = history.block((location) => {
      console.log("voday");
      dispatch(listPlaylistsTrackSoundkitAlgolia([]));
    });
    return () => {
      unlisten();
    };
  }, [dispatch, history]);

  const Hit = useCallback(
    ({ hit }: TrackSoundKit) => {
      const convertTrack = convertTrackKey(hit);
      return (
        <Box overflow="hidden">
          <MusicCard
            onOpenOptionsModal={onOpenOptionsModal}
            item={convertTrack}
            isProfile
            isRecentAction
            handleShowModalAddTrack={handleShowModalAddTrack}
            handleFreeDownload={handleFreeDownload}
            onOpenModalShareLink={showModalAndGetdataShareLink}
            setShortLinkUrl={setShortLink}
            setLoadingUrl={setLoadingUrl}
            handleShowModalFreeDownloadMailchimp={
              handleShowModalFreeDownloadMailchimp
            }
          />
        </Box>
      );
    },
    [
      handleFreeDownload,
      handleShowModalAddTrack,
      onOpenOptionsModal,
      setLoadingUrl,
      setShortLink,
      showModalAndGetdataShareLink,
      handleShowModalFreeDownloadMailchimp,
    ]
  );

  const renderData = useCallback(() => {
    if (isLoading) {
      return <SkeletonExplore />;
    }
    if (hits?.length > 0) {
      return (
        <>
          <Flex justifyContent="space-between">
            <Text
              mb="30px"
              fontSize={{ base: "18px", md: "24px" }}
              lineHeight={{ base: "20px", md: "29px" }}
              fontWeight="700"
              fontFamily="Cera Pro Bold"
            >
              {t("trendingTags")}
            </Text>
          </Flex>
          <Box minH="200px">
            <RefinementList
              attribute="tags"
              searchable={true}
              style={{ fontFamily: "Cera Pro Medium" }}
              searchablePlaceholder={t("trendingTags")}
              limit={16}
            />
          </Box>
          <Box display={{ base: "none", md: "block" }}>
            <CurrentRefinements />
          </Box>

          <Divider
            my={{ base: "16px", md: "24px", xl: "28px" }}
            borderColor="#2A2B30"
            display={{ base: "none", md: "block" }}
          />
          <InfiniteHits hitComponent={Hit} />
        </>
      );
    } else {
      return ["loading", "stalled"].includes(status) ? (
        <SkeletonExplore />
      ) : (
        <EmptyTrackSoundkit />
      );
    }
  }, [Hit, hits?.length, isLoading, status, t]);

  return (
    <Box>
      {renderData()}
      {showOptionsModal && (
        <ModalPriceTracks
          selectedTrack={selectedTrack}
          showModal={showOptionsModal}
          setShowModal={setShowOptionsModal}
          handleFreeDownload={handleFreeDownload}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      )}
      {showLyrics && (
        <ModalLyrics
          showModal={showLyrics}
          setShowModal={setShowLyrics}
          selectedTrack={selectedTrack}
          onOpenOptionsModal={onOpenOptionsModal}
          isEditLyrics={isEditLyrics}
          setIsEditLyrics={setIsEditLyrics}
          lyricsData={lyricsData}
        />
      )}
      {showModalAddToPlaylist && (
        <ModalAddToPlaylist
          trackId={trackId}
          setShowModal={setShowModalAddToPlaylist}
          showModal={showModalAddToPlaylist}
          setShowModalCreatePlaylist={setShowModalCreatePlaylist}
          playlists={playlists}
          handleSearchText={handleSearchText}
          debouncedSearchText={debouncedSearchText}
        />
      )}
      {showModalCreatePlaylist && (
        <ModalCreatePlaylist
          showModal={showModalCreatePlaylist}
          setShowModal={setShowModalCreatePlaylist}
        />
      )}
      {showModalShareLink && (
        <ModalShareLinkRecentActions
          showModalShareLink={showModalShareLink}
          setShowModalShareLink={setShowModalShareLink}
          selectedTrackSoundkit={selectedTrack}
          shortLinkUrl={shortLink}
          loadingUrl={loadingUrl}
        />
      )}
      {showModalFreeMailchimp && (
        <ModalShowFreeDownloadFormMailChimp
          showModal={showModalFreeMailchimp}
          setShowModal={setShowModalFreeMailchimp}
          selectedTrack={selectedTrack}
          handleFreeDownload={handleFreeDownload}
        />
      )}
    </Box>
  );
}

export default React.memo(TrackExploreHome);
