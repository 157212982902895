import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { ALLOW_BROAD_CASTING, LICENSE_RENEWAL } from "constants/index";
import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectField } from "../CustomFIeld/SelectField/SelectField";

export default function NonExclusive() {
  const { t } = useTranslation();
  const { control, register, setValue, getValues, formState } =
    useFormContext();

  const dataContractDetail = useMemo(
    () => [
      {
        name: "distributionCopies",
        label: t("distribution"),
        placeholder: "0",
        input: true,
      },
      {
        name: "freeDownloads",
        label: t("freeDownloads"),
        placeholder: "0",
        input: true,
      },
      {
        name: "audioStreams",
        label: t("audioStreams"),
        placeholder: "0",
        input: true,
      },
      {
        name: "musicVideo",
        label: t("musicStreams"),
        placeholder: "0",
        input: true,
      },
      {
        name: "nonMonetizedVideo",
        label: t("nonMonetizedVideo"),
        placeholder: "0",
        input: true,
      },
      {
        name: "monetizedVideo",
        label: t("monetizedVideos"),
        placeholder: "0",
        input: true,
      },
    ],
    [t]
  );

  const dataContract = useMemo(
    () => [
      {
        title: t("numberOfRadio"),
        price: "0",
        input: true,
        isRadio: true,
        subName: "radioStation",
        name: "allowBroadCasting",
      },
      {
        title: t("numberOfProfit"),
        price: "0",
        input: true,
        subName: "notProfitPerformance",
        name: "profitPerformance",
      },
    ],
    [t]
  );

  const selectProfit = useMemo(
    () => [
      {
        name: t("yes"),
        value: ALLOW_BROAD_CASTING.ALLOW,
      },
      {
        name: t("no"),
        value: ALLOW_BROAD_CASTING.NOT_ALLOW,
      },
    ],
    [t]
  );

  const selectalicenseRenewal = useMemo(
    () => [
      {
        name: `1 ${t("year")}`,
        value: LICENSE_RENEWAL.ONE_YEAR,
      },
      {
        name: `2 ${t("year")}`,
        value: LICENSE_RENEWAL.TWO_YEAR,
      },
    ],
    [t]
  );

  const handleUnlimited = useCallback(
    (name) => {
      const currentVal = getValues(name);
      const valueInput =
        currentVal === "0" || !currentVal ? `${t("unlimited")}` : "0";
      setValue(name, valueInput);
    },
    [getValues, setValue, t]
  );

  const handleChangeSelect = useCallback(
    (type, value) => {
      if (
        type === "profitPerformance" &&
        value === ALLOW_BROAD_CASTING.NOT_ALLOW
      ) {
        return setValue("notProfitPerformance", "0");
      }
      return setValue("radioStation", "0");
    },
    [setValue]
  );

  return (
    <>
      <Text mt="40px" fontSize={{ base: "20px", md: "24px" }}>
        {t("contractDetail")}
      </Text>
      <Divider borderColor="#24252a" mt="10px" />
      <SimpleGrid columns={{ base: 1, md: 3 }} mt="25px" spacing="15px">
        {dataContractDetail.map((item, index) => (
          <Box key={index}>
            <Text color="#535353" mb="5px">
              {item.label}
            </Text>
            <Flex>
              <Button
                backgroundColor={
                  getValues(`${item.name}`) === `${t("unlimited")}`
                    ? "#34391A"
                    : "#25252A"
                }
                color={
                  getValues(`${item.name}`) === `${t("unlimited")}`
                    ? "#D4F70E"
                    : "white"
                }
                _hover={{ boxShadow: "none" }}
                borderLeftRadius="4px"
                borderRightRadius="none"
                mr="1px"
                w={item.input ? "50%" : "100%"}
                size="lg"
                onClick={() => {
                  handleUnlimited(item.name);
                }}
                border={
                  getValues(`${item.name}`) === `${t("unlimited")}`
                    ? "1px solid #D4F70E"
                    : "none"
                }
              >
                {t("unlimited")}
              </Button>
              {item.input && (
                <Input
                  disabled
                  {...register(`${item.name}`)}
                  borderLeftRadius="none"
                  borderRightRadius="4px"
                  border="none"
                  backgroundColor="#25252A"
                  placeholder={item.placeholder}
                  size="lg"
                />
              )}
            </Flex>
          </Box>
        ))}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="15px">
        <Box mt="15px">
          <SelectField
            name="allowBroadCasting"
            label={t("broadcastingRight")}
            placeholder=""
            options={selectProfit}
            control={control}
            onChangePerformance={(type: string, value: string) =>
              handleChangeSelect(type, value)
            }
          />
        </Box>
        <Box mt="15px">
          <SelectField
            name="profitPerformance"
            label={t("allowProfitPerformances")}
            placeholder=""
            options={selectProfit}
            control={control}
            onChangePerformance={(type: string, value: string) =>
              handleChangeSelect(type, value)
            }
          />
        </Box>
        {dataContract.map((item, index) => (
          <Box key={index}>
            <Text color="#535353" mb="5px">
              {item.title}
            </Text>
            <Flex>
              <Button
                disabled={
                  getValues(item.name) === ALLOW_BROAD_CASTING.NOT_ALLOW
                }
                backgroundColor={
                  getValues(`${item.subName}`) === `${t("unlimited")}`
                    ? "#D3F70E"
                    : "#25252A"
                }
                color={
                  getValues(`${item.subName}`) === `${t("unlimited")}`
                    ? "black"
                    : "white"
                }
                _hover={{ boxShadow: "none" }}
                borderLeftRadius="4px"
                borderRightRadius={item.input ? "none" : "4px"}
                mr="1px"
                w={item.input ? "50%" : "100%"}
                size="lg"
                onClick={() => {
                  handleUnlimited(item.subName);
                }}
              >
                {t("unlimited")}
              </Button>
              {item.input && (
                <Input
                  disabled={
                    getValues(item.name) === ALLOW_BROAD_CASTING.NOT_ALLOW
                  }
                  borderLeftRadius="none"
                  borderRightRadius="4px"
                  border="none"
                  backgroundColor="#25252A"
                  placeholder={item.price}
                  size="lg"
                  {...register(`${item.subName}`)}
                />
              )}
            </Flex>
          </Box>
        ))}
      </SimpleGrid>

      <Text mt="40px" fontSize={{ base: "20px", md: "24px" }}>
        {t("licenseRenewalTerm")}
      </Text>
      <Divider borderColor="#24252a" mt="10px" />

      <Flex alignItems="center" justifyContent="space-between" gridGap="50px">
        <Box mt="15px" w={{ base: "100%", md: "50%" }}>
          <SelectField
            name="licenseRenewal"
            label={t("yearTerm")}
            placeholder={t("selectYearTerm")}
            options={selectalicenseRenewal}
            control={control}
            errors={formState.errors}
          />
        </Box>
      </Flex>
    </>
  );
}
