import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { CreatePlaylist, QueryPlayTrack } from "app/models";
import PlaylistsService from "app/apis/playlists";
import TrackService from "app/apis/tracks";

export const TYPES = {
  REMOVE_AUDIO_LIST: "REMOVE_AUDIO_LIST",
  SET_AUDIO_LIST: "SET_AUDIO_LIST",
  SELECT_AUDIO: "SELECT_AUDIO",
  TOGGLE_MUTED: "TOGGLE_MUTED",
  TOGGLE_SHUFFLE_AUDIO: "TOGGLE_SHUFFLE_AUDIO",
  SET_LOOP_AUDIO_TYPE: "SET_LOOP_AUDIO_TYPE",
  SET_VOLUME: "SET_VOLUME",
  CREATE_PLAYLIST: "CREATE_PLAYLIST",
  GET_PLAYLISTS: "GET_PLAYLISTS",
  ADD_TRACK_TO_PLAYLIST: "ADD_TRACK_TO_PLAYLIST",
  PREPARE_EDIT_PLAYLIST: "PREPARE_EDIT_PLAYLIST",
  EDIT_PLAYLIST: "EDIT_PLAYLIST",
  DELETE_PLAYLIST: "DELETE_PLAYLIST",
  GET_INDIVIDUAL_PLAYLIST: "GET_INDIVIDUAL_PLAYLIST",
  REMOVE_TRACK_TO_PLAYLIST: "REMOVE_TRACK_TO_PLAYLIST",
  FETCH_FAVORITES_PLAYLISTS: "FETCH_FAVORITES_PLAYLISTS",
  FETCH_REPOST_PLAYLISTS: "FETCH_REPOST_PLAYLISTS",
  LIKE_PLAYLIST_INDIVIDUAL: "LIKE_PLAYLIST_INDIVIDUAL",
  UNLIKE_PLAYLIST_INDIVIDUAL: "UNLIKE_PLAYLIST_INDIVIDUAL",
  REPOST_PLAYLIST_INDIVIDUAL: "REPOST_PLAYLIST_INDIVIDUAL",
  FOLLOW_UNFOLLOW_PLAYLIST: "FOLLOW_UNFOLLOW_PLAYLIST",
  GET_PLAYLISTSFOLLOWING: "GET_PLAYLISTSFOLLOWING",
  PLAY_PLAYLISTS: "PLAY_PLAYLISTS",
  CHECK_IS_PLAY_PLAYLISTS: "CHECK_IS_PLAY_PLAYLISTS",
  PLAYLIST_IS_PLAYING: "PLAYLIST_IS_PLAYING",
  GET_PLAYLISTS_IN_PROFILE: "GET_PLAYLISTS_IN_PROFILE",
  LIKE_PLAYLISTS_ERROR: "LIKE_PLAYLISTS_ERROR",
  UNLIKE_PLAYLISTS_ERROR: "UNLIKE_PLAYLISTS_ERROR",
  LIKE_PLAYLIST_DETAIL_ERROR: "LIKE_PLAYLIST_DETAIL_ERROR",
  UNLIKE_PLAYLIST_DETAIL_ERROR: "UNLIKE_PLAYLIST_DETAIL_ERROR",
  LIST_PLAYLIST_TRACK_SOUNDKIT_ALGOLIA: "LIST_PLAYLIST_TRACK_SOUNDKIT_ALGOLIA",
};

export const removeAudioList = createAction(TYPES.REMOVE_AUDIO_LIST);

export const toggleMuted = createAction(TYPES.TOGGLE_MUTED);

export const toggleShuffleAudio = createAction(TYPES.TOGGLE_SHUFFLE_AUDIO);

export const setLoopAudioType = createAction(
  TYPES.SET_LOOP_AUDIO_TYPE,
  (payload) => {
    return {
      payload,
    };
  }
);
export const setVolume = createAction(TYPES.SET_VOLUME, (payload) => {
  return {
    payload,
  };
});

export const setAudioList = createAction(TYPES.SET_AUDIO_LIST, (payload) => {
  return {
    payload,
  };
});

export const selectAudio = createAction(TYPES.SELECT_AUDIO, (payload) => {
  return {
    payload,
  };
});

export const createPlaylist = createAsyncThunk(
  TYPES.CREATE_PLAYLIST,
  async (data: CreatePlaylist) => {
    return await PlaylistsService.createPlaylists(data);
  }
);

export const editPlaylist = createAsyncThunk(
  TYPES.EDIT_PLAYLIST,
  async (data: CreatePlaylist) => {
    return await PlaylistsService.editPlaylists(data);
  }
);

export const getPlaylists = createAsyncThunk(
  TYPES.GET_PLAYLISTS,
  async (visibility?: string) => {
    return await PlaylistsService.getPlaylists(visibility);
  }
);

export const getPlaylistsInProfile = createAsyncThunk(
  TYPES.GET_PLAYLISTS_IN_PROFILE,
  async (userId?: string) => {
    return await PlaylistsService.getPlaylistsInProfile(userId);
  }
);

export const deletePlaylists = createAsyncThunk(
  TYPES.DELETE_PLAYLIST,
  async (playlistIds: string[]) => {
    return await PlaylistsService.deletePlaylists(playlistIds);
  }
);

export const addTrackToPlaylist = createAsyncThunk(
  TYPES.ADD_TRACK_TO_PLAYLIST,
  async (data: CreatePlaylist) => {
    return await PlaylistsService.addTrackToPlaylist(data);
  }
);

export const removeTrackToPlaylist = createAsyncThunk(
  TYPES.REMOVE_TRACK_TO_PLAYLIST,
  async (data: CreatePlaylist) => {
    return await PlaylistsService.removeTrackToPlaylist(data);
  }
);

export const getIndividualPlaylist = createAsyncThunk(
  TYPES.GET_INDIVIDUAL_PLAYLIST,
  async (id: string) => {
    return await PlaylistsService.getIndividualPlaylist(id);
  }
);

export const prepareEditPlaylist = createAction(
  TYPES.PREPARE_EDIT_PLAYLIST,
  (payload) => {
    return {
      payload,
    };
  }
);

export const fetchFavoritesPlaylists = createAsyncThunk(
  TYPES.FETCH_FAVORITES_PLAYLISTS,
  PlaylistsService.getFavoritesPlaylist
);

export const fetchRepostPlaylists = createAsyncThunk(
  TYPES.FETCH_REPOST_PLAYLISTS,
  PlaylistsService.getRepostPlaylists
);

export const likePlaylistIndividual = createAsyncThunk(
  TYPES.LIKE_PLAYLIST_INDIVIDUAL,
  async (query: QueryPlayTrack, thunkAPI) => {
    setTimeout(async () => {
      try {
        await TrackService.setFavoritePlaylist(query);
      } catch (error) {
        thunkAPI.dispatch(likePlaylistsError(query.id));
        thunkAPI.dispatch(errorLikePlaylistDetail());
      }
    }, query.timeCallAPi);

    return { playlistId: query?.id };
  }
);

export const unlikePlaylistIndividual = createAsyncThunk(
  TYPES.UNLIKE_PLAYLIST_INDIVIDUAL,
  async (query: QueryPlayTrack, thunkAPI) => {
    setTimeout(async () => {
      try {
        await TrackService.unsetFavoritePlaylist(query);
      } catch (error) {
        thunkAPI.dispatch(unLikePlaylistsError(query.id));
        thunkAPI.dispatch(errorUnLikePlaylistDetail());
      }
    }, query.timeCallAPi);
    return { playlistId: query?.id };
  }
);

export const repostPlaylistIndividual = createAsyncThunk(
  TYPES.REPOST_PLAYLIST_INDIVIDUAL,
  async (playlistId: string) => {
    TrackService.setRepostPlaylist(playlistId);
    return { playlistId: playlistId };
  }
);

export const followUnfollowPlaylist = createAsyncThunk(
  TYPES.FOLLOW_UNFOLLOW_PLAYLIST,
  async (data: CreatePlaylist) => {
    return await PlaylistsService.followUnfollowPlaylists(data);
  }
);

export const getPlaylistsFollowing = createAsyncThunk(
  TYPES.GET_PLAYLISTSFOLLOWING,
  PlaylistsService.getPlaylistsFollowing
);

export const playPlaylists = createAction(TYPES.PLAY_PLAYLISTS, (payload) => {
  return {
    payload,
  };
});

export const checkIsPlayPlaylists = createAction(
  TYPES.CHECK_IS_PLAY_PLAYLISTS,
  (payload) => {
    return {
      payload,
    };
  }
);

export const likePlaylistsError = createAction(
  TYPES.LIKE_PLAYLISTS_ERROR,
  (payload) => {
    return {
      payload,
    };
  }
);
export const unLikePlaylistsError = createAction(
  TYPES.UNLIKE_PLAYLISTS_ERROR,
  (payload) => {
    return {
      payload,
    };
  }
);

export const checkPlaylistIdPlaying = createAction(
  TYPES.PLAYLIST_IS_PLAYING,
  (payload) => {
    return {
      payload,
    };
  }
);

export const errorLikePlaylistDetail = createAction(
  TYPES.LIKE_PLAYLIST_DETAIL_ERROR
);
export const errorUnLikePlaylistDetail = createAction(
  TYPES.UNLIKE_PLAYLIST_DETAIL_ERROR
);

export const listPlaylistsTrackSoundkitAlgolia = createAction(
  TYPES.LIST_PLAYLIST_TRACK_SOUNDKIT_ALGOLIA,
  (payload) => {
    return {
      payload,
    };
  }
);
