import { Box, Flex } from "@chakra-ui/react";
import { getIndividualSoundkit } from "app/redux/actions/mySoundKit";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LeftIndividualSoundkit from "./leftIndividualSoundkit";
import RightIndividualSoundkit from "./rightIndividualSoundkit";
import "../IndividualTrackPage/style.css";
import { useMySoundkit } from "hooks/soundkit/useMySoundkit";
import SkeletonIndividualTrack from "../IndividualTrackPage/skeletonIndividualTrack";

export default function IndividualSoundkitPage() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { isLoading } = useMySoundkit();

  useEffect(() => {
    dispatch(getIndividualSoundkit(slug));
  }, [dispatch, slug]);

  return (
    <Box
      overflow="auto"
      padding={{ base: "40px 16px 0px", xl: "40px 80px 0px" }}
      height="100vh"
    >
      {isLoading ? (
        <SkeletonIndividualTrack />
      ) : (
        <Flex
          w="100%"
          flexWrap="nowrap"
          gridGap={{ base: "10px", md: "15px", lg: "20px", xl: "30px" }}
          display={{ base: "block", md: "flex" }}
        >
          <LeftIndividualSoundkit />
          <RightIndividualSoundkit />
        </Flex>
      )}
    </Box>
  );
}
