import { Box, GridItem, Text } from "@chakra-ui/react";
import { TrackStems, Untagged, VoiceTag } from "app/assets/icons";
import AudioFile from "app/components/AudioFile";
import { Licenses } from "app/models";
import { FILE_UPLOAD_TYPE } from "constants/fileUploadTypes";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./styles.scss";
// import VoiceTagPreview from "./VoiceTagPreview";
import { planType } from "constants/planType";
import { useSelector } from "react-redux";
import { selectUserAdmin } from "app/redux/selectors/users";

export default function FilesToDeliver() {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const currentUser = useSelector(selectUserAdmin);

  const checkPlanSubscription = useMemo(
    () => currentUser?.subscriptionType === planType.MAINSTREAM,
    [currentUser?.subscriptionType]
  );

  const selectedLicensesWatch = watch("licenses");

  // const taggedFileUrl = watch("taggedFileUrl");

  const hasUploadTrackStems = useMemo(() => {
    return selectedLicensesWatch.some(
      (license: Licenses) => license?.trackStems
    );
  }, [selectedLicensesWatch]);

  const uploadExtraWav = useMemo(() => {
    return selectedLicensesWatch.some((license: Licenses) => license?.extraWav);
  }, [selectedLicensesWatch]);

  const fileUploads = useMemo(
    () => [
      {
        isDisplay: true,
        enableBitrateCheck: true,
        headerTitle: "audioFiles",
        title: "untaggedMP3",
        name: "untaggedFileUrl",
        detail: "minBitrateWAVorMP3",
        type: FILE_UPLOAD_TYPE.AUDIO,
        fileType: "audio/mp3",
        icon: <Untagged />,
      },
      {
        isDisplay: uploadExtraWav,
        headerTitle: "audioFilesWav",
        title: "untaggedWAV",
        name: "untaggedFileUrlWav",
        detail: "minBitrateWAV",
        type: FILE_UPLOAD_TYPE.AUDIO,
        fileType: "audio/wav",
        icon: <Untagged />,
      },
      {
        isDisplay: hasUploadTrackStems,
        headerTitle: "",
        title: "trackStems",
        detail: "trackStemsDetail",
        name: "trackStemsUrl",
        type: FILE_UPLOAD_TYPE.ZIP,
        icon: <TrackStems />,
      },
      {
        isDisplay: true,
        headerTitle: "audioTagForStreaming",
        title: "MP3CustomVoiceTag",
        detail: ["MP3CustomVoiceTagNote"],
        name: "taggedFileUrl",
        type: FILE_UPLOAD_TYPE.AUDIO,
        icon: <VoiceTag />,
        isNotUpload: !checkPlanSubscription,
      },
    ],
    [checkPlanSubscription, hasUploadTrackStems, uploadExtraWav]
  );

  return (
    <GridItem colSpan={2}>
      {fileUploads.map((item) => (
        <Box
          fontFamily="Cera Pro Regular"
          key={item.name}
          display={item.isDisplay ? "block" : "none"}
          mb="25px"
        >
          <Text color="#535353" fontFamily="Cera Pro Regular">
            {t(item.headerTitle)}
          </Text>
          <Controller
            name={item.name}
            render={({ field }) => {
              return (
                <AudioFile
                  licensesData={selectedLicensesWatch}
                  error={errors[item.name]?.message}
                  data={item}
                  value={field.value}
                  setFileUrl={(value: string | void) => {
                    field.onChange({
                      target: {
                        value,
                      },
                    });
                  }}
                />
              );
            }}
            control={control}
          />
        </Box>
      ))}
      {/* <VoiceTagPreview tagDefaultId={'audio-default'} hasTitle audioUrl={taggedFileUrl} /> */}
    </GridItem>
  );
}
